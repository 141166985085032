<template>
  <div v-if="loadedPage" class="receipt-description">
    <div class="receipt-image-wrapper">
      <div
        :class="{
          rotate90: rotate == 1,
          rotate180: rotate == 2,
          rotate270: rotate == 3,
        }">
        <!--
        :src="fe_media.file" CORS ISSUE for pdf's

        some browsers and OS's have an issue with v-show on pdf's
        v-show="imageLoaded"
        -->
        <vuePdf
          v-if="receipt.media && receipt.media.fileType == 'application/pdf'"
          class="pdf-component"
          :page="pageNr"
          :src="`${windowOrigin}/api/pv/nar/file/${receipt.media.file}`"
          @loaded="imageLoaded = true"
          @num-pages="pageCount = $event"></vuePdf>
        <img
          v-show="imageLoaded"
          v-else-if="fe_media"
          class="receipt-image"
          :src="fe_media.file" alt=""
          @load="imageLoaded = true"/>

        <div
          v-show="!imageLoaded"
          class="loading-text">
          Loading image <span class="dot-1">.</span><span class="dot-2">.</span><span class="dot-3">.</span>
        </div>
      </div>
      <div class="button-wrapper">
        <button @click="rotate <= 2 ? (rotate += 1) : (rotate = 0)">
          rotate90
        </button>
        <button @click="confirmOrDeclineReceipt()">
          {{ receipt.confirmed_receipt ? 'Decline Receipt' : 'Confirm receipt' }}
        </button>
        <button
          v-if="receipt.confirmed_registration"
          @click="showResetRegistrationConfirm = !showResetRegistrationConfirm">
          Decline registration
        </button>
        <button
          v-if="receipt.activation.cashback_step"
          @click="showCashbackModal = !showCashbackModal">
          Cashback
        </button>
        <button :style="!fe_receipt.comments.length ? { 'background': '#ccc'} : { 'background': '#EA7801'}" @click="showCommentModal = !showCommentModal">
          Comments {{
            fe_receipt.comments && fe_receipt.comments.length > 0
              ? `(${fe_receipt.comments.length})`
              : ""
          }}
        </button>
        <button @click="showParamsModal = !showParamsModal">
          Params
        </button>
        <!--<button @click="showCtaMailModal = !showCtaMailModal">
          CTA_email
        </button>-->

        <button
          v-if="receipt.activation.cta_actions && receipt.activation.cta_actions.length"
          @click="showActionsModal = true">
          Actions
        </button>
        <button v-if="receipt.flagged" @click="showResetFlagModal = !showResetFlagModal">
          Reset flag
        </button>
        <button @click="showEditModal = !showEditModal">
          Edit fields
        </button>
        <button @click="showCloneConfirmModal = !showCloneConfirmModal">
          Clone
        </button>
        <button v-if="fe_media" @click="downloadImage(fe_media.file)">Download Image</button>
        <button @click="deleteModal = !deleteModal">
          Delete
        </button>
        <a style="padding-block: 2px;
          padding-inline: 6px;
          border-width: 2px;
          border-style: outset;
          border-color: buttonborder;
          border-image: initial;
          background: rgb(238, 238, 238)"
          :href="`${windowOrigin}/admin/pv/activations/${this.receipt.activation._id}`">
          Goto Activation
        </a>
        <div
          v-if="blocked.length && blocked[0].phone_nr == receipt.phone_nr"
          class="blocked-user">
          The user is blocked everywhere by phone_nr!
        </div>
      </div>
    </div>
    <pre class="receipt-metadata" id="json"></pre>

    <modal v-if="showParamsModal">
      <h3>Activation</h3>

      <div
        v-if="receipt.activation && receipt.activation._id"
        class="receipt-activation-params">
        <div class="header"><b>_id:</b> {{ receipt.activation._id }}</div>
        <div class="header"><b>Name:</b> {{ receipt.activation.name }}</div>
        <div class="header"><b>Receipt terms for line items:</b>
          <table>
            <thead>
              <tr>
                <th>Key</th>
                <th>Label</th>
                <th>Terms</th>
                <th>Mandatory</th>
                <th>Amount</th>
                <th>Amount min</th>
                <th>Amount max</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="term in receipt.activation.receipt_terms_multiple" :key="term.key">
                <td>{{ term.key }}</td>
                <td>{{ term.label }}</td>
                <td>{{ term.terms }}</td>
                <td>{{ term.mandatory }}</td>
                <td>{{ term.amount }}</td>
                <td>{{ term.amount_min }}</td>
                <td>{{ term.amount_max }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="header"><b>Date max days old:</b> {{ receipt.activation.receipt_date_max_days }}</div>
        <div class="header"><b>Date range from:</b> {{ formatDate(receipt.activation.receipt_date_range_from) }}</div>
        <div class="header"><b>Date range to:</b> {{ formatDate(receipt.activation.receipt_date_range_to) }}</div>
        <div class="header"><b>shop list (combined):</b> {{ combinedShopTerms }}</div>
        <div class="header"><b>shop exclude list:</b> {{ receipt.activation.receipt_shop_exclude_list }}</div>
        <div class="header"><b>term minimum amount:</b> {{ receipt.activation.receipt_term_minimum_amount }}</div>
      </div>

      <div>
        <button
          class="btn btn-transparent"
          @click="showParamsModal = !showParamsModal">
          Close
        </button>
      </div>
    </modal>

    <modal v-if="showCtaMailModal">
      <h3>CTA email</h3>

      <div v-if="receipt.activation && receipt.activation._id">
        <div>Email: {{ receipt.email }}</div><br />
        <div>Subject: {{ receipt.activation.cta_email_subject }}</div>
        <div v-html="receipt.activation.cta_email_template"></div>
      </div>

      <div>
        <button
          @click="sendCtaEmail()"
          class="btn btn-activate">Send CTA Email</button>
        <button
          class="btn btn-transparent"
          @click="showCtaMailModal = !showCtaMailModal">
          Close
        </button>
      </div>
    </modal>

    <modal v-if="showCashbackModal">
      <h3>Confirm / Decline cashback</h3>
      <div>
        <p>Cashback status: {{ receipt.confirmed_cashback }}</p>
        <p v-if="receipt.confirmed_cashback">
          Decline cashback ONLY if we have confirmation from CDI that payment didn't go through.
          This will allow customer to try again with our cashback-flow.
        </p>
        <p v-else-if="!receipt.confirmed_cashback">
          Confirm cashback only if the customer has received money from CDI manually!
          This will remove possibility for customer to use our cashback-flow.
        </p>
      </div>
      <div>
        <button
          class="btn btn-activate"
          @click="confirmCashback()">{{ receipt.confirmed_cashback ? 'Decline cashback' : 'Confirm cashback' }}
        </button>
        <button class="btn btn-transparent" @click="showCashbackModal = !showCashbackModal">Cancel</button>
      </div>
    </modal>

    <modal v-if="showConfirmReceiptModal">
      <div>
        <div v-if="receiptKeywordItems.length">
          <h3>Which key do you want to add terms to?</h3>
          <InputField
            label="Key (be carefull)"
            inputType="select"
            :selectOptions="activationReceiptKeys"
            v-model="theKey"/>
          <h3>Add keywords that match the receipt item</h3>
          <div
            v-for="(value,key,index) in receiptKeywordItems"
            :key="index"
            :ref="'icon'+receiptKeywordItems[key]"
            class="additional-terms">
            <md-checkbox
              :id="'item'+key"
              v-model="checkedKeywordItems"
              :value="receiptKeywordItems[key]"
              class="md-primary m-0">
            </md-checkbox>
            <!--<md-field class="additional-terms-input">
              <md-input v-model="receiptKeywordItems[key]" @input="uncheckPrevious($event, key, value)"></md-input>
            </md-field>-->
            <span class="additional-terms-input">
              <input
                v-model="receiptKeywordItems[key]"
                @input="uncheckPrevious($event, key, value)"/>
            </span>
          </div>
        </div>
      </div>
      <div>
        <button class="btn btn-transparent" @click="dismissConfirmModal()">Cancel</button>
        <button class="btn btn-activate" @click="confirmReceipt()">Confirm receipt</button>
      </div>
    </modal>

    <modal v-if="showDeclineReceiptModal">
      <h3>Du you really want to decline the receipt step?</h3>
      <div>
        <div style="margin-top: 30px; color: red;">
          <inputField
            v-model="alsoBlock"
            style="margin: 10px 0;"
            label="Would you also want to block the user?"
            inputType="checkbox"/>
        </div>
        <!--
          set flagged_duplicate => which means even this receipt is checked towards similar_receipts
          you must upload a new receipt to continue
        
        <inputField
          v-if="receipt.similar_receipts && receipt.similar_receipts.length"
          v-model="setDuplicateFlag"
          style="margin: 10px 0;"
          label="Block the current receipt, it's a duplicate of another receipt"
          inputType="checkbox"/>-->
        <!--
          set blocked => which means this receipt is blocked for this company
          create a new receipt with this data, so that it will trigger similar_receipts for others like this one
          buuut, that id will not be correct in the OCR service
        
        <inputField
          v-else
          v-model="requireReceiptChange"
          style="margin: 10px 0;"
          label="Block the current receipt, so it can't be used for this Company, EVER again"
          inputType="checkbox"/>-->
      </div>
      <div>
        <button class="btn btn-transparent" @click="showDeclineReceiptModal = false">Cancel</button>
        <button class="btn btn-activate" @click="declineReceipt()">Decline receipt</button>
      </div>
    </modal>

    <modal v-if="showForceConfirmModal">
      <slot>
        <div>
          <div v-if="receipt.activation.registration_step">
            <h2>Important!</h2>
            <div>You must add at least one receipt item.<br/><br/>
              Click Edit Fields -> Add an item -> enter correct values and select correct key -> save -> try again to confirm receipt</div>
          </div>
          <div v-else>
            <h3>Are you sure you want to confirm this receipt?</h3>
  
            <div><b>!!! Please add an item in the Edit Fields modal !!!!</b></div>
            <div>
              Receipt scanner could not find any items on this receipt.
              <br> If you're <strong>really sure</strong> that this is a valid receipt anyways,
              go ahead an force confirmation.
            </div>
          </div>
        </div>
        <button
          class="btn btn-transparent"
          @click="showForceConfirmModal = false">
          Cancel
        </button>
        <button
          v-if="receipt.activation.registration_step && receipt.receipt_items && receipt.receipt_items.length
            || !receipt.activation.registration_step"
          class="btn btn-activate"
          @click="onForceConfirm()">
          Confirm anyways
        </button>
      </slot>
    </modal>

    <modal v-if="showCloneConfirmModal">
      <h3>Are you sure you want to clone this receipt?</h3>
      <div>
        <p>You can change the line item and price for the new cloned receipt</p>
      </div>
      <div>
        <button
          class="btn btn-transparent"
          @click="showCloneConfirmModal = !showCloneConfirmModal">
          Close
        </button>
        <button
          class="btn btn-transparent"
          @click="onCloneConfirm">
          Clone please
        </button>
      </div>
    </modal>

    <modal v-if="showEditModal">
      <h3>Edit receipt fields</h3>
      <div style="max-width: 600px;">
        <p>Edit and override these fields</p>
        <div style="display: flex; flex-direction: column;">
          <inputField
            v-model="editForm.receipt_store"
            style="margin: 5px 0;"
            label="Retailer"/>
          <inputField
            v-model="editForm.receipt_date"
            style="margin: 5px 0;"
            label="Date"/>
          <inputField
            v-if="receipt.activation.review_step"
            v-model="editForm.review_attempts_additional"
            style="margin: 5px 0;"
            label="Additional review attempts"/>
          <inputField
            v-model="editForm.confirmed_receipt"
            style="margin: 5px 0;"
            label="Receipt confirmed"
            inputType="checkbox"/>
          <inputField
            v-model="editForm.confirmed_registration"
            style="margin: 5px 0;"
            label="Registration confirmed"
            inputType="checkbox"/>
          <inputField
            v-model="editForm.running_analyzer"
            style="margin: 5px 0;"
            label="Running analyzer"
            inputType="checkbox"/>
          <button class="btn btn-activate" @click="addReceiptItem()">Add item</button>
          <button class="btn btn-transparent" @click="removeReceiptItem()">Remove item</button>
          <div
            v-for="item in editReceiptItems"
            :key="item._id"
            class="line-item">
            <inputField
              v-model="item.item"
              style="margin: 5px 0;"
              label="Item"/>
            <inputField
              v-model="item.item_cost"
              style="margin: 5px 0;"
              label="Cost"/>
            <inputField
              v-model="item.serialnr"
              style="margin: 5px 0;"
              label="Registration serialnr"/>
            <inputField
              v-model="item.imeinr"
              style="margin: 5px 0;"
              label="Registration imeinr"/>
            <inputField
              v-model="item.modelnr"
              style="margin: 5px 0;"
              label="Registration modelnr"/>
            <InputField
              label="Key (be carefull)"
              inputType="select"
              :selectOptions="activationReceiptKeys"
              v-model="item.key"/>
          </div>
        </div>
      </div>
      <div>
        <button
          class="btn btn-transparent"
          @click="showEditModal = !showEditModal">
          Close
        </button>
        <button
          class="btn btn-transparent"
          @click="onEditConfirm">
          Save
        </button>
      </div>
    </modal>

    <modal v-if="showResetRegistrationConfirm">
      <h3>Are you sure you want to decline confirmed registration?</h3>

      <div>
        <div>
          The receipt field confirmed_registration will be set to false 
          in order to unlock the registration step.
        </div>

        <div style="margin-top: 30px; color: red;">
          <inputField
            v-model="alsoBlock"
            style="margin: 10px 0;"
            label="Would you also want to block the user?"
            inputType="checkbox"/>
        </div>
        <!--
        <inputField
          v-model="requirePRChange"
          style="margin: 10px 0;"
          label="Block the current PR settings, so it can't be used again"
          inputType="checkbox"/>-->
      </div>

      <div>
        <button
          class="btn btn-transparent"
          @click="showResetRegistrationConfirm = !showResetRegistrationConfirm">
          Close
        </button>
        <button
          class="btn btn-activate"
          @click="onResetRegistrationConfirm()">
          Reset
        </button>
      </div>
    </modal>

    <modal v-if="showResetFlagModal"
      :header="{closeButton: true, text: 'Reset flag'}"
      @close="showResetFlagModal = false">
      <slot>
        <div>
          <h3>Are you sure you want to remove flag for this receipt?</h3>
          <div>
            The receipt will no longer show up in the flagged inbox
          </div>
        </div>
        <button
          class="btn btn-transparent"
          @click="showResetFlagModal = false">
          Cancel
        </button>
        <button
          class="btn btn-activate"
          @click="onResetFlagConfirm()">
          Remove flag
        </button>
      </slot>
    </modal>

    <modal
      v-if="showCommentModal"
      :header="{closeButton: true, text: 'Comments about this receipt'}"
      @close="showCommentModal = false">
      <div>
        <div style="display: flex; flex-direction: row;">
          <md-field>
            <label>Add new comment</label>
            <md-textarea v-model="newComment" md-autogrow></md-textarea>
          </md-field>
          <div
            style="display: flex; flex-direction: column; justify-content: end; margin-bottom: 20px;">
            <button
              class="btn btn-activate"
              @click="addAdminReceiptComment"
              :disabled="newComment.length < 3">
              Save
            </button>
          </div>
        </div>

        <div
          v-for="comment in sortedComments"
          :key="comment._id"
          class="comment"
          style="display: flex; flex-direction: column;">
          <span class="date"> {{ formatDate(comment.created_at) }} </span>
          {{ comment.comment }}
        </div>
      </div>
      <div>
        <button
          class="btn btn-transparent"
          @click="showCommentModal = !showCommentModal">
          Close
        </button>
      </div>
    </modal>

    <modal
      v-if="showActionsModal"
      :header="{closeButton: true, text: 'Actions'}"
      @close="showActionsModal = false">
      <table>
        <thead>
          <tr>
            <th>Action ID</th>
            <th>Executed</th>
            <th>Type</th>
            <th>Offer ID</th>
            <th>Collection ID</th>
            <th>Delayed Duration</th>
            <th>Trigger</th>
            <th>Payload</th>
            <th>Endpoint</th>
            <th>Points</th>
            <th>Email Subject</th>
            <th>Email Template</th>
            <th>File Attachment filename</th>
            <th>Buttons</th>
            <!--<th>File Attachment file key</th>
            <th>File Attachment file type</th>
            <th>File Attachment field positions</th>-->
          </tr>
        </thead>
        <tbody>
          <tr v-for="action in mergedCtaActions" :key="action._id">
            <td>{{ action._id }}</td>
            <td :style="`color: ${action.executed ? 'green' : 'red'};`">{{ action.executed ? 'yes' : 'no' }}</td>
            <td>{{ action.type }}</td>
            <td>{{ action.offer_id }}</td>
            <td>{{ action.collection_id }}</td>
            <td>{{ action.delayed_duration }}</td>
            <td>{{ action.trigger }}</td>
            <td>{{ action.payload }}</td>
            <td>{{ action.endpoint }}</td>
            <td>{{ action.points }}</td>
            <td>{{ action.email_subject }}</td>
            <td>{{ action.email_template != '' ? 'yes' : 'no' }}</td>
            <td>{{ action.file_attachment.filename }}</td>
            <td>
              <button class="btn btn-activate" @click="forceRunAction(action._id)">Force Run</button>
            </td>
            <!--<td>{{ action.file_attachment.fileKey }}</td>
            <td>{{ action.file_attachment.fileType }}</td>
            <td>{{ action.file_attachment.fieldPositions }}</td>-->
          </tr>
        </tbody>
      </table>
      <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px;">
        <button class="btn btn-transparent" @click="showActionsModal = false">Cancel</button>
        <button
          class="btn btn-activate"  
          @click="blockDelayedCta()">
          {{ receipt.cta_actions_block ? 'Remove Actions block' : 'Block Actions' }}
        </button>
        <button class="btn btn-activate" @click="runEligbleActions()">Run all eligible actions</button>
      </div>
    </modal>

    <modal v-if="deleteModal">
      <h3>Du you really want to delete the receipt?</h3>
      <p v-if="receipt.cta_done">The receipt has CTA_DONE, deleting the receipt means that the consumer can get another Prize</p>
      <p v-else>The receipt is not yet done with all the steps</p>
      <div>
        <InputField
          label="Delete code"
          placeholder="****"
          v-model="deleteCode"/>
      </div>
      <div>
        <button class="btn btn-transparent" @click="deleteModal = false">Cancel</button>
        <button class="btn btn-activate" @click="deleteReceipt()">Delete</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { map } from 'lodash';
import moment from 'moment-timezone';
import EventBus from '../resources/eventBus';
import vuePdf from 'vue-pdf';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import receiptApi from '../api/pvReceipt.api';
import InputField from '../stories/components/InputField/InputField.vue';

export default {
  name: 'AdminPVReceipt',
  components: {
    vuePdf,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  props: ['receiptId'],
  mounted() {
    this.getAdminReceipt();
  },
  computed: {
    ...mapGetters('pvReceipts', ['receipt']),
    ...mapGetters('pvActivations', ['receipts']),
    ...mapGetters('pvBlocked', ['blocked']),
    sortedComments() {
      return this.fe_receipt && this.fe_receipt.comments ? this.fe_receipt.comments
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        : [];
    },
    anyOpenModal() {
      return this.showConfirmReceiptModal
        || this.showDeclineReceiptModal
        || this.showForceConfirmModal
        || this.showCommentModal
        || this.showParamsModal
        || this.showCtaMailModal
        || this.showCashbackModal
        || this.showResetRegistrationConfirm
        || this.showCloneConfirmModal
        || this.showResetFlagModal
        || this.showEditModal;
    },
    combinedShopTerms() {
      let combinedShopTerms = [];
      if (this.receipt && this.receipt.activation) {
        let retailerTerms = this.receipt.activation.receipt_retailer_list.map(retailer => retailer.terms);
        retailerTerms = [].concat.apply([], retailerTerms);
        combinedShopTerms = this.receipt.activation.receipt_shop_list.concat(retailerTerms);
      }
      return combinedShopTerms;
    },
    mergedCtaActions() {
      let ctaActions = [];
      if (this.receipt && this.receipt.activation) {
        ctaActions = this.receipt.activation.cta_actions.map(action => {
          const runAction = this.receipt.cta_executed_actions.find(executedAction => executedAction.action === action._id);
          return {
            ...action,
            executed: !!runAction?._id,
          };
        });
      }
      return ctaActions;
    },
    activationReceiptKeys() {
      return this.receipt.activation.receipt_terms_multiple.map(term => term.key);
    },
  },
  data() {
    return {
      loadedPage: false,
      showDeclineReceiptModal: false,
      showConfirmReceiptModal: false,
      showForceConfirmModal: false,
      pageCount: 0,
      pageNr: 1,
      fe_receipt: {},
      fe_receipts: [],
      receiptKeywordItems : [],
      theKey: '',
      checkedKeywordItems: [],
      fe_media: null,
      imageLoaded: false,
      rotate: 0,
      currentReceiptId: '',
      showCommentModal: false,
      showParamsModal: false,
      showCtaMailModal: false,
      showCashbackModal: false,
      showActionsModal: false,
      deleteModal: false,
      deleteCode: '',
      newComment: '',
      showResetRegistrationConfirm: false,
      alsoBlock: false,
      showCloneConfirmModal: false,
      showResetFlagModal: false,
      cloneForm: {},
      editForm: {confirmed_receipt: false},
      editReceiptItems: [],
      showEditModal: false,
    };
  },
  methods: {
    getAdminReceipt(query) {
      this.$store
        .dispatch('pvReceipts/getAdminReceipt', { receiptId: query || this.receiptId })
        .then(() => {
          this.findAdminBlocked().then(() => {
            this.setJsonData();
          });
        });
    },
    setJsonData() {
      this.fe_media = this.receipt.fe_media;
      this.fe_receipt = JSON.parse(JSON.stringify(this.receipt));
      this.fe_receipt.fe_media = {
        file: 'changed in order to skip long scroll..',
      };
      delete this.fe_receipt.activation;
      this.setReceiptNone('receipt_type');
      this.setReceiptNone('receipt_metadata');
      this.setReceiptNone('receipt_analyze_attempts');
      this.setReceiptNone('receipt_item');
      this.setReceiptNone('receipt_item_cost');
      this.setReceiptNone('receipt_store');
      this.setReceiptNone('receipt_date');
      this.setReceiptNone('receipt_date_unix');
      this.setReceiptNone('receipt_number');
      this.setReceiptNone('receipt_address');
      this.setReceiptNone('receipt_phone');
      this.setReceiptNone('receipt_total');
      this.setReceiptNone('changed_receipt_data');
      this.setReceiptNone('confirmed_receipt');
      this.editReceiptItems = this.fe_receipt.receipt_items || [];
      this.editForm.receipt_store = this.fe_receipt.receipt_store;
      this.editForm.receipt_date = this.fe_receipt.receipt_date;
      this.editForm.confirmed_receipt = this.fe_receipt.confirmed_receipt;
      this.editForm.confirmed_registration = this.fe_receipt.confirmed_registration;
      this.editForm.review_attempts_additional = this.fe_receipt.review_attempts_additional;
      this.editForm.running_analyzer = this.fe_receipt.running_analyzer;

      this.loadedPage = true;
      setTimeout(() => {
        const jsonTag = document.getElementById('json');
        jsonTag.innerHTML = this.syntaxHighlight(JSON.stringify(this.fe_receipt, undefined, 2));
      }, 100);
    },
    setReceiptNone(key) {
      this.fe_receipt[key] = this.fe_receipt[key] === undefined ? null : this.fe_receipt[key];
    },
    syntaxHighlight(json) {
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
        function (match) {
          let cls = "a-number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "a-key";
            } else {
              cls = "a-string";
            }
          } else if (/true|false/.test(match)) {
            cls = "a-boolean";
          } else if (/null/.test(match)) {
            cls = "a-null";
          } else if (match < 3000000000 && match > 1000000000) {
            match = moment.unix(match).format('YYYY-MM-DD HH:mm:ss ZZ');
          }
          return `<span class="${cls}">${match}</span>`;
        }
      );
    },
    uncheckPrevious(event, key, prevValue) {
      const { value } = event.target;
      const index = this.checkedKeywordItems.indexOf(prevValue);
      if (index != -1) {
        this.checkedKeywordItems.splice(index, 1);
      }
      this.receiptKeywordItems[key] = value;
    },
    confirmOrDeclineReceipt() {
      const isConfirmed = this.receipt.confirmed_receipt;

      if (!isConfirmed) {
        try {
          // Show confirmation modal
          // reset dynamic values
          this.receiptKeywordItems = [];
          this.checkedKeywordItems = [];
  
          // This is a weird receipt (probably an invoice-type) with no lineitems
          // Let admins choose if they wanna confirm it anyways
          if (!this.receipt.receipt_items || !this.receipt.receipt_items.length) {
            this.showForceConfirmModal = true;
            return;
          }
  
          // fill the checkbox list with all line items that can be added to terms keywords
          const lineItems = map(this.receipt.receipt_metadata.Items.valueArray);
  
          lineItems.map(lineItem => {
            const { Description } = lineItem.valueObject;
            const content = lineItem.content;
  
            let descriptionTitle;
            let contentTitle;
            
            if (Description) {
              descriptionTitle = (Description.content || Description.valueString).replace(/\n/g, " ").toLowerCase();
              this.receiptKeywordItems.push(descriptionTitle);
            }
            if (content) {
              contentTitle = content.replace(/\n/g, " ").toLowerCase();
              if (!descriptionTitle || descriptionTitle !== contentTitle) {
                this.receiptKeywordItems.push(contentTitle);  
              }
            }
  
            // This receipt has neither description nor content, but still has line-items
            // Consider this as "weird", bail further analysis and ask admins to force-confirm
            if (!descriptionTitle && !contentTitle) {
              this.showForceConfirmModal = true;
              return;
            }
          });

          // modal gets shown by setting this variable to true (see 'active' flag on modal)
          this.showConfirmReceiptModal = true;
        } catch (error) {
          // Consider this as "weird", bail further analysis and ask admins to force-confirm
          console.error(error);
          this.showForceConfirmModal = true;
          return;
        }
      } else {
        this.showDeclineReceiptModal = true;
      }
    },
    dismissConfirmModal() {
      // don't leave any left-overs
      this.receiptKeywordItems = [];
      this.checkedKeywordItems = [];
      // close the modal
      this.showConfirmReceiptModal = false;
    },
    confirmReceipt() {
      this.$store
        .dispatch("pvReceipts/confirmAdminReceipt", {
          confirm: true,
          entityId: this.receiptId,
          key: this.theKey,
          additionalTerms: this.checkedKeywordItems
        }).then(() => {
          //close the modals
          this.showConfirmReceiptModal = false;
          this.showForceConfirmModal = false;

          this.setJsonData();
        });
    },
    sendCtaEmail() {
      receiptApi.sendCtaEmail(this.receiptId)
      .then((response) => {
        this.doAlert(response.message);
        this.showCtaMailModal = false;
      });
    },
    cloneReceipt() {
      this.$store
        .dispatch("pvReceipts/cloneReceipt", {receiptId:this.receiptId})
        .then((clone) => {

          let clonedUrl = window.location.href.replace(/a-r\/.*$/, `a-r/${clone._id}`);
          window.open(`${clonedUrl}`, '_blank');
          this.showCloneConfirmModal = false;
        });
    },
    editSaveReceipt() {
      this.$store
        .dispatch("pvReceipts/saveAdminReceipt", {
          receiptId: this.receiptId,
          ...this.editForm,
          receipt_items: this.editReceiptItems,
        }).then(() => {
          this.doAlert('Successfully saved the receipt!');
          this.showEditModal = false;
          this.setJsonData();
        });
    },
    resetFlagReceipt() {
      this.$store
        .dispatch("pvFlagged/resetFlagForReceipt", this.receiptId)
        .then(() => {
          this.showResetFlagModal = false;
          this.getAdminReceipt();
        });
    },
    resetRegistration() {
      this.$store
      .dispatch("pvReceipts/confirmAdminRegistration", {
        receiptId: this.receiptId,
        confirm: false,
      })
      .then(() => {
        this.showResetRegistrationConfirm = false;
        this.setJsonData();
      });

      if (this.alsoBlock) {
        this.blockUser();
      }
    },
    addAdminReceiptComment() {
      this.$store
      .dispatch("pvReceipts/addAdminReceiptComment", {
        comment: this.newComment,
        entityId: this.receiptId
      })
      .then(() => {
        this.newComment = "";
        this.setJsonData();
      });
    },
    async forceRunAction(actionId) {
      await this.$store.dispatch('pvReceipts/forcePVAction', { receiptId: this.receipt._id, actionId });
      this.doAlert('Forcefully executed action');
    },
    async runEligbleActions() {
      await this.$store.dispatch('pvReceipts/testPVActions', { receiptId: this.receipt._id });
      this.doAlert('Eligble actions executed');
    },
    declineReceipt() {
      this.$store
      .dispatch("pvReceipts/confirmAdminReceipt", { confirm: false, entityId: this.receiptId })
      .then(() => {
        this.showDeclineReceiptModal = false;
        this.setJsonData();
      });

      if (this.alsoBlock) {
        this.blockUser();
      }
    },
    confirmCashback() {
      this.$store
      .dispatch("pvReceipts/confirmAdminCashback", { confirm: !this.receipt.confirmed_cashback, entityId: this.receiptId })
      .then(() => {
        this.setJsonData();
      });
    },
    findAdminBlocked() {
      return this.$store.dispatch('pvBlocked/findAdminBlocked', {
        query: this.receipt.phone_nr
      });
    },
    blockUser () {
      this.$store.dispatch('pvBlocked/addAdminBlock', {
        phoneNr: this.receipt.phone_nr,
        email: this.receipt.email,
      }).then(() => {
        this.alsoBlock = false;
        this.findAdminBlocked();
      });
    },
    blockDelayedCta() {
      this.$store
      .dispatch("pvReceipts/addAdminReceiptDelayedCtaBlock", { block: !this.receipt.cta_actions_block, entityId: this.receiptId })
      .then(() => {
        this.setJsonData();
      });
    },
    onForceConfirm() {
      this.confirmReceipt();
    }, 
    onCloneConfirm(){
      this.cloneReceipt();
    },
    onResetRegistrationConfirm(){
      this.resetRegistration();
    }, 
    onEditConfirm(){
      this.editSaveReceipt();
    },
    onResetFlagConfirm(){
      this.resetFlagReceipt();
    },
    addReceiptItem() {
      this.editReceiptItems.push({});
      this.showEditModal = false;
      this.showEditModal = true;
    },
    removeReceiptItem() {
      this.editReceiptItems.pop();
      this.showEditModal = false;
      this.showEditModal = true;
    },
    doAlert(message) {
      EventBus.$emit("setAlert", {
        message,
        close: "OK",
        seconds: 4,
        top: 0,
        bot: "unset",
        left: 0,
        right: 0,
      });
    },
    popupLeft() {
      if (this.anyOpenModal) {
        return;
      }
      if (!this.receipts || !this.receipts.length) {
        this.doAlert('There are no receipts to navigate through.');
        return;
      }
      for(let i = 0; i < this.receipts.length; i++) {
        if (this.receipts[i]._id === this.receipt._id) {
          const prevIndex = i-1;
          if (prevIndex >= 0) {
            this.imageLoaded = false;
            this.$router.replace({ params: { receiptId: this.receipts[prevIndex]._id } });
            this.getAdminReceipt(this.receipts[prevIndex]._id);
          } else {
            this.doAlert('You are at the first receipt');
          }
        }
      }
    },
    popupRight() {
      if (this.anyOpenModal) {
        return;
      }
      if (!this.receipts || !this.receipts.length) {
        this.doAlert('There are no receipts to navigate through.');
        return;
      }
      for(let i = 0; i < this.receipts.length; i++) {
        if (this.receipts[i]._id === this.receipt._id) {
          const nextIndex = i+1;
          if (nextIndex <= this.receipts.length-1) {
            this.imageLoaded = false;
            this.$router.replace({ params: { receiptId: this.receipts[nextIndex]._id } });
            this.getAdminReceipt(this.receipts[nextIndex]._id);
          } else {
            this.doAlert('You are at the last receipt');
          }
        }
      }
    },
    handleKeydown (itemId) {
      switch (itemId.keyCode) {
        case 37:
        this.popupLeft();
        break;
        case 39:
        this.popupRight();
        break;
      }
    },
    formatDate(unix) {
      return moment.unix(unix).format("YYYY-MM-DD HH:mm:ss"); //"YYYY-MM-DD HH:mm:ss ZZ"
    },
    async downloadImage(imageUrl) {
      this.openUrl(imageUrl, true);
    },
    deleteReceipt() {
      this.$store.dispatch('pvReceipts/deleteAdminReceipt', {
        receiptId: this.receipt._id,
        deleteCode: this.deleteCode,
      }).then(() => {
        this.setAlert('Successfully deleted receipt!');
        this.$router.go(-1);
      });
    }
  },
  beforeMount () {
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.a-string{
  color: darkorange;
}
.a-number{
  color: lightgreen;
}
.a-boolean{
  color: #7171ff;
}
.a-null{
  color: #c7291b;
}
.a-key{
  color: lightblue;
}

.additional-terms{
  height: 40px;
  .additional-terms-input {
    margin-left: 20px;
  }
}

</style>

<style lang="scss" scoped>
.receipt-description {
  position: absolute;
  text-align: left;
  display: flex;
  height: 100%;
  width: 100%;

  .receipt-image-wrapper {
    position: relative;
    width: 50%;
    height: 100%;
    background-color: black;
    overflow: auto;
    .receipt-image {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: auto;
      margin: auto;
    }
    .button-wrapper {
      position: absolute;
      left: 5px;
      top: 5px;
      button {
        margin-right: 20px;
      }
    }

    .loading-text {
      position: absolute;
      top: 50%;
      left: 40%;
      color: red;
      font-size: 28px;

      @keyframes dot {
        0%, 20% { opacity: 0; }
        50%, 100% { opacity: 1; }
      }
  
      .dot-1 {
        animation: dot 1s infinite;
        animation-delay: 0.2s;
      }
  
      .dot-2 {
        animation: dot 1s infinite;
        animation-delay: 0.4s;
      }
  
      .dot-3 {
        animation: dot 1s infinite;
        animation-delay: 0.6s;
      }
    }
  }
  .receipt-metadata {
    color: white;
    width: 50%;
    height: calc(100vh - $nav-height);
    margin: 0;
    background-color: rgb(37, 37, 37);
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }

  tr:nth-child(even) {
    background-color: lightgrey;
  }
}
.blocked-user{
  position: fixed;
  left: 30%;
  top: 50%;
  color: white;
  background: linear-gradient(blue, red);
  padding: 20px;
  border-radius: 50% 40% 40% 20% / 70% 50% 30% 25%;
}

.rotate90 {
  height: 100%;
  width: 100%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate180 {
  height: 100%;
  width: 100%;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate270 {
  height: 100%;
  width: 100%;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.pdf-component {
  height: 250px !important;
  canvas {
    height: 250px !important;
  }
}
.date {
  font-size: 12px;
  margin-top: 10px;
  font-weight: 900;
}
.comment {
  border-bottom: 1px solid #cbcbcb;
}
.line-item {
  background: rgb(224, 224, 224);
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
}

.receipt-activation-params {
  .header {
    margin: 5px 0;
  }
}

</style>
