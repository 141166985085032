module.exports = {
  Goodiebag: [
    {
      key: 'company',
      db: 'company',
      default: null,
      search: true,
    },
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'uniqueName',
      db: 'unique_name',
      default: '',
    },
    {
      key: 'theme',
      db: 'theme',
      default: 'iris',
    },
    {
      key: 'columns',
      db: 'columns',
      default: 2,
    },
    {
      key: 'info',
      db: 'info',
      default: '',
    },
    {
      key: 'order',
      db: 'order',
      default: 0,
    },
    {
      key: 'filenames',
      db: 'media',
      default: '',
      json: true,
    },
    {
      key: 'thumbnailFilenames',
      db: 'thumbnail',
      default: '',
      json: true,
    },
    {
      key: 'thirdPartyCrmName',
      db: 'third_party_crm_name',
      default: '',
    },
    {
      key: 'banner',
      db: 'banner',
      default: false,
    },
    {
      key: 'bannerOffers',
      db: 'banner_offers',
      default: '[]',
      json: true,
    },
    /*{
      key: 'donations',
      db: 'donations',
      default: false,
    },*/
    {
      key: 'jwtLock',
      db: 'jwt_lock',
      default: false,
    },
    {
      key: 'jwtLockExplanation',
      db: 'jwt_lock_explanation',
      default: '',
    },
    {
      key: 'JwtLockButtonText',
      db: 'jwt_lock_button_text',
      default: '',
    },
    {
      key: 'JwtLockButtonUrl',
      db: 'jwt_lock_button_url',
      default: '',
    },
    {
      key: 'useScheduledPublish',
      db: 'scheduled_publish_at',
      default: false,
    },
    {
      key: 'localScheduledPublishAt',
      db: 'scheduled_publish_at',
      default: 0,
      date: true,
    },
    {
      key: 'localScheduledUnPublishAt',
      db: 'scheduled_unpublish_at',
      default: 0,
      date: true,
    },
    {
      key: 'useScheduledSoftPublish',
      db: 'scheduled_soft_publish_at',
      default: false,
    },
    {
      key: 'localScheduledSoftPublishAt',
      db: 'scheduled_soft_publish_at',
      default: 0,
      date: true,
    },
    {
      key: 'localScheduledSoftUnPublishAt',
      db: 'scheduled_soft_unpublish_at',
      default: 0,
      date: true,
    },
    {
      key: 'localScheduledArchiveAt',
      db: 'scheduled_archive_at',
      default: 0,
      date: true,
    },
    {
      key: 'directLink',
      db: 'direct_link',
      default: '',
    },
    {
      key: 'hideSubtitle',
      db: 'hide_subtitle',
      default: false,
    },
    {
      key: 'pointsVisited',
      db: 'points_visited',
      default: '0',
    },
  ],

  Offer: [
    {
      key: 'company',
      db: 'company',
      default: null,
      search: true,
    },
    {
      key: 'goodiebag',
      db: 'goodiebag',
      default: null,
      search: true,
    },
    {
      key: 'uniqueName',
      db: 'unique_name',
      default: '',
    },
    {
      key: 'firstHeader',
      db: 'first_header',
      default: '',
    },
    {
      key: 'secondHeader',
      db: 'second_header',
      default: '',
    },
    {
      key: 'quickText',
      db: 'quick_text',
      default: '',
    },
    {
      key: 'saveText',
      db: 'save_text',
      default: '',
    },
    {
      key: 'variations',
      db: 'variations',
      default: false,
    },
    {
      key: 'offerTag',
      db: 'offer_tag',
      default: '',
    },
    {
      key: 'thePrize',
      db: 'the_prize',
      default: '',
    },
    {
      key: 'columns',
      db: 'columns',
      default: 1,
    },
    {
      key: 'exclusivity',
      db: 'exclusivity',
      default: 1,
    },
    {
      key: 'value',
      db: 'value',
      default: 1,
    },
    {
      key: 'order',
      db: 'order',
      default: '',
    },
    {
      key: 'share',
      db: 'share',
      default: true,
    },
    {
      key: 'uniqueIcon',
      db: 'unique_icon',
      default: false,
    },
    {
      key: 'clickable',
      db: 'clickable',
      default: true,
    },
    {
      key: 'jwtLock',
      db: 'jwt_lock',
      default: false,
    },
    {
      key: 'jwtLockExplanation',
      db: 'jwt_lock_explanation',
      default: '',
    },
    {
      key: 'JwtLockButtonText',
      db: 'jwt_lock_button_text',
      default: '',
    },
    {
      key: 'JwtLockButtonUrl',
      db: 'jwt_lock_button_url',
      default: '',
    },
    {
      key: 'hideTextBox',
      db: 'hide_text_box',
      default: false,
    },
    {
      key: 'bannerSubHeader',
      db: 'banner_subheader',
      default: '',
    },
    {
      key: 'bannerHeader',
      db: 'banner_header',
      default: '',
    },
    {
      key: 'bannerButtonText',
      db: 'banner_button_text',
      default: '',
    },
    {
      key: 'mustLogin',
      db: 'must_login',
      default: false,
    },
    {
      key: 'useExpiresAt',
      db: 'starts_at',
      default: false,
    },
    {
      key: 'localStartsAt',
      db: 'starts_at',
      default: 0,
      date: true,
    },
    {
      key: 'localExpiresAt',
      db: 'expires_at',
      default: 0,
      date: true,
    },
    {
      key: 'useRecurring',
      db: 'recurring_time',
      default: false,
    },
    {
      key: 'recurringTotal',
      db: 'recurring_total',
      default: '',
    },
    {
      key: 'recurringStartsAt',
      db: 'recurring_starts_at',
      default: '',
    },
    {
      key: 'recurringExpiresAt',
      db: 'recurring_expires_at',
      default: '',
    },
    {
      key: 'linkButtonTitle',
      db: 'link_button_title',
      default: '',
    },
    {
      key: 'internalLink',
      db: 'internal_link',
      default: false,
    },
    {
      key: 'clearonAccount',
      db: 'clearon_acc',
      default: '',
      secret: true,
    },
    {
      key: 'clearonPassword',
      db: 'clearon_pass',
      default: '',
      secret: true,
    },
    {
      key: 'clearonId',
      db: 'clearon_id',
      default: '',
    },
    {
      key: 'clearonEanId',
      db: 'clearon_ean_id',
      default: '',
    },
    {
      key: 'directLink',
      db: 'direct_link',
      default: '',
    },
    {
      key: 'externalLink',
      db: 'external_link',
      default: '',
    },
    {
      key: 'severalExternalLinks',
      db: 'several_external_links',
      default: '',
      json: true,
    },
    {
      key: 'linksGallery',
      db: 'links_gallery',
      default: '',
    },
    {
      key: 'winnerAdress',
      db: 'winner_adress',
      default: false,
    },
    {
      key: 'winnersTotal',
      db: 'winners_total',
      default: '',
    },
    {
      key: 'appstore',
      db: 'appstore',
      default: '',
    },
    {
      key: 'gPlay',
      db: 'g_play',
      default: '',
    },
    {
      key: 'survey',
      db: 'survey',
      default: '',
      json: true,
    },
    {
      key: 'survey',
      db: 'survey',
      default: '',
      json: true,
    },
    {
      key: 'ctaEmail',
      db: 'cta_email',
      default: false,
    },
    {
      key: 'ctaEmailTemplate',
      db: 'cta_email_template',
      default: '',
    },
    {
      key: 'ctaSubcribeToEvents',
      db: 'cta_subscribe_event',
      default: false,
    },
    {
      key: 'ctaSubscribeEventsRecipients',
      db: 'cta_subscribe_event_recipients',
      default: '',
    },
    {
      key: 'expiration',
      db: 'expiration',
      default: '',
    },
    {
      key: 'recurringWinVideo',
      db: 'recurring_win_video',
      default: '',
      json: true,
    },
    {
      key: 'recurringLooseVideo',
      db: 'recurring_loose_video',
      default: '',
      json: true,
    },
    {
      key: 'iframeWebsite',
      db: 'iframe_website',
      default: '',
    },
    {
      key: 'filenames',
      db: 'media',
      default: '',
      json: true,
    },
    {
      key: 'thumbnailFilenames',
      db: 'thumbnail',
      default: '',
      json: true,
    },
    {
      key: 'termsText',
      db: 'terms_text',
      default: '',
    },
    {
      key: 'termsMerchandise',
      db: 'terms_merchandise',
      default: '',
    },
    {
      key: 'termsMerchandiseDefault',
      db: 'terms_merchandise_default',
      default: true,
    },
    {
      key: 'termsMustAccept',
      db: 'terms_must_accept',
      default: false,
    },
    {
      key: 'useScheduledPublish',
      db: 'scheduled_publish_at',
      default: false,
    },
    {
      key: 'localScheduledPublishAt',
      db: 'scheduled_publish_at',
      default: 0,
      date: true,
    },
    {
      key: 'localScheduledUnPublishAt',
      db: 'scheduled_unpublish_at',
      default: 0,
      date: true,
    },
    {
      key: 'useScheduledSoftPublish',
      db: 'scheduled_soft_publish_at',
      default: false,
    },
    {
      key: 'localScheduledSoftPublishAt',
      db: 'scheduled_soft_publish_at',
      default: 0,
      date: true,
    },
    {
      key: 'localScheduledSoftUnPublishAt',
      db: 'scheduled_soft_unpublish_at',
      default: 0,
      date: true,
    },
    {
      key: 'localScheduledArchiveAt',
      db: 'scheduled_archive_at',
      default: 0,
      date: true,
    },
    {
      key: 'pointsVisited',
      db: 'points_visited',
      default: '0',
    },
    {
      key: 'thirdPartyCrmName',
      db: 'third_party_crm_name',
      default: '',
    },
  ],

  Company: [
    {
      key: 'parentCompany',
      db: 'parentCompany',
      default: null,
      search: true,
    },
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'info',
      db: 'info',
      default: '',
    },
    {
      key: 'rpId',
      db: 'rp_id',
      default: '',
    },
    {
      key: 'uniqueName',
      db: 'unique_name',
      default: '',
    },
    {
      key: 'customUrl',
      db: 'custom_url',
      default: '',
    },
    {
      key: 'theme',
      db: 'theme',
      default: 'iris',
    },
    {
      key: 'fontMedium',
      db: 'font_medium',
      default: '',
    },
    {
      key: 'fontRegular',
      db: 'font_regular',
      default: '',
    },
    {
      key: 'fontBold',
      db: 'font_bold',
      default: '',
    },
    {
      key: 'club',
      db: 'club',
      default: false,
    },
    {
      key: 'replaceCompany',
      db: 'replace_company',
      default: false,
    },
    {
      key: 'brand400Color',
      db: 'brand_400_color',
      default: '',
    },
    {
      key: 'brand900Color',
      db: 'brand_900_color',
      default: '',
    },
    {
      key: 'brandButtonColor',
      db: 'brand_button_color',
      default: '',
    },
    {
      key: 'clearonAccount',
      db: 'clearon_account',
      default: '',
      secret: true,
    },
    {
      key: 'clearonPassword',
      db: 'clearon_pass',
      default: '',
      secret: true,
    },
    {
      key: 'whitelist',
      db: 'whitelist',
      default: '',
    },
    {
      key: 'jwtKey',
      db: 'jwt_key',
      default: '',
      secret: true,
    },
    {
      key: 'dashboard',
      db: 'dashboard',
      default: '',
    },
    {
      key: 'pixel',
      db: 'pixel',
      default: '',
    },
    {
      key: 'iframeDashboard',
      db: 'iframe_dashboard',
      default: '',
    },
    {
      key: 'closestStores',
      db: 'closest_stores',
      default: '',
    },
    {
      key: 'order',
      db: 'order',
      default: '',
    },
    {
      key: 'disableBlurryHeaderImage',
      db: 'disable_blurry_header',
      default: false,
    },
    {
      key: 'hideEnginioLogo',
      db: 'hide_enginio_logo',
      default: false,
    },
    {
      key: 'hideNavigation',
      db: 'hide_navigation',
      default: false,
    },
    {
      key: 'legalName',
      db: 'legal_name',
      default: '',
    },
    {
      key: 'orgNumber',
      db: 'org_number',
      default: '',
    },
    {
      key: 'phoneNr',
      db: 'phone_nr',
      default: '',
    },
    {
      key: 'email',
      db: 'email',
      default: '',
    },
    {
      key: 'address',
      db: 'address',
      default: '',
    },
    {
      key: 'postalCode',
      db: 'postal_code',
      default: '',
    },
    {
      key: 'city',
      db: 'city',
      default: '',
    },
    {
      key: 'country',
      db: 'country',
      default: '',
    },
    {
      key: 'loginMandatory',
      db: 'login_mandatory',
      default: false,
    },
    {
      key: 'loginDesign',
      db: 'login_design',
      default: 'STANDARD',
    },
    {

      key: 'loginLandingSubheader',
      db: 'login_landing_subheader',
      default: '',
    },
    {
      key: 'signupSubheader',
      db: 'signup_subheader',
      default: '',
    },
    {
      key: 'mandatoryEnginioSubscribe',
      db: 'mandatory_enginio_subscribe',
      default: false,
    },
    {
      key: 'defaultEnginioSubscribe',
      db: 'default_enginio_subscribe',
      default: false,
    },
    {
      key: 'mandatoryCompanySubscribe',
      db: 'mandatory_company_subscribe',
      default: false,
    },
    {
      key: 'defaultCompanySubscribe',
      db: 'default_company_subscribe',
      default: false,
    },
    {
      key: 'mandatoryCompanySubscribeDescription',
      db: 'mandatory_company_subscribe_description',
      default: false,
    },
    {
      key: 'privacyUrl',
      db: 'privacy_url',
      default: '',
    },
    {
      key: 'subscribeButtonText',
      db: 'subscribe_button_text',
      default: '',
    },
    {
      key: 'faqUrl',
      db: 'faq_url',
      default: '',
    },
    {
      key: 'pvCompanyId',
      db: 'pv_company_id',
      default: '',
    },
    {
      key: 'newProductButtonText',
      db: 'new_product_button_text',
      default: '',
    },
    {
      key: 'newProductButtonUrl',
      db: 'new_product_button_url',
      default: '',
    },
    {
      key: 'thirdPartyCrmName',
      db: 'third_party_crm_name',
      default: '',
    },
    {
      key: 'clubAbout',
      db: 'club_about',
      default: '',
    }
  ],

  ParentCompany: [
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'uniqueName',
      db: 'unique_name',
      default: '',
    },
    {
      key: 'thirdPartyCrmName',
      db: 'third_party_crm_name',
      default: '',
    },
  ],

  Hyperlink: [
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'url',
      db: 'url',
      default: '',
    },
  ],

  QrHuntGame: [
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'uniqueName',
      db: 'unique_name',
      default: '',
    },
    {
      key: 'textColor',
      db: 'text_color',
      default: 'black',
    },
    {
      key: 'accentColor',
      db: 'accent_color',
      default: '#EA7801',
    },
    {
      key: 'bgTextColor',
      db: 'bg_text_color',
      default: 'white',
    },
    {
      key: 'bgColor',
      db: 'bg_color',
      default: 'white',
    },
    {
      key: 'desktopBgColor',
      db: 'desktop_bg_color',
      default: 'white',
    },
    {
      key: 'termsText',
      db: 'terms_text',
      default: '',
    },
    {
      key: 'termsMerchandise',
      db: 'terms_merchandise',
      default: '',
    },
    {
      key: 'termsMerchandiseDefault',
      db: 'terms_merchandise_default',
      default: true,
    },
    {
      key: 'termsMustAccept',
      db: 'terms_must_accept',
      default: false,
    },
    {
      key: 'startDay',
      db: 'start_day',
      default: '',
    },
    {
      key: 'startHour',
      db: 'start_hour',
      default: '',
    },
    {
      key: 'expirationDay',
      db: 'expiration_day',
      default: '',
    },
    {
      key: 'expirationHour',
      db: 'expiration_hour',
      default: '',
    },
    {
      key: 'reasonNoRecurring',
      db: 'reason_no_recurring',
      default: '',
    },
    {
      key: 'reasonAlreadyEnd',
      db: 'reason_already_end',
      default: '',
    },
    {
      key: 'reasonNoStart',
      db: 'reason_no_start',
      default: '',
    },
    {
      key: 'reasonNoDayStart',
      db: 'reason_no_day_start',
      default: '',
    },
    {
      key: 'reasonNoDayExpires',
      db: 'reason_no_day_expires',
      default: '',
    },
    {
      key: 'tiebreak',
      db: 'tiebreak',
      default: false,
    },
    {
      key: 'tiebreakText',
      db: 'tiebreak_text',
      default: '',
    },
    {
      key: 'tiebreakQuestion',
      db: 'tiebreak_question',
      default: '',
      json: true,
    },
    {
      key: 'tiebreakButtonText',
      db: 'tiebreak_button_text',
      default: 'transl:Games.submit',
    },
    {
      key: 'mapButtonText',
      db: 'map_button_text',
      default: 'transl:Games.openmap',
    },
    {
      key: 'landingText',
      db: 'landing_text',
      default: '',
    },
    {
      key: 'gohuntText',
      db: 'gohunt_text',
      default: '',
    },
    {
      key: 'gohuntButtonText',
      db: 'gohunt_button_text',
      default: '',
    },
    {
      key: 'huntHeader',
      db: 'hunt_header',
      default: 'transl:Games.youhavescanned',
    },
    {
      key: 'huntObjectives',
      db: 'hunt_objectives',
      default: '[]',
      json: true,
    },
    {
      key: 'huntFunfactsHeader',
      db: 'hunt_funfacts_header',
      default: '',
    },
    {
      key: 'huntFunfacts',
      db: 'hunt_funfacts',
      default: '[]',
      json: true,
    },
    {
      key: 'surveyHeader',
      db: 'survey_header',
      default: '',
    },
    {
      key: 'survey',
      db: 'survey',
      default: '',
      json: true
    },
    {
      key: 'surveyText',
      db: 'survey_text',
      default: '',
    },
    {
      key: 'welcomeEmail',
      db: 'welcome_email',
      default: false,
    },
    {
      key: 'welcomeEmailSubject',
      db: 'welcome_email_subject',
    },
    {
      key: 'welcomeEmailTemplate',
      db: 'welcome_email_template',
    },
    {
      key: 'ctaDoneButton',
      db: 'cta_done_button',
    },
    {
      key: 'ctaDoneUrl',
      db: 'cta_done_url',
    },
    {
      key: 'ctaEmail',
      db: 'cta_email',
      default: false,
    },
    {
      key: 'ctaEmailSubject',
      db: 'cta_email_subject',
      default: '',
    },
    {
      key: 'ctaEmailTemplate',
      db: 'cta_email_template',
      default: '',
    },
    {
      key: 'reminderEmail',
      db: 'reminder_email',
      default: false,
    },
    {
      key: 'reminderEmailSubject',
      db: 'reminder_email_subject',
    },
    {
      key: 'reminderEmailTemplate',
      db: 'reminder_email_template',
    },
    {
      key: 'thanksEmail',
      db: 'thanks_email',
      default: false,
    },
    {
      key: 'thanksEmailSubject',
      db: 'thanks_email_subject',
    },
    {
      key: 'thanksEmailTemplate',
      db: 'thanks_email_template',
      default: '',
    },
    {
      key: 'winnerText',
      db: 'winner_text',
      default: ''
    },
    {
      key: 'winnerAddress',
      db: 'winner_address',
      default: false,
    },
    {
      key: 'thePrize',
      db: 'the_prize',
      default: '',
    },
    {
      key: 'mustLogin',
      db: 'must_login',
      default: true,
    },
    {
      key: 'useExpiresAt',
      db: 'starts_at',
      default: false,
    },
    {
      key: 'localStartsAt',
      db: 'starts_at',
      default: 0,
      date: true,
    },
    {
      key: 'localExpiresAt',
      db: 'expires_at',
      default: 0,
      date: true,
    },
    {
      key: 'thirdPartyCrmName',
      db: 'third_party_crm_name',
      default: '',
    },
  ],

  PVCompany: [
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'info',
      db: 'info',
      default: '',
    },
    {
      key: 'jwtKey',
      db: 'jwt_key',
      default: '*',
      secret: true,
    },
    {
      key: 'bazaarvoiceKey',
      db: 'bazaarvoice_key',
      default: '*',
      secret: true,
    },
    {
      key: 'testfreaksKey',
      db: 'testfreaks_key',
      default: '*',
      secret: true,
    }
  ],

  PVCampaign: [
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'info',
      db: 'info',
      default: '',
    },
    {
      key: 'cashbackCurrency',
      db: 'cashback_currency',
      default: '',
    },
    {
      key: 'cashbackDelay',
      db: 'cashback_delay',
      default: 0,
    },
    {
      key: 'cashbackTotalAmount',
      db: 'cashback_total_amount',
      default: '',
    },
    {
      key: 'useScheduledPublish',
      db: 'scheduled_publish_at',
      default: false,
    },
    {
      key: 'localScheduledPublishAt',
      db: 'scheduled_publish_at',
      default: 0,
      date: true,
    },
    {
      key: 'localScheduledUnPublishAt',
      db: 'scheduled_unpublish_at',
      default: 0,
      date: true,
    },
  ],

  PVActivation: [
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'info',
      db: 'info',
      default: '',
    },
    {
      key: 'textColor',
      db: 'text_color',
      default: '',
    },
    {
      key: 'accentColor',
      db: 'accent_color',
      default: '',
    },
    {
      key: 'receiptStep',
      db: 'receipt_step',
      default: false,
    },
    {
      key: 'registrationStep',
      db: 'registration_step',
      default: false,
    },
    {
      key: 'surveyStep',
      db: 'survey_step',
      default: false,
    },
    {
      key: 'addressStep',
      db: 'address_step',
      default: false,
    },
    {
      key: 'addressCountry',
      db: 'address_country',
      default: false,
    },
    {
      key: 'addressState',
      db: 'address_state',
      default: false,
    },
    {
      key: 'reviewStep',
      db: 'review_step',
      default: false,
    },
    {
      key: 'reviewPartner',
      db: 'review_partner',
      default: '',
    },
    {
      key: 'reviewHeader',
      db: 'review_header',
      default: '',
    },
    {
      key: 'reviewRating',
      db: 'review_rating',
      default: false,
    },
    {
      key: 'reviewTextarea',
      db: 'review_textarea',
      default: false,
    },
    {
      key: 'reviewDelay',
      db: 'review_delay',
      default: 3,
    },
    {
      key: 'reviewModeration',
      db: 'review_moderation',
      default: true,
    },
    {
      key: 'reviewModerationAttempts',
      db: 'review_moderation_attempts',
      default: 3,
    },
    {
      key: 'reviewModerationProductDescription',
      db: 'review_moderation_product_description',
      default: '',
    },
    {
      key: 'reviewModerationAppropriateThreshold',
      db: 'review_moderation_appropriate_threshold',
      default: 70,
    },
    {
      key: 'reviewModerationRelevanceThreshold',
      db: 'review_moderation_relevance_threshold',
      default: 70,
    },
    {
      key: 'reviewModerationReadabilityThreshold',
      db: 'review_moderation_readability_threshold',
      default: 70,
    },
    {
      key: 'cashbackStep',
      db: 'cashback_step',
      default: false,
    },
    {
      key: 'availableForPhoneNr',
      db: 'available_for_phone_nrs',
      default: '[]',
      json: true,
    },
    {
      key: 'activationReceiptInheritance',
      db: 'activation_receipt_inheritance',
      default: '',
    },
    {
      key: 'recurring',
      db: 'recurring',
      default: false,
    },
    {
      key: 'recurringDaily',
      db: 'recurring_daily',
      default: false,
    },
    {
      key: 'recurringLimit',
      db: 'recurring_limit',
      default: '',
    },
    {
      key: 'timezone',
      db: 'timezone',
      default: 'Europe/Stockholm',
    },
    {
      key: 'receiptTermsMultiple',
      db: 'receipt_terms_multiple',
      default: '[{ "key": "first", "label": "", "terms": ["first", "second"], "term_minimum_amount": 0, "amount_minimum_estimate": 0, "amount_maximum_estimate": 0, "multiple_rows": false, "mandatory": true }]',
      json: true,
    },
    {
      key: 'receiptTermsSearchAdvanced',
      db: 'receipt_terms_search_advanced',
      default: true,
    },
    {
      key: 'receiptTermIgnoreDots',
      db: 'receipt_term_ignore_dots',
      default: false,
    },
    {
      key: 'receiptTermIgnoreSpaces',
      db: 'receipt_term_ignore_spaces',
      default: false,
    },
    {
      key: 'receiptTermInWholeString',
      db: 'receipt_term_in_whole_string',
      default: false,
    },
    {
      key: 'receiptRetailerInWholeString',
      db: 'receipt_retailer_in_whole_string',
      default: false,
    },
    {
      key: 'receiptDateInWholeString',
      db: 'receipt_date_in_whole_string',
      default: false,
    },
    {
      key: 'registrationTermsMultiple',
      db: 'registration_terms_multiple',
      // ... terms: [], regexp: "", ... not supported yet
      default: '[{ "key": "first", "label": "", "serial": true, "serial_description": "", "imei": true, "imei_description": "", "method": "", "modelnr": true, "modelnrs": ["modelNr1", "modelNr2"] }]',
      json: true,
    },
    /*
    {
      key: 'registrationTerms',
      db: 'registration_terms',
      default: '[]',
      json: true,
    },
    {
      key: 'registrationSerial',
      db: 'registration_serial',
      default: false,
    },
    {
      key: 'registrationSerialDescription',
      db: 'registration_serial_description',
      default: '',
    },
    {
      key: 'registrationImei',
      db: 'registration_imei',
      default: false,
    },
    {
      key: 'registrationImeiDesctiption',
      db: 'registration_imei_description',
      default: '',
    },
    {
      key: 'registrationRegexp',
      db: 'registration_regexp',
      default: '',
    },
    {
      key: 'registrationMethod',
      db: 'registration_method',
      default: '',
    },
    {
      key: 'registrationModelnr',
      db: 'registration_modelnr',
      default: false,
    },
    {
      key: 'registrationModelnrs',
      db: 'registration_modelnrs',
      default: '[]',
      json: true,
    },*/
    {
      key: 'products',
      db: 'products',
      default: '[]',
      json: true,
    },
    {
      key: 'reviewProducts',
      db: 'review_products',
      default: '{}',
      json: true,
    },
    {
      key: 'survey',
      db: 'survey',
      default: '[]',
      json: true,
    },
    {
      key: 'receiptShopList',
      db: 'receipt_shop_list',
      default: '[]',
      json: true,
    },
    {
      key: 'receiptRetailerList',
      db: 'receipt_retailer_list',
    },
    {
      key: 'receiptShopExcludeList',
      db: 'receipt_shop_exclude_list',
      default: '[]',
      json: true,
    },
    {
      key: 'receiptTermMinimumAmount',
      db: 'receipt_term_minimum_amount',
      default: 0,
    },
    {
      key: 'receiptAmountMinimumEstimate',
      db: 'receipt_amount_minimum_estimate',
      default: 0,
    },
    {
      key: 'receiptAmountMaximumEstimate',
      db: 'receipt_amount_maximum_estimate',
      default: 0,
    },
    {
      key: 'receiptCanChangeItem',
      db: 'receipt_can_change_item',
      default: false,
    },
    {
      key: 'receiptCanChangeItemCost',
      db: 'receipt_can_change_item_cost',
      default: false,
    },
    {
      key: 'receiptCanChangeStore',
      db: 'receipt_can_change_store',
      default: false,
    },
    {
      key: 'useReceiptDateRange',
      db: 'receipt_date_range_from',
      default: false,
    },
    {
      key: 'localReceiptDateRangeFrom',
      db: 'receipt_date_range_from',
      default: 0,
      date: true,
    },
    {
      key: 'localReceiptDateRangeTo',
      db: 'receipt_date_range_to',
      default: 0,
      date: true,
    },
    {
      key: 'receiptDateMaxDays',
      db: 'receipt_date_max_days',
      default: 0,
    },
    {
      key: 'receiptCanChangeDate',
      db: 'receipt_can_change_date',
      default: false,
    },
    {
      key: 'ctaCodeVisible',
      db: 'cta_code_visible',
      default: false,
    },
    {
      key: 'ctaCodesBoolean',
      db: 'cta_codes',
      default: false,
    },
    {
      key: 'ctaCodesInheritance',
      db: 'cta_codes_inheritance',
      default: '',
    },
    {
      key: 'ctaCodesAmount',
      db: 'none',
      default: 0,
    },
    {
      key: 'cashbackAmount',
      db: 'cashback_amount',
      default: '',
    },
    {
      key: 'cashbackProducts',
      db: 'cashback_products',
      default: '{}',
      json: true,
    },
    {
      key: 'cashbackCurrency',
      db: 'cashback_currency',
      default: '',
    },
    {
      key: 'cashbackPayoutmethods',
      db: 'cashback_payoutmethods',
      default: 'card, phone, account',
    },
    {
      key: 'cashbackDelay',
      db: 'cashback_delay',
      default: 0,
    },
    {
      key: 'cashbackManualPartner',
      db: 'cashback_manual_partner',
      default: false,
    },
    /*{
      key: 'cashbackTotalAmount',
      db: 'cashback_total_amount',
      default: '',
    },*/
    {
      key: 'ctaActions',
      db: 'cta_actions',
      default: [],
    },
    {
      key: 'termsMustAccept',
      db: 'terms_must_accept',
      default: false,
    },
    {
      key: 'termsMerchandise',
      db: 'terms_merchandise',
      default: '',
    },
    {
      key: 'termsMerchandiseDefault',
      db: 'terms_merchandise_default',
      default: true,
    },
    {
      key: 'termsText',
      db: 'terms_text',
      default: '',
    },
    {
      key: 'ctaDoneButton',
      db: 'cta_done_button',
      default: '',
    },
    {
      key: 'ctaDoneUrl',
      db: 'cta_done_url',
      default: '',
    },
    {
      key: 'ctaDoneCustomMessage',
      db: 'cta_done_custom_message',
      default: '',
    },
    {
      key: 'ctaDoneCustomIcon',
      db: 'cta_done_custom_icon',
      default: '',
    },
    {
      key: 'ctaDoneButtonDescription',
      db: 'cta_done_button_description',
      default: '',
    },
    {
      key: 'market',
      db: 'market',
      default: '',
    },
    {
      key: 'enginioActivation',
      db: 'enginio_activation',
      default: '',
    },
    {
      key: 'receiptSimilarity',
      db: 'receipt_similarity',
      default: '99',
    },
    {
      key: 'receiptAutomaticallyConfirmed',
      db: 'receipt_automatically_confirmed',
      default: false,
    },
    {
      key: 'ctaDoneHeader',
      db: 'cta_done_header',
      default: '',
    },
    {
      key: 'ctaDoneAddNewButton',
      db: 'cta_done_add_new_button',
      default: '',
    },
    {
      key: 'useScheduledPublish',
      db: 'scheduled_publish_at',
      default: false,
    },
    {
      key: 'localScheduledPublishAt',
      db: 'scheduled_publish_at',
      default: 0,
      date: true,
    },
    {
      key: 'localScheduledUnPublishAt',
      db: 'scheduled_unpublish_at',
      default: 0,
      date: true,
    },
    {
      key: 'campaign',
      db: 'campaign',
      default: null,
      search: true,
    },
  ],

  PVChain: [
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'terms',
      db: 'terms',
      default: '[]',
      json: true,
    },
    {
      key: 'reviewPartner',
      db: 'review_partner',
      default: '',
    },
  ],

  PVRetailer: [
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'terms',
      db: 'terms',
      default: '[]',
      json: true,
    },
    {
      key: 'ignoreDots',
      db: 'ignore_dots',
      default: true,
    },
    {
      key: 'ignoreSpaces',
      db: 'ignore_spaces',
      default: true,
    },
    {
      key: 'reviewPartner',
      db: 'review_partner',
      default: '',
    },
    {
      key: 'chain',
      db: 'chain',
      default: null,
      search: true,
    },
  ],

  PVStore: [
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'phoneNr',
      db: 'phone_nr',
      default: '',
    },
    {
      key: 'phoneNrSecondary',
      db: 'phone_nr_secondary',
      default: '[]',
      json: true,
    },
    {
      key: 'address',
      db: 'address',
      default: '',
    },
    {
      key: 'houseNr',
      db: 'house_nr',
      default: '',
    },
    {
      key: 'house',
      db: 'house',
      default: '',
    },
    {
      key: 'road',
      db: 'road',
      default: '',
    },
    {
      key: 'city',
      db: 'city',
      default: '',
    },
    {
      key: 'cityDistrict',
      db: 'city_district',
      default: '',
    },
    {
      key: 'suburb',
      db: 'suburb',
      default: '',
    },
    {
      key: 'postalCode',
      db: 'postal_code',
      default: '',
    },
    {
      key: 'country',
      db: 'country',
      default: '',
    },
    {
      key: 'addressUnsorted',
      db: 'address_unsorted',
      default: '',
    },
    {
      key: 'phoneNrFormatted',
      db: 'phone_nr_formatted',
      default: '',
    },
    {
      key: 'addressFormatted',
      db: 'address_formatted',
      default: '',
    },
    {
      key: 'houseNrFormatted',
      db: 'house_nr_formatted',
      default: '',
    },
    {
      key: 'houseFormatted',
      db: 'house_formatted',
      default: '',
    },
    {
      key: 'roadFormatted',
      db: 'road_formatted',
      default: '',
    },
    {
      key: 'cityFormatted',
      db: 'city_formatted',
      default: '',
    },
    {
      key: 'cityDistrictFormatted',
      db: 'city_district_formatted',
      default: '',
    },
    {
      key: 'postalCodeFormatted',
      db: 'postal_code_formatted',
      default: '',
    },
    {
      key: 'countryFormatted',
      db: 'country_formatted',
      default: '',
    },
    {
      key: 'addressUnsortedFormatted',
      db: 'address_unsorted_formatted',
      default: '',
    },
    {
      key: 'retailer',
      db: 'retailer',
      default: null,
      search: true,
    },
  ],

  PVPartnerAPI: [
    {
      key: 'name',
      db: 'name',
      default: '',
    },
    {
      key: 'info',
      db: 'info',
      default: '',
    },
    {
      key: 'company',
      db: 'company',
      default: null,
      search: true,
    },
    {
      key: 'apiUrl',
      db: 'api_url',
      default: '',
      secret: true,
    },
    {
      key: 'apiMethod',
      db: 'api_method',
      default: '',
      secret: true,
    },
    {
      key: 'apiAuth',
      db: 'api_auth',
      default: '',
      secret: true,
    },
    {
      key: 'apiKey',
      db: 'api_key',
      default: '',
      secret: true,
    },
    {
      key: 'apiSecret',
      db: 'api_secret',
      default: '',
      secret: true,
    },
  ],
};
