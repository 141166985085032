/* eslint-disable no-shadow, no-param-reassign */
import contentApi from '../../api/content.api';

// initial state
const state = {
  content: {},
  contents: [],
};

// getters
const getters = {
  content: state => state.content,
  contents: state => state.contents,
};

// actions
const actions = {
  findAdminContents({ commit }, payload) {
    return contentApi.findAdminContents(payload)
      .then((response) => {
        commit('CONTENTS', response.contents);
      });
  },

  addAdminContent({ commit }, payload) {
    return contentApi.addAdminContent(payload)
      .then((response) => {
        commit('ADD_CONTENT', response.content);
      });
  },

  getAdminContent({ commit }, payload) {
    return contentApi.getAdminContent(payload)
      .then((response) => {
        commit('CONTENT', response.content);
      });
  },

  saveAdminContent({ commit }, payload) {
    return contentApi.saveAdminContent(payload)
      .then((response) => {
        commit('CONTENT', response.content);
      });
  },
};

// mutations
const mutations = {
  CONTENTS(state, assets) {
    state.assets = assets;
  },
  ADD_CONTENT(state, asset) {
    state.assets.push(asset);
  },
  CONTENT(state, asset) {
    state.asset = asset;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
