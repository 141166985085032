/* eslint-disable no-shadow, no-param-reassign */
import campaignApi from '../../api/pvCampaign.api';

// initial state
const state = {
  campaign: {},

  campaignQuery: '',
  campaigns: [],

  modalPvCampaigns: [],
};

// getters
const getters = {
  campaign: state => state.campaign,

  campaignQuery: state => state.campaignQuery,
  campaigns: state => state.campaigns,

  modalPvCampaigns: state => state.modalPvCampaigns,
};

// actions
const actions = {
  // ADMIN
  findAdminPVCampaign({ commit }, payload) {
    commit('CAMPAIGN_STATUS', 'LOADING');
    return campaignApi.findAdminPVCampaign(payload)
      .then((response) => {
        commit('CAMPAIGNS', response.campaigns);
        commit('CAMPAIGN_STATUS', 'FINISHED');
      });
  },

  findAdminModalPVCampaigns({ commit }, payload) {
    commit('CAMPAIGN_STATUS', 'LOADING');
    return campaignApi.findAdminPVCampaign(payload)
      .then((response) => {
        commit('MODAL_CAMPAIGNS', response.campaigns);
        commit('CAMPAIGN_STATUS', 'FINISHED');
      });
  },

  addAdminPVCampaign({ commit }, payload) {
    commit('CAMPAIGN_STATUS', 'LOADING');
    return campaignApi.addAdminPVCampaign(payload)
      .then((response) => {
        commit('ADD_CAMPAIGN', response.campaign);
        commit('CAMPAIGN_STATUS', 'FINISHED');
      });
  },

  saveAdminPVCampaign({ commit }, payload) {
    commit('CAMPAIGN_STATUS', 'LOADING');
    return campaignApi.saveAdminPVCampaign(payload)
      .then((response) => {
        commit('SAVE_CAMPAIGN', response.campaign);
        commit('CAMPAIGN_STATUS', 'FINISHED');
      });
  },

  getAdminPVCampaign({ commit }, payload) {
    commit('CAMPAIGN_STATUS', 'LOADING');
    return campaignApi.getAdminPVCampaign(payload)
      .then((response) => {
        commit('CAMPAIGN', response.campaign);
        commit('CAMPAIGN_STATUS', 'FINISHED');
      });
  },

  publiciseAdminPVCampaign({ commit }, payload) {
    commit('CAMPAIGN_STATUS', 'LOADING');
    return campaignApi.publiciseAdminPVCampaign(payload)
      .then((response) => {
        commit('CAMPAIGN', response.campaign);
        commit('CAMPAIGN_STATUS', 'FINISHED');
      });
  },

  deleteAdminPVCampaign({ commit }, payload) {
    commit('CAMPAIGN_STATUS', 'LOADING');
    return campaignApi.deleteAdminPVCampaign(payload)
      .then(() => {
        commit('DELETE_CAMPAIGN', payload.entityId);
        commit('CAMPAIGN_STATUS', 'FINISHED');
        return;
      });
  },

  getAdminPVExangerates({ commit }, payload) {
    commit('CAMPAIGN_STATUS', 'LOADING');
    return campaignApi.getAdminPVExangerates(payload.from)
      .then((response) => {
        commit('CAMPAIGN_STATUS', 'FINISHED');
        return response.rates;
      });
  },
};

// mutations
const mutations = {
  CAMPAIGN(state, campaign) {
    state.campaign = campaign;
  },
  CAMPAIGNS(state, campaigns) {
    state.campaigns = campaigns;
  },
  ADD_CAMPAIGN(state, campaign) {
    state.campaigns.unshift(campaign);
  },

  CAMPAIGN_QUERY(state, campaignQuery) {
    state.campaignQuery = campaignQuery;
  },
  SAVE_CAMPAIGN(state, campaign) {
    state.campaign = campaign;
  },
  DELETE_CAMPAIGN(state) {
    state.campaign = {};
  },
  CAMPAIGN_STATUS(state, status) {
    state.campaignStatus = status;
  },
  MODAL_CAMPAIGNS(state, campaigns) {
    state.modalPvCampaigns = [];
    for (let i = 0; i < campaigns.length; i += 1) {
      state.modalPvCampaigns.push({
        value: campaigns[i]._id,
        text: campaigns[i].name,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
