<template>
  <div class="entity-editor__container">

    <div v-if="!entity && loaded" class="entity-editor__add-entity">
      <div class="entity-editor__add-entity-title">{{ title }}</div>
      <div
        v-for="(group, key) in fieldGroups"
        :key="key"
        class="entity-editor__add-entity-inputs-container">
        <div
          v-for="field in group.fields"
          :key="field.label">

          <div v-if="hasFieldPermission(field) && key.includes('name')">
            <InputField
              v-if="field.checkbox"
              :label="field.label"
              :value="info[field.model]"
              @input="setVariable(field.model, $event)"
              inputType="checkbox" />

            <InputField
              v-else-if="field.inputType === 'select'
              && field.selectOptions && field.selectOptions.length
              && field.selectSettings && Object.keys(field.selectSettings).length"
              inputType="select"
              :label="field.label"
              v-model="info[field.model]"
              :selectSettings="field.selectSettings"
              :selectOptions="field.selectOptions"/>

            <InputField
              v-else-if="field.inputType === 'select'
              && field.selectOptions && field.selectOptions.length"
              inputType="select"
              :label="field.label"
              v-model="info[field.model]"
              :selectOptions="field.selectOptions"/>

            <md-datepicker
              v-else-if="field.date"
              v-model="info[field.model]"
              :value="info[field.model]">
              <label>{{ field.label }}</label>
            </md-datepicker>

            <div v-else-if="field.htmlText">
              <div>{{ field.label }}</div>
              <CKEditor
                v-model="info[field.model]">
              </CKEditor>
            </div>

            <div v-else-if="field.autoComplete">
              <AutoComplete
                v-if="field.model === 'parentCompany'"
                :value="info[field.model] ? info[field.model].text : ''"
                :options="modalParentCompanies"
                :required="true"
                :is-async="true"
                unique-id="change"
                title="Client"
                placeholder="Find Client"
                resultPosition="relative"
                @input="findAdminParentCompanies($event)"
                @selected="info[field.model] = $event"/>
              <AutoComplete
                v-if="field.model === 'company'"
                :value="info[field.model] ? info[field.model].text : ''"
                :options="modalCompanies"
                :required="true"
                :is-async="true"
                unique-id="change"
                title="Brand"
                placeholder="Find Brand by name"
                resultPosition="relative"
                @input="findAdminCompanies($event)"
                @selected="info[field.model] = $event"/>
              <AutoComplete
                v-if="field.model === 'goodiebag' || field.model === 'parentGoodiebag'"
                :value="info[field.model] ? info[field.model].text : ''"
                :options="modalGoodiebags"
                :required="true"
                :is-async="true"
                unique-id="change"
                :title="`${field.model === 'goodiebag' ? 'Goodiebag' : 'Parent Goodiebag'}`"
                :placeholder="`Find ${field.model === 'goodiebag' ? 'Goodiebag' : 'Parent Goodiebag'} by name`"
                resultPosition="relative"
                @input="findAdminGoodiebags($event)"
                @selected="info[field.model] = $event"/>
              <AutoComplete
                v-if="field.model === 'campaign'"
                :value="info[field.model] ? info[field.model].text : ''"
                :options="modalPvCampaigns"
                :required="true"
                :is-async="true"
                unique-id="change"
                title="PV Campaign"
                :placeholder="`Find PV Campaign by name`"
                resultPosition="relative"
                @input="findAdminModalPVCampaigns($event)"
                @selected="info[field.model] = $event"/>
              <AutoComplete
                v-if="field.model === 'chain'"
                :value="info[field.model] ? info[field.model].text : ''"
                :options="modalPvChains"
                :required="true"
                :is-async="true"
                unique-id="change"
                title="PV Chain"
                :placeholder="`Find PV Chain by name`"
                resultPosition="relative"
                @input="findAdminModalPvChains($event)"
                @selected="info[field.model] = $event; info[`${field.model}Id`] = $event.value"/>
              <AutoComplete
                v-if="field.model === 'retailer'"
                :value="info[field.model] ? info[field.model].text : ''"
                :options="modalPvRetailers"
                :required="true"
                :is-async="true"
                unique-id="change"
                title="PV Retailer"
                :placeholder="`Find PV Retailer by name`"
                resultPosition="relative"
                @input="findAdminModalPVRetailer($event)"
                @selected="info[field.model] = $event; info[`${field.model}Id`] = $event.value"/>
            </div>

            <InputField
              v-else
              :label="field.label"
              :value="info[field.model]"
              @input="setVariable(field.model, $event)"
              :inputType="field.inputType" />
          </div>
        </div>
      </div>

      <div
        @click="addEntity()"
        class="btn btn-transparent">
        Add
      </div>
    </div>

    <div
         v-else-if="isEditing && loaded"
         class="entity-editor__wrapper"
         :class="{ '--nobreadcrumbs': !useBreadcrumbs }">

      <div
           v-if="useBreadcrumbs"
           class="entity-editor__breadcrumb">
        <aBreadcrumb :goodiebag-id="breadcrumb" :admin="admin" />
      </div>

      <div class="entity-editor__header">
        <div class="entity-editor__header-title">{{ title }}</div>
        <div class="entity-editor__live" v-if="entity.public"> Live</div>
        <div class="entity-editor__offline" v-if="!entity.public && entityType !== 'Hyperlink'"> Offline</div>
        <iconComponent
          v-if="urlPath"
          class="entity-editor__open-new-tab"
          @click="openUrl(`${windowOrigin}/${urlPath}`, true)"
          symbol="openInBrowser"/>
      </div>

      <div class="entity-editor__button-bar">
        <div
            @click="saveEntity()"
            class="btn btn-activate">
          Save Changes
        </div>

        <div
          v-if="entityType === 'Offer' || entityType === 'Goodiebag'"
          class="btn btn-transparent"
          @click="bookmarkAdminEntity()">
          Bookmark
        </div>

        <div class="entity-editor__button-dropdown">
          <p>•••</p>
          <div class="entity-editor__button-dropdown-content">
            <div
                v-if="admin && (entityType === 'Offer' || entityType === 'Goodiebag')"
                @click="addAdminEntityPicture()"
                class="btn btn-transparent">
              Save filenames
            </div>

            <div
                v-if="entityType === 'Offer' || entityType === 'Goodiebag'"
                @click="deleteEntityPictures()"
                class="btn btn-transparent">
              Delete Pictures
            </div>

            <div
                v-if="entityType === 'Company'"
                @click="uploadModal = true"
                class="btn btn-transparent">
              Upload Arbitrary Asset
            </div>

            <div
                v-if="(entity.winner_adress || (entity.survey && entity.survey.length)) && entityType === 'Offer'"
                @click="getWinners()"
                class="btn btn-transparent">
              Get Winners
            </div>

            <div
                v-if="(entity.winner_adress || (entity.survey && entity.survey.length)) && entityType === 'Offer'"
                @click="deleteWinnersModal = true"
                class="btn btn-transparent">
              Delete Winners
            </div>

            <div
                v-if="entityType !== 'ParentCompany'
                && entityType !== 'Hyperlink'
                && entityType !== 'PVChain'
                && entityType !== 'PVRetailer'
                && entityType !== 'PVStore'"
                @click="publiciseAdminEntity()"
                class="btn btn-transparent">
              {{ entity.public ? 'Hide' : 'Publish' }}
            </div>

            <div
                v-if="entityType !== 'ParentCompany'
                && entityType !== 'Company'
                && entityType !== 'Hyperlink'
                && entityType !== 'PVCampaign'
                && entityType !== 'PVChain'
                && entityType !== 'PVRetailer'
                && entityType !== 'PVStore'"
                @click="softPubliciseAdminEntity()"
                class="btn btn-transparent">
              {{ entity.public_soft ? 'Undo soft publish' : 'Soft publish' }}
            </div>

            <div
                v-if="entityType === 'Goodiebag'"
                @click="publiciseLandingEntity()"
                class="btn btn-transparent">
              {{ entity.public_landing ? 'Hide Landing' : 'Publish Landing' }}
            </div>

            <div
                v-if="entityType === 'Offer'"
                @click="closeEntity()"
                class="btn btn-transparent">
              {{ entity.closed ? 'Open' : 'Close' }}
            </div>

            <div
                v-if="entityType === 'Offer' || entityType === 'Goodiebag'"
                @click="archiveEntity()"
                class="btn btn-transparent">
              {{ entity.archived ? 'Retrieve' : 'Archive' }}
            </div>

            <div
                v-if="admin && entityType === 'Company'"
                @click="createLinkModal = true"
                style="width: 100%; margin: 1rem 0;"
                class="btn btn-transparent">
                Create Link
            </div>

            <div
                v-if="admin && entityType === 'Company'"
                @click="$router.push({ name: 'AdminCompanyContentCreator', params: { companyId: entity._id } })"
                style="width: 100%; margin: 1rem 0;"
                class="btn btn-transparent">
                Content Creator
            </div>

            <div
                v-if="admin && entityType.includes('Game')"
                style="width: 100%; margin: 1rem 0;"
                class="btn btn-transparent"
                @click="sendAdminGameReminder()">
                Send Reminder email
            </div>
            <div
                v-if="admin && entityType.includes('Game')"
                style="width: 100%; margin: 1rem 0;"
                class="btn btn-transparent"
                @click="sendAdminGameThanks()">
                Send Thanks email
            </div>
            <div
                v-if="admin && entityType.includes('Game')"
                style="width: 100%; margin: 1rem 0;"
                class="btn btn-transparent"
                @click="getAdminGameParticipants()">
                Get Participants
            </div>
            <div
                v-if="admin && entityType.includes('Game')"
                style="width: 100%; margin: 1rem 0;"
                class="btn btn-transparent"
                @click="deleteParticipantsModal = true">
                Remove Participants
            </div>

            <div
              v-if="admin && entityType == 'PVActivation'"
              style="width: 100%; margin: 1rem 0;"
              class="btn btn-transparent"
              @click="showCloneModal()">
              Clone
            </div>

            <div
                v-if="admin"
                @click="deleteModal = true"
                class="btn btn-transparent">
              Delete
            </div>

            <!--<downloadCsv
              v-if="foundWinners.length"
              class="div md-primary"
              :data="foundWinners"
              name="winners.csv">
              <div style="padding: 0.6rem;">Download Winners</div>
            </downloadCsv>-->
          </div>
        </div>
      </div>

      <div class="entity-editor__groups-wrapper">
        <div
          v-for="(group, key) in fieldGroups"
          :key="key"
          class="entity-editor__groups-container">
          <collapsable
            :header="{bgColor: '#F9D7B3', textPosition: 'left'}" :content="{bgColor: 'white'}"
            :collapsed="key.includes('Group')">
            <template v-slot:header>
              <div>
                <div class="entity-editor__groups-title">
                  {{ group.title }}
                </div>
              </div>
            </template>

            <template v-slot:content>
              <div
                class="entity-editor__groups"
                :class="`entity-editor__columns-${group.columns}`">
                <div
                  v-for="field in group.fields"
                  :key="field.label">
                  <div v-if="field.line" class="entity-editor__separator"></div>
                  <div
                    v-else-if="hasFieldPermission(field) && !field.title"
                    class="entity-editor__custom-group">
                    <div v-if="field.fieldDescription" class="entity-editor__link-info">{{field.fieldDescription}}</div>
                    <button v-if="field.popupInfo" class="entity-editor__popupinfo" @click="openPopupInfo(field.label, field.popupInfo)">i</button>
                    <InputField v-if="field.checkbox" :label="field.label" :value="info[field.model]"
                      @input="setVariable(field.model, $event)" inputType="checkbox" />

                    <InputField
                      v-else-if="field.inputType === 'select'
                      && field.selectOptions && field.selectOptions.length
                      && field.selectSettings && Object.keys(field.selectSettings).length"
                      inputType="select"
                      :label="field.label"
                      v-model="info[field.model]"
                      :selectSettings="field.selectSettings"
                      :selectOptions="field.selectOptions"/>

                    <InputField
                      v-else-if="field.inputType === 'select'
                      && field.selectOptions && field.selectOptions.length"
                      inputType="select"
                      :label="field.label"
                      v-model="info[field.model]"
                      :selectOptions="field.selectOptions"/>

                    <md-datepicker
                      v-else-if="field.date"
                      v-model="info[field.model]"
                      :value="info[field.model]">
                      <label>{{ field.label }}</label>
                    </md-datepicker>

                    <div v-else-if="field.htmlText">
                      <div>{{ field.label }}</div>
                      <CKEditor
                        v-model="info[field.model]">
                      </CKEditor>
                    </div>

                    <Uploader v-else-if="field.upload && isEditing" :role="userType"
                      :media="entity[field.model]" :entity="field.entity" :mediaType="field.mediaType" :entityId="entity._id"
                      :aspectWidth="field.aspectWidth" :aspectHeight="field.aspectHeight" />

                    <div v-else-if="field.autoComplete">
                      <AutoComplete
                        v-if="field.model === 'parentCompany'"
                        :value="info[field.model] ? info[field.model].text : ''"
                        :options="modalParentCompanies"
                        :required="true" :is-async="true"
                        unique-id="change"
                        title="Client"
                        placeholder="Find Client"
                        resultPosition="relative"
                        @input="findAdminParentCompanies($event)"
                        @selected="info[field.model] = $event"/>
                      <AutoComplete
                        v-if="field.model === 'company'"
                        :value="info[field.model] ? info[field.model].text : ''"
                        :options="modalCompanies"
                        :required="true"
                        :is-async="true"
                        unique-id="change"
                        title="Brand"
                        placeholder="Find Brand by name"
                        resultPosition="relative"
                        @input="findAdminCompanies($event)"
                        @selected="info[field.model] = $event"/>
                      <AutoComplete
                        v-if="field.model === 'goodiebag' || field.model === 'parentGoodiebag'"
                        :value="info[field.model] ? info[field.model].text : ''"
                        :options="modalGoodiebags"
                        :required="true"
                        :is-async="true"
                        unique-id="change"
                        :title="`${field.model === 'goodiebag' ? 'Goodiebag' : 'Parent Goodiebag'}`"
                        :placeholder="`Find ${field.model === 'goodiebag' ? 'Goodiebag' : 'Parent Goodiebag'} by name`"
                        resultPosition="relative"
                        @input="findAdminGoodiebags($event)"
                        @selected="info[field.model] = $event"/>
                      <AutoComplete
                        v-if="field.model === 'campaign'"
                        :value="info[field.model] ? info[field.model].text : ''"
                        :options="modalPvCampaigns"
                        :required="true"
                        :is-async="true"
                        unique-id="change"
                        title="PV Campaign"
                        :placeholder="`Find PV Campaign by name`"
                        resultPosition="relative"
                        @input="findAdminModalPVCampaigns($event)"
                        @selected="info[field.model] = $event"/>
                      <AutoComplete
                        v-if="field.model === 'chain'"
                        :value="info[field.model] ? info[field.model].text : ''"
                        :options="modalPvChains"
                        :required="true"
                        :is-async="true"
                        unique-id="change"
                        title="PV Chain"
                        :placeholder="`Find PV Chain by name`"
                        resultPosition="relative"
                        @input="findAdminModalPvChains($event)"
                        @selected="info[field.model] = $event; info[`${field.model}Id`] = $event.value"/>
                      <AutoComplete
                        v-if="field.model === 'retailer'"
                        :value="info[field.model] ? info[field.model].text : ''"
                        :options="modalPvRetailers"
                        :required="true"
                        :is-async="true"
                        unique-id="change"
                        title="PV Retailer"
                        :placeholder="`Find PV Retailer by name`"
                        resultPosition="relative"
                        @input="findAdminModalPVRetailer($event)"
                        @selected="info[field.model] = $event; info[`${field.model}Id`] = $event.value"/>
                    </div>

                    <div v-else-if="field.groupHeader" class="entity-editor__header-grid">
                      <p class="entity-editor__group-header">{{field.groupHeader}}</p>
                    </div>
                    <div v-else-if="field.linksGallery">
                      <LinksGallery
                        :label="label"
                        :parentHyperlinks="hyperlinks"
                        :parentLinks="links"
                        :admin="admin"
                        @changedHyperlinkLabel="changeHyperlinkLabel($event)"
                        @pickedHyperlink="addPickedHyperlink($event)"
                        @changedLink="changeLink($event.index, $event.value)"
                        @deletedLink="removeLink($event.index)" />
                    </div>
                    <div v-else-if="field.actionsManager">
                      <ActionsManager
                        :label="label"
                        :actions="actions"
                        :activation="entity"
                        @pickedAction="addPickedAction($event)"
                        @setInputValue="setActionInputValue($event.index, $event.value)"
                        @setOfferId="setOfferId($event.index, $event.value)"
                        @setCollectionId="setCollectionId($event.index, $event.value)"
                        @setDuration="setDuration($event.index, $event.value)"
                        @setTrigger="setTrigger($event.index, $event.value)"
                        @setPoints="setPoints($event.index, $event.value)"
                        @setPayload="setPayload($event.index, $event.value)"
                        @setEndpoint="setEndpoint($event.index, $event.value)"
                        @setEmailSubject="setEmailSubject($event.index, $event.value)"
                        @setEmailTemplate="setEmailTemplate($event.index, $event.value)"
                        @setEmailTo="setEmailTo($event.index, $event.value)"
                        @setEmailAttachment="setEmailAttachment($event.index, $event.fileObj)"
                        @openPDFEditor="openPDFEditor($event.index, $event.pdfSource)"
                        @removedAction="removeAction($event.index)" />
                    </div>
                    <Multichoice
                      v-else-if="field.multichoice"
                      :label="label"
                      :routeName="field.routeName"
                      :entities="info[field.model]"
                      :entityType="field.entityType"
                      :admin="admin"
                      @change="setVariable(field.model, $event)"/>

                    <InputField v-else :label="field.label" :value="info[field.model]" :inputType="field.inputType"
                      @input="setVariable(field.model, $event)" />
                  </div>
                </div>
              </div>
            </template>
          </collapsable>
        </div>
      </div>
    </div>

    <modal v-if="createLinkModal" size="xlarge" :header="{closeButton: true, text: 'Create Company login link'}"
        @close="createLinkModal = false;" modalType="fullscreen">
      <slot>
        <div>You can use either email or phone nr, or both</div>
        <InputField
          label="Email"
          v-model="linkEmail"/>
        <InputField
          label="Phone nr"
          v-model="linkMobile"/>
        <InputField
          label="Role"
          inputType="select"
          :selectOptions="partnerRoles"
          v-model="linkRole"/>
        <div style="user-select: all; overflow-wrap: break-word;">
          {{ token }}
        </div>
        <div>
          <div class="btn btn-activate" @click="createAdminCompanyLink()">Link</div>
        </div>
      </slot>
    </modal>

    <modal v-if="deleteModal" :header="{closeButton: true, text: 'Delete Entity'}"
           @close="deleteModal = false" size="medium">
      <slot>
        <InputField
          v-if="entityType.includes('PV')"
          label="Delete code"
          placeholder="****"
          v-model="deleteCode"/>
        <div class="btn btn-transparent" @click="deleteEntity()">Delete</div>
      </slot>
    </modal>

    <modal v-if="popupInfoModal" :header="{closeButton: true, text: popupHeader}"
           @close="popupInfoModal = false" size="large">
      <slot>
        <div class="popup-modal-content" v-html="popupInfo"></div>
      </slot>
    </modal>

    <modal v-if="deleteParticipantsModal" :header="{closeButton: true, text: 'Delete all Participants'}"
           @close="deleteParticipantsModal = false" size="medium">
      <slot>
        <h3>Are you sure?</h3>
        <div class="btn btn-transparent" @click="deleteAdminGameParticipants()">Delete</div>
      </slot>
    </modal>

    <modal v-if="deleteWinnersModal" :header="{closeButton: true, text: 'Delete Winners'}"
           @close="deleteWinnersModal = false" size="medium">
      <slot>
        <div class="btn btn-transparent" @click="deleteWinners()">Delete</div>
      </slot>
    </modal>

    <modal
      v-if="pdfEditorModal"
      :header="{closeButton: true, text: 'PDF Editor'}"
      modalType="fullscreen"
      @close="pdfEditorModal = false">
      <slot>
        <PdfFormEditor
          v-if="pdfEditorIndex >= 0"
          :action="actions[pdfEditorIndex]"
          :pdfSource="pdfEditorSource"
          @formUpdated="updatePDFFile($event)"/>
        <button
          class="btn btn-transparent"
          @click="pdfEditorModal = false">
          Close
        </button>
      </slot>
    </modal>

    <modal
      v-if="uploadModal"
      :header="{closeButton: true, text: 'Upload Arbitrary Asset'}"
      :overlay="{ clickable: false }"
      @close="uploadModal = false" size="xlarge">
      <slot>
        <div for="mediaTypeSelect">Select media type</div>
        <md-radio class="md-primary"
          value="Pdf"
          name="mediaTypeSelect"
          v-model="mediaTypeSelect">Pdf</md-radio>
        <md-radio class="md-primary"
          value="Image"
          name="mediaTypeSelect"
          v-model="mediaTypeSelect">Image</md-radio>
        <md-radio class="md-primary"
          value="Video"
          name="mediaTypeSelect"
          v-model="mediaTypeSelect">Video</md-radio>

        <Uploader
          :role="userType"
          :mediaType="mediaTypeSelect"
          entity="CompanyArbitrary"
          :entityId="entity._id"
          :aspectWidth="NaN"
          :aspectHeight="NaN"
          @pickedFile="arbitraryAsset = $event"/>
        <div v-if="arbitraryAsset.url" style="border-top: 1px solid grey; margin-top: 20px; padding-top: 10px;">
          <div>New asset url:</div>
          <div style="user-select: all;">{{ windowOrigin.includes('localhost') ? '' : `${assetOrigin}` }}{{ arbitraryAsset?.url }}</div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { cloneDeep as _cloneDeep, first as _first } from 'lodash-es';
import validator from 'validator';
import Vue from "vue";
import Uploader from '../Uploader/Uploader.vue';
import LinksGallery from '../LinksGallery/LinksGallery.vue';
import ActionsManager from '../ActionsManager/ActionsManager.vue';
import Multichoice from '../Multichoice/Multichoice.vue';
// import aPdfTextExtract from './APdfTextExtract';
import offerApi from '../../../api/offer.api';
import gameApi from '../../../api/game.api';
import InputField from '../InputField/InputField.vue';
import Collapsable from '../Collapsable/Collapsable.vue';
import AutoComplete from '../AutoComplete/AutoComplete.vue';
import PdfFormEditor from '../PdfFormEditor/PdfFormEditor.vue';
import CKEditor from '../CKEditor/CKEditor.vue';
import aBreadcrumb from "../../../components/ABreadcrumb";
import entityFieldGroups from './entityFieldGroups';
import entityFieldModels from './entityFieldModels';

export default {
  name: 'EntityEditorComponent',
  components: {
    Uploader,
    LinksGallery,
    ActionsManager,
    Multichoice,
    InputField,
    AutoComplete,
    aBreadcrumb,
    Collapsable,
    PdfFormEditor,
    CKEditor,
    // aPdfTextExtract,
  },
  props: {
    // Entity could be a Goodiebag, Offer, Company etc..
    // if empty we will create a Goodiebag, Offer, Company etc..
    entity: {
      type: Object,
      required: false,
      default: () => {},
    },
    /**
     * Title
     */
    title: {
      type: String,
      required: false,
      default: '',
    },
    // EnitypeType could be 'Goodiebag', 'Offer', 'Company', 'ParentCompany'
    entityType: {
      type: String,
      required: true,
    },
    /**
     * FieldGroups are an object with lists of editable fields
     * Not supported anymore: using a file for this instead
     */
    /*fieldGroups: {
      type: Object,
      required: true,
    },*/
    /**
     * model.key, model.default, model.db
     * Not supported anymore: using a file for this instead
    */
    /*fieldModels: {
      type: Array,
      required: true,
    },*/
    /**
     * Adds values to the HTTPrequest, so for example GoodiebagId, CompanyId
     */
    attach: {
      type: Array,
      required: false,
      default: () => [],
    },
    breadcrumb: {
      type: String,
      required: false,
      default: '',
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  mounted() {
    this.setVariables();
  },
  watch: {
    entity(newVal, oldVal) {
      if (newVal._id && newVal._id !== oldVal._id) {
        this.setVariables();
      }
    },
  },
  computed: {
    ...mapGetters('parentCompanies', ['modalParentCompanies']),
    ...mapGetters(['modalCompanies', 'modalGoodiebags']),
    ...mapGetters(['modalCompanies', 'modalGoodiebags']),
    ...mapGetters('pvCampaigns', ['modalPvCampaigns']),
    ...mapGetters('pvChains', ['modalPvChains']),
    ...mapGetters('pvRetailers', ['modalPvRetailers']),
    useBreadcrumbs() {
      return this.breadcrumb && this.breadcrumb != '';
    },
    isEditing() {
      return this.entity && this.entity._id ? true : false;
    },
    namespace() {
      let namespace = '';
      if (this.entityType === 'ParentCompany') {
        namespace = 'parentCompanies/';
      } else if (this.entityType.includes('Game')) {
        namespace = 'games/';
      } else if (this.entityType.includes('PVActivation')) {
        namespace = 'pvActivations/';
      } else if (this.entityType.includes('PVCampaign')) {
        namespace = 'pvCampaigns/';
      } else if (this.entityType.includes('PVCompany')) {
        namespace = 'pvCompanies/';
      } else if (this.entityType.includes('PVChain')) {
        namespace = 'pvChains/';
      } else if (this.entityType.includes('PVRetailer')) {
        namespace = 'pvRetailers/';
      } else if (this.entityType.includes('PVStore')) {
        namespace = 'pvStores/';
      }
      return namespace;
    },
    userType() {
      return this.admin ? 'Admin' : 'Company';
    },
    vuexEntityType() {
      if (this.entityType.includes('Game')) {
        return 'Game';
      } else {
        return this.entityType;
      }
    },
    finalEntityType() {
      if (this.entityType.includes('Game')) {
        return this.entityType.split('Game')[0];
      } else {
        return this.entityType;
      }
    },
    urlPath() {
      const entityId = this.entity.unique_name || this.entity._id;
      if (this.entityType.includes('Game')) {
        const gameType = this.toUrlCase(this.entityType.split('Game')[0]);
        return `g/${gameType}/${entityId}`;
      } else if (this.entityType.includes('Offer')) {
        return `a/${entityId}`;
      } else if (this.entityType.includes('Goodiebag')) {
        return `c/${entityId}`;
      } else if (this.entityType.includes('Company')) {
        return `e/${entityId}`;
      } else if (this.entityType.includes('PVActivation') && this.entity.enginio_activation) {
        return `pv/${entityId}/${this.entity.enginio_activation}`;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      validator,
      info: {},
      foundWinners: [],
      filenames: '[]',
      thumbnailFilenames: '{}',
      value: '',
      loaded: false,
      linkEmail: '',
      linkMobile: '',
      linkRole: '',
      partnerRoles: ['PARTNER_ALL','PARTNER_WRITE','PARTNER_READ'],
      token: '',
      fieldData: [],
      entityFieldGroups,
      entityFieldModels,
      fieldGroups: [],
      fieldModels: [],
      customFieldGroups: [],
      hyperlinks: [],
      links: [],
      htmlTextFallback: '',
      label: '',
      actions: [],
      deleteCode: '',
      canSave: false,
      deleteModal: false,
      deleteParticipantsModal: false,
      createLinkModal: false,
      deleteWinnersModal: false,
      pdfEditorSource: null,
      pdfEditorIndex: -1,
      pdfEditorModal: false,
      uploadModal: false,
      arbitraryAsset: {},
      mediaTypeSelect: 'Image',
      assetOrigin: window.origin !== undefined ? window.origin : window.location.origin,
      popupInfoModal: false,
      popupHeader: '',
      popupInfo: '',
    };
  },
  methods: {
    setVariables() {
      this.setGroupsAndModels();
      const entity = _cloneDeep(this.entity);
      if (this.isEditing) {
        this.info.entityId = entity._id;
        for (let i = 0; i < this.fieldModels.length; i+=1) {
          const model = this.fieldModels[i];
          let value;
          let customField = false;
          try {
            if (model.json) {
              value = JSON.stringify(entity[model.db]);
            } else if (model.date) {
              value = entity[model.db] * 1000;
            } else if (model.search) {
              value = {
                text: entity[model.db]?.name,
                value: entity[model.db]?._id
              };
            } else if (model.key == 'linksGallery') {
              if (entity[model.db]) {
                this.label = entity[model.db].label;
                this.hyperlinks = entity[model.db].hyperlinks || [];
                this.links = entity[model.db].links || [];
              }
              customField = true;
            } else if (model.key == 'ctaActions') {
              this.actions = entity[model.db];
              value = this.actions;
            } else if (model.secret) {
              value = '*';
            } else {
              value = entity[model.db];
            }
          } catch (err) {
            console.log(err);
            console.log('Issue with entity, could be missing some info from the database?', model, entity);
            value = '';
          }
          if (!customField) {
            this.info[model.key] = value;
          }
        }
      } else {
        for (let j = 0; j < this.fieldModels.length; j+=1) {
          const model2 = this.fieldModels[j];
          let theDefault = model2.default;
          try {
            if (typeof model2.default == 'string' && model2.default.includes('transl:')) {
              theDefault = this.transl[model2.default.split('transl:')[1]];
            }
          } catch (error) {
            theDefault = model2.default;
            console.error(error);
          }
          this.info[model2.key] = theDefault;
        }
      }
      for (let g = 0; g < this.attach.length; g+=1) {
        const obj = this.attach[g];
        this.info[obj.key] = obj.value;
      }
      this.loaded = true;
      setTimeout(() => {
        this.canSave = true;
      }, 2000);
    },
    setGroupsAndModels() {
      this.fieldGroups = _cloneDeep(this.entityFieldGroups[this.entityType]);
      this.fieldModels = _cloneDeep(this.entityFieldModels[this.entityType]);

      /*
        const fieldGroups = _cloneDeep(this.entityFieldGroups[this.entityType]);
        this.fieldModels = _cloneDeep(this.entityFieldModels[this.entityType]);
        this.fieldGroups = fieldGroups;
        this.customFieldGroups = customFieldGroups;
      */

      if (this.isEditing) {
        let aspectWidth = 1;
        let aspectHeight = 1;
        let thumbAspectWidth = 1;
        let thumbAspectHeight = 1;
        let useMedia = true;
        let useThumbnail = true;
        let useBanner = false;
        let useIcon = true;
        let useIcon11 = false;
        let useGameLanding = false;
        let useGameMap = false;
        let useGameTiebreak = false;
        let useGameHunt = false;
        let useLanding = false;
        let useClub = false;

        if (this.entityType == 'Offer') {
          aspectWidth = NaN;
          aspectHeight = NaN;
          const tags = this.transl.tags;
          const allTags = [
            tags.news,
            tags.campaign,
            tags.contest,
            tags.recipe,
            tags.survey,
            tags.game,
            tags.soon,
            tags.finished,
            '',
          ];
          this.fieldGroups.advancedGroup.fields.push({
            label: 'Offer Tag',
            model: 'offerTag',
            options: allTags,
            helper: '',
            validator: false,
            required: true,
          });
          if (!this.entity.unique_icon) {
            useIcon = false;
          }
          if (this.entity.columns == 2) {
            if (this.entity.hide_text_box) {
              thumbAspectWidth = 11;
              thumbAspectHeight = 9;
            } else {
              thumbAspectWidth = 2;
              thumbAspectHeight = 1;
            }
          } else {
            if (this.entity.hide_text_box) {
              thumbAspectWidth = 945;
              thumbAspectHeight = 1181;
            }
          }
          useBanner = true;
        } else if (this.entityType == 'Goodiebag') {
          useLanding = true;
          if (this.entity.columns == 1) {
            thumbAspectWidth = 1;
            thumbAspectHeight = 1;
          } else if (this.entity.columns == 2) {
            thumbAspectWidth = 2;
            thumbAspectHeight = 1;
          } else if (this.entity.columns == 3) {
            thumbAspectWidth = 3;
            thumbAspectHeight = 1;
          }
          if (this.entity.theme == 'nemo') {
            aspectWidth = NaN;
            aspectHeight = NaN;
          } else {
            aspectWidth = 4;
            aspectHeight = 1;
          }
        } else if (this.entityType == 'Company') {
          aspectWidth = 4;
          aspectHeight = 1;
          thumbAspectWidth = 2;
          thumbAspectHeight = 1;
          useClub = this.entity.club;
          useIcon11 = true;
        } else if (this.entityType == 'Hyperlink') {
          // aspectWidth = 25;
          // aspectHeight = 8;
          aspectWidth = NaN;
          aspectHeight = NaN;
          useThumbnail = false;
          useIcon = false;
        } else if (this.entityType == 'ParentCompany') {
          useMedia = false;
          useThumbnail = false;
          useIcon = false;
        } else if (this.vuexEntityType == 'Game') {
          useMedia = false;
          useThumbnail = false;
          useIcon = false;
          useGameLanding = true;
          useGameMap = true;
          useGameTiebreak = true;
          useGameHunt = true;
        } else if (this.entityType == 'PVCompany') {
          useMedia = false;
          useThumbnail = false;
          useIcon = false;
        } else if (this.entityType == 'PVCampaign') {
          useMedia = false;
          useThumbnail = false;
          useIcon = false;
        } else if (this.entityType == 'PVActivation') {
          useMedia = true;
          useThumbnail = false;
          useIcon = false;
        } else if (this.entityType == 'PVChain') {
          useMedia = false;
          useThumbnail = false;
          useIcon = false;
        } else if (this.entityType == 'PVRetailer') {
          useMedia = false;
          useThumbnail = false;
          useIcon = false;
        } else if (this.entityType == 'PVStore') {
          useMedia = false;
          useThumbnail = false;
          useIcon = false;
        }

        if (useMedia) {
          this.fieldGroups.uploads.fields.push({
            editOnly: true,
            label: 'Media',
            model: 'media',
            entity: this.entityType,
            mediaType: 'Picture',
            helper: '',
            aspectWidth,
            aspectHeight,
            upload: true,
          });
        }
        if (useThumbnail) {
          this.fieldGroups.uploads.fields.push({
            label: 'Thumbnail',
            model: 'thumbnail',
            entity: this.entityType,
            mediaType: 'Thumbnail',
            helper: '',
            aspectWidth: thumbAspectWidth,
            aspectHeight: thumbAspectHeight,
            upload: true,
          });
        }
        if (useIcon) {
          this.fieldGroups.uploads.fields.push({
            editOnly: true,
            label: 'Icon',
            model: 'icon',
            entity: this.entityType,
            mediaType: 'Icon',
            helper: '',
            aspectWidth: NaN,
            aspectHeight: NaN,
            upload: true,
          });
        }
        if (useIcon11) {
          this.fieldGroups.uploads.fields.push({
            editOnly: true,
            label: 'Icon 11',
            model: 'icon_1x1',
            entity: this.entityType,
            mediaType: 'Icon11',
            helper: '',
            aspectWidth: 1,
            aspectHeight: 1,
            upload: true,
          });
        }
        if (useLanding) {
          this.fieldGroups.uploads.fields.push({
            editOnly: true,
            label: 'Landing',
            model: 'landing',
            entity: this.entityType,
            mediaType: 'Landing',
            helper: '',
            aspectWidth: 4,
            aspectHeight: 5,
            upload: true,
          });
        }
        if (useClub) {
          this.fieldGroups.uploads.fields.push({
            editOnly: true,
            label: 'Club Media',
            model: 'club_media',
            entity: this.entityType,
            mediaType: 'ClubMedia',
            helper: '',
            aspectWidth: 1920,
            aspectHeight: 575,
            upload: true,
          });
        }
        if (useGameLanding) {
          this.fieldGroups.uploads.fields.push({
            editOnly: true,
            label: 'Landing Image',
            model: 'landing_image',
            entity: this.entityType,
            mediaType: 'Landing',
            helper: '',
            aspectWidth: NaN,
            aspectHeight: NaN,
            upload: true,
          });
        }
        if (useGameMap) {
          this.fieldGroups.uploads.fields.push({
            editOnly: true,
            label: 'Map Image',
            model: 'map_image',
            entity: this.entityType,
            mediaType: 'MapImage',
            helper: '',
            aspectWidth: NaN,
            aspectHeight: NaN,
            upload: true,
          });
        }
        if (useGameTiebreak) {
          this.fieldGroups.uploads.fields.push({
            editOnly: true,
            label: 'Tiebreak Image',
            model: 'tiebreak_image',
            entity: this.entityType,
            mediaType: 'TiebreakImage',
            helper: '',
            aspectWidth: NaN,
            aspectHeight: NaN,
            upload: true,
          });
        }
        if (useGameHunt) {
          this.fieldGroups.uploads.fields.push({
            editOnly: true,
            label: 'Hunt Image',
            model: 'hunt_image',
            entity: this.entityType,
            mediaType: 'HuntImage',
            helper: '',
            aspectWidth: NaN,
            aspectHeight: NaN,
            upload: true,
          });
        }
        if (useBanner) {
          this.fieldGroups.uploads.fields.push({
            label: 'Banner',
            model: 'banner',
            entity: this.entityType,
            mediaType: 'Banner',
            helper: '',
            aspectWidth: 2,
            aspectHeight: 1,
            upload: true,
          });
        }
      } else {
        if (this.entityType == 'Offer') {
          this.fieldGroups.name.fields.push({
            label: 'ctaEmailCodes, codes (PS, you can only add them here)',
            model: 'ctaEmailCodes',
            inputType: 'textarea',
            helper: '',
            validator: false,
            required: false,
          });
        }
      }

      if (this.admin) {
        const chooseGoodiebag = {
          autoComplete: true,
          label: 'Goodiebag',
          model: 'goodiebag',
          helper: '',
          validator: false,
          required: true,
        };
        const goodiebagModel = {
          key: 'goodiebag',
          db: 'goodiebag',
          default: '',
          search: true,
        };
        const chooseCompany = {
          autoComplete: true,
          label: 'Brand',
          model: 'company',
          helper: '',
          validator: false,
          required: true,
        };
        const companyModel = {
          key: 'company',
          db: 'company',
          default: '',
          search: true,
        };
        const chooseParentCompany = {
          autoComplete: true,
          label: 'Client',
          model: 'parentCompany',
          helper: '',
          validator: false,
          required: true,
        };
        const parentCompanyModel = {
          key: 'parentCompany',
          db: 'parentCompany',
          default: '',
          search: true,
        };
        const choosePVCampaign = {
          autoComplete: true,
          label: 'PV Campaign',
          model: 'campaign',
          helper: '',
          validator: false,
          required: true,
        };
        const choosePVChain = {
          autoComplete: true,
          label: 'PV Chain',
          model: 'chain',
          helper: '',
          validator: false,
          required: true,
        };
        const choosePVRetailer = {
          autoComplete: true,
          label: 'PV Retailer',
          model: 'retailer',
          helper: '',
          validator: false,
          required: true,
        };
        /*const choosePVStore = {
          autoComplete: true,
          label: 'PV Store',
          model: 'store',
          helper: '',
          validator: false,
          required: true,
        };*/

        if (this.entityType == 'Offer') {
          this.fieldGroups.name.fields.push(chooseGoodiebag);
          this.fieldGroups.name.fields.push(chooseCompany);
          this.fieldModels.push(goodiebagModel);
          this.fieldModels.push(companyModel);
        } else if (this.entityType == 'Goodiebag') {
          chooseGoodiebag.label = 'Parent Goodiebag';
          chooseGoodiebag.model = 'parentGoodiebag';
          goodiebagModel.key = 'parentGoodiebag';
          goodiebagModel.db = 'parentGoodiebag';
          this.fieldGroups.name.fields.push(chooseGoodiebag);
          this.fieldGroups.name.fields.push(chooseCompany);
          this.fieldModels.push(goodiebagModel);
          this.fieldModels.push(companyModel);
        } else if (this.entityType == 'Company') {
          this.fieldGroups.name.fields.push(chooseParentCompany);
          this.fieldModels.push(parentCompanyModel);
        } else if (this.vuexEntityType == 'Game') {
          this.fieldGroups.name.fields.push(chooseCompany);
          this.fieldModels.push(companyModel);
        } else if (this.entityType == 'PVActivation') {
          this.fieldGroups.name.fields.push(choosePVCampaign);
        } else if (this.entityType == 'PVRetailer') {
          this.fieldGroups.name.fields.push(choosePVChain);
        } else if (this.entityType == 'PVStore') {
          this.fieldGroups.name.fields.push(choosePVRetailer);
        }
      }
    },
    setVariable(key, value) {
      this.$set(this.info, key, value);
      this.info = Object.assign({}, this.info);
    },
    hasFieldPermission(field) {
      if (!this.admin && field.admin) {
        return false;
      }
      if (!this.isEditing && field.editOnly) {
        return false;
      }
      return true;
    },
    addEntity() {
      this.editSendInfo();
      console.log(`${this.namespace}add${this.userType}${this.vuexEntityType}`)
      this.$store.dispatch(`${this.namespace}add${this.userType}${this.vuexEntityType}`, this.info).then(() => {
        this.setAlert('Successfully added entity!');
        // this.reset();
      });
    },
    saveEntity() {
      if (!this.canSave) {
        this.setAlert('Cannot save the entity yet!');
      }
      this.editSendInfo();
      this.$store.dispatch(`${this.namespace}save${this.userType}${this.vuexEntityType}`, this.info).then(() => {
        this.setAlert('Successfully saved entity!');
        this.loaded = false;
        setTimeout(() => {
          this.setVariables();
        }, 2);
      });
    },
    editSendInfo() {
      if (this.entityType == 'Offer') {
        if (!this.admin) {
          this.info.companyId = undefined;
        } else {
          this.info.companyId = this.info.company.value;
        }
        this.info.goodiebagId = this.info.goodiebag.value;
        if (!this.info.useExpiresAt) {
          this.info.startsAt = 0;
          this.info.expiresAt = 0;
        } else {
          if (typeof this.info.localStartsAt === 'object' || typeof this.info.localStartsAt === 'string') {
            this.info.localStartsAt = moment(this.info.localStartsAt).valueOf();
          }
          if (typeof this.info.localExpiresAt === 'object' || typeof this.info.localExpiresAt === 'string') {
            this.info.localExpiresAt = moment(this.info.localExpiresAt).valueOf();
          }
          this.info.startsAt = this.info.localStartsAt/1000;
          // Add 23h, so that the counter ends at the end of the day
          this.info.expiresAt = this.info.localExpiresAt/1000 + (23*60*60 + 59*60 + 59);
          if (this.info.expiresAt <= this.info.startsAt) {
            this.setAlert('"Gäller till" måste vara senare än "Gäller från"');
            return;
          }
        }
        if (!this.info.useRecurring) {
          this.info.recurringStartsAt = 0;
          this.info.recurringExpiresAt = 0;
        } else {
          if (parseInt(this.info.recurringExpiresAt) <= parseInt(this.info.recurringStartsAt)) {
            this.setAlert('Recurring "Gäller till" måste vara senare än "Gäller från"');
            return;
          }
        }
        if (this.info.internalLink) {
          this.info.externalLink = '';
          this.info.severalExternalLinks = {};
          this.info.linksGallery = {};
        }
        if (this.info.iframeMediaId) {
          this.info.iframeMedia = [{
            id: this.info.iframeMediaId,
            host: this.info.iframeMediaHost,
          }];
        }

        // this.info.linksGallery make it a string
        if (this.hyperlinks.length) {
          this.info.linksGallery = JSON.stringify({
            label: this.label,
            hyperlinks: this.hyperlinks,
            links: this.links
          });
        }
      } else if (this.entityType == 'Goodiebag') {
        if (!this.admin) {
          this.info.companyId = undefined;
        } else {
          this.info.companyId = this.info.company.value;
        }
        if (this.info.parentGoodiebag) {
          this.info.parentGoodiebagId = this.info.parentGoodiebag.value;
        }
      } else if (this.entityType == 'Company') {
        if (this.info.parentCompany) {
          this.info.parentCompanyId = this.info.parentCompany.value;
        }
      } else if (this.vuexEntityType === 'Game') {
        this.info.entityType = this.finalEntityType;
        this.info.companyId = this.info.company.value;

        if (!this.info.useExpiresAt) {
          this.info.startsAt = 0;
          this.info.expiresAt = 0;
        } else {
          if (typeof this.info.localStartsAt === 'object' || typeof this.info.localStartsAt === 'string') {
            this.info.localStartsAt = moment(this.info.localStartsAt).valueOf();
          }
          if (typeof this.info.localExpiresAt === 'object' || typeof this.info.localExpiresAt === 'string') {
            this.info.localExpiresAt = moment(this.info.localExpiresAt).valueOf();
          }
          this.info.startsAt = this.info.localStartsAt/1000;
          // Add 23h, so that the counter ends at the end of the day
          this.info.expiresAt = this.info.localExpiresAt/1000 + (23*60*60 + 59*60 + 59);
          if (this.info.expiresAt <= this.info.startsAt) {
            this.setAlert('"Gäller till" måste vara senare än "Gäller från"');
            return;
          }
        }
      } else if (this.vuexEntityType === 'PVActivation') {
        if (this.info.campaign) {
          this.info.campaignId = this.info.campaign.value;
        }
        if (!this.info.useReceiptDateRange) {
          this.info.receiptDateRangeFrom = 0;
          this.info.receiptDateRangeTo = 0;
        } else {
          if (typeof this.info.localReceiptDateRangeFrom === 'object' || typeof this.info.localReceiptDateRangeFrom === 'string') {
            this.info.localReceiptDateRangeFrom = moment(this.info.localReceiptDateRangeFrom).valueOf();
          }
          if (typeof this.info.localReceiptDateRangeTo === 'object' || typeof this.info.localReceiptDateRangeTo === 'string') {
            this.info.localReceiptDateRangeTo = moment(this.info.localReceiptDateRangeTo).valueOf();
          }
          this.info.receiptDateRangeFrom = this.info.localReceiptDateRangeFrom/1000;
          // Add 23h, so that the counter ends at the end of the day
          this.info.receiptDateRangeTo = this.info.localReceiptDateRangeTo/1000 + (23*60*60 + 59*60 + 59);
          if (this.info.receiptDateRangeTo <= this.info.receiptDateRangeFrom) {
            this.doAlert('"Gäller till" måste vara senare än "Gäller från"');
            return;
          }
        }
      }

      // Scheduling
      if (!this.info.useScheduledPublish) {
        this.info.scheduledPublishAt = 0;
        this.info.scheduledUnPublishAt = 0;
      } else {
        this.info.scheduledPublishAt = this.getCorrectUnix(this.info.localScheduledPublishAt);
        this.info.scheduledUnPublishAt = this.getCorrectUnix(this.info.localScheduledUnPublishAt) + (23*60*60 + 59*60 + 59);
        if (this.info.scheduledUnPublishAt <= this.info.scheduledPublishAt) {
          this.doAlert('"scheduledUnPublishAt" måste vara senare än "scheduledPublishAt"');
          return;
        }
      }

      // Soft Scheduling
      if (!this.info.useScheduledSoftPublish) {
        this.info.scheduledSoftPublishAt = 0;
        this.info.scheduledSoftUnPublishAt = 0;
      } else {
        this.info.scheduledSoftPublishAt = this.getCorrectUnix(this.info.localScheduledSoftPublishAt);
        this.info.scheduledSoftUnPublishAt = this.getCorrectUnix(this.info.localScheduledSoftUnPublishAt) + (23*60*60 + 59*60 + 59);
        if (this.info.scheduledSoftUnPublishAt <= this.info.scheduledSoftPublishAt) {
          this.doAlert('"scheduledSoftUnPublishAt" måste vara senare än "scheduledSoftPublishAt"');
          return;
        }
      }
    },
    showCloneModal() {
      this.$emit('eventshowCloneModal', true);
    },
    addAdminEntityPicture() {
      this.$store.dispatch(`${this.namespace}add${this.userType}${this.vuexEntityType}Media`, { entityId: this.entity._id, mediaType: "media", data: { filenames: this.info.filenames }, entityType: this.finalEntityType });
      this.$store.dispatch(`${this.namespace}add${this.userType}${this.vuexEntityType}Media`, { entityId: this.entity._id, mediaType: "thumbnail", data: { filenames: this.info.thumbnailFilenames }, entityType: this.finalEntityType });
    },
    publiciseAdminEntity() {
      this.$store.dispatch(`${this.namespace}publicise${this.userType}${this.vuexEntityType}`, { entityId: this.entity._id, publicise: !this.entity.public, entityType: this.finalEntityType })
      .then(() => {
        this.setAlert(`Successfully ${ this.entity.public ? 'publicised' : 'hide' } the ${this.entityType}!`);
      });
    },
    softPubliciseAdminEntity() {
      this.$store.dispatch(`${this.namespace}softPublicise${this.userType}${this.vuexEntityType}`, { entityId: this.entity._id, softPublicise: !this.entity.public_soft, entityType: this.finalEntityType })
      .then(() => {
        this.setAlert(`Successfully ${ this.entity.public_soft ? 'soft publicised' : 'undid soft published' } the ${this.entityType}!`);
      });
    },
    publiciseLandingEntity() {
      this.$store.dispatch(`${this.namespace}publiciseLanding${this.userType}${this.vuexEntityType}`, { entityId: this.entity._id, publicise_landing: !this.entity.public_landing, entityType: this.finalEntityType })
      .then(() => {
        this.setAlert(`Successfully ${ this.entity.public_landing ? 'publicised' : 'hid' } the landing ${this.entityType}!`);
      });
    },
    bookmarkAdminEntity() {
      this.$store.dispatch(`${this.namespace}bookmark${this.userType}${this.vuexEntityType}`, { entityId: this.entity._id, entityType: this.finalEntityType })
      .then(() => {
        this.setAlert(`Successfully bookmarked the entity!`);
      });
    },
    closeEntity() {
      this.$store.dispatch(`${this.namespace}close${this.userType}${this.vuexEntityType}`, {
        entityId: this.entity._id,
        goodiebagId: this.goodiebagId,
        close: !this.entity.closed,
        entityType: this.finalEntityType,
      }).then(() => {
        this.setAlert('Successfully closed/opened the entity!');
      });
    },
    archiveEntity() {
      this.$store.dispatch(`${this.namespace}archive${this.userType}${this.vuexEntityType}`, {
        entityId: this.entity._id,
        goodiebagId: this.goodiebagId,
        archive: !this.entity.archived,
        entityType: this.finalEntityType,
      }).then(() => {
        this.setAlert(`Successfully ${ this.entity.archived ? 'archived' : 'retrieved' } the ${this.entityType}!`);
      });
    },
    getWinners() {
      if (this.admin) {
        offerApi.getAdminWinners(this.entity._id).then((response) => {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
          anchor.target = '_blank';
          anchor.download = `${this.entity.first_header}_participants_${(new Date).toISOString()}.csv`;
          anchor.click();
        });
      } else {
        offerApi.getCompanyWinners(this.entity._id).then((response) => {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
          anchor.target = '_blank';
          anchor.download = `${this.entity.first_header}_participants_${(new Date).toISOString()}.csv`;
          anchor.click();
        });
      }
    },
    deleteWinners() {
      if (this.admin) {
        offerApi.deleteAdminWinners(this.entity._id).then(() => {
          this.setAlert('Successfully deleted winners!');
        });
      } else {
        offerApi.deleteCompanyWinners(this.entity._id).then(() => {
          this.setAlert('Successfully deleted winners!');
        });
      }
    },
    createAdminCompanyLink() {
      axios.put(`/api/admin/companies/${this.entity._id}/link`, {
        email: this.linkEmail,
        mobile: this.linkMobile,
        role: this.linkRole,
      }).then((response) => {
        if (response.data && response.data.token) {
          this.token = response.data.token;
        }
      });
    },
    deleteEntityPictures() {
      this.$store.dispatch(`${this.namespace}delete${this.userType}${this.vuexEntityType}Pictures`, { entityId: this.entity._id, entityType: this.finalEntityType }).then(() => {
        this.setAlert('Successfully deleted entity pictures!');
      });
    },
    deleteEntity() {
      this.$store.dispatch(`${this.namespace}delete${this.userType}${this.vuexEntityType}`, {
        entityId: this.entity._id,
        entityType: this.finalEntityType,
        deleteCode: this.deleteCode,
      }).then(() => {
        this.setAlert('Successfully deleted entity!');
        this.reset();
        this.$router.go(-1);
      });
    },
    deleteAdminGameParticipants() {
      gameApi.deleteAdminGameParticipants({ entityId: this.entity._id, entityType: this.finalEntityType }).then((response) => {
        const { message } = response.data;
        this.setAlert(message);
      });
    },
    sendAdminGameReminder() {
      gameApi.sendAdminGameReminder({ entityId: this.entity._id, entityType: this.finalEntityType }).then((response) => {
        const { message } = response.data;
        this.setAlert(message);
      });
    },
    sendAdminGameThanks() {
      gameApi.sendAdminGameThanks({ entityId: this.entity._id, entityType: this.finalEntityType }).then((response) => {
        const { message } = response.data;
        this.setAlert(message);
      });
    },
    getAdminGameParticipants() {
      gameApi.getAdminGameParticipants({ entityId: this.entity._id, entityType: this.finalEntityType }).then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `${this.entity.name}_participants_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    findAdminParentCompanies(query) {
      this.$store.dispatch('parentCompanies/findAdminParentCompanies', {query});
    },
    findAdminCompanies(query) {
      this.$store.dispatch('findAdminCompanies', { query });
    },
    findAdminGoodiebags(query) {
      this.$store.dispatch('findAdminGoodiebags', { query, type: 'modal' });
    },
    findAdminModalPVCampaigns(query) {
      this.$store.dispatch('pvCampaigns/findAdminModalPVCampaigns', { query, type: 'modal' });
    },
    findAdminModalPvChains(query) {
      this.$store.dispatch('pvChains/findAdminModalPVChains', { query, type: 'modal' });
    },
    findAdminModalPVRetailer(query) {
      this.$store.dispatch('pvRetailers/findAdminModalPVRetailer', { query, type: 'modal' });
    },
    reset() {
      this.info = {};
    },
    handleChange(payload) {
      this.value = payload;
    },
    changeHyperlinkLabel(value) {
      this.label = value;
    },
    addPickedHyperlink(hyperlink) {
      this.hyperlinks.push(hyperlink);
      this.links.push(hyperlink.url);
    },
    changeLink(index, value) {
      Vue.set(this.links, index, value);
    },
    removeLink(index) {
      this.hyperlinks.splice(index, 1);
      this.links.splice(index, 1);
    },
    getCorrectUnix(badUnix) {
      let fixedUnix = 0;
      if (isNaN(badUnix)) {
        fixedUnix = 0;
      } else {
        if (typeof badUnix === 'object') {
          fixedUnix = moment(badUnix).valueOf();
        } else {
          fixedUnix = badUnix;
        }
        fixedUnix = fixedUnix/1000;
      }
      return fixedUnix;
    },
    handleHtmlEditorTextChanged(delta, two, three, modelId) {
      // FIX: https://app.asana.com/0/1200869563661437/1204224646605628
      // Clearing the "Terms" html editor does not actually clear out everything, thus leaving traces
      // in the DB, most notably when having formatted text (formatting tags are left behind).
      // This leads to an info-icon being visible in customer-facing pages with no actual text :O

      // This method clears the formatting (which left the traces) properly on deletion of all text
      // in the html editor. Method is an eventhandler for 'text-change' event on html editor

      // Find our QuillJS component in VueEditor
      let editorCore = _first(this.$refs[`htmlEditor-${modelId}`]).quill;

      if(!editorCore) return;

      let hasDelete = false;
      let hasRetain = false;
      let hasText = true;

      // Quill saves all operations internally, so for us to know that the user actually deleted everything
      // we must check the delta of quills operations for deletion-only match
      let checkKeyPresenceInArray = key => delta.ops.some(obj => Object.keys(obj).includes(key));

      hasDelete = checkKeyPresenceInArray('delete')
      hasRetain = checkKeyPresenceInArray('retain')
      hasText = editorCore.getLength() > 2; // QuillJS always adds \n , even if you remove all text

      // Nothing for us to do since it still has text, we only care about deletions
      if (hasText) {
        return;
      }

      if (hasDelete && !hasRetain) {
        console.log("Deleted it all!");

        this.$nextTick( () => {
          // User removed/cleared ALL text, remove formatting and make sure
          // we're not getting anything back and saving to DB
          editorCore.removeFormat(0, editorCore.getLength(), 'api');
          editorCore.setText(''); //reset the text field (actually will add '\n')
        });
      }
    },
    openPopupInfo(popupHeader, popupInfo) {
      this.popupHeader = popupHeader;
      this.popupInfo = popupInfo;
      this.popupInfoModal = true;
    },
    toUrlCase(str) {
      return str && str.match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
          .map(s => s.toLowerCase())
          .join('-');
    },
    addPickedAction(action) {
      this.actions.push(action);
      this.setVariable("ctaActions", this.actions);
    },
    removeAction(index) {
      this.actions.splice(index, 1);
      this.setVariable("ctaActions", this.actions);
    },
    setOfferId(index, value) {
      this.actions[index].offer_id = value;
      this.setVariable("ctaActions", this.actions);
      this.canSave = true;
    },
    setCollectionId(index, value) {
      this.actions[index].collection_id = value;
      this.setVariable("ctaActions", this.actions);
      this.canSave = true;
    },
    setDuration(index, value) {
      this.actions[index].delayed_duration = value;
      this.setVariable("ctaActions", this.actions);
      this.canSave = true;
    },
    setTrigger(index, value) {
      this.actions[index].trigger = value;
      this.setVariable("ctaActions", this.actions);
      this.canSave = true;
    },
    setPoints(index, value) {
      this.actions[index].points = value;
      this.setVariable("ctaActions", this.actions);
      this.canSave = true;
    },
    setPayload(index, value) {
      this.actions[index].payload = value;
      this.setVariable("ctaActions", this.actions);
      this.canSave = true;
    },
    setEmailSubject(index, value) {
      this.actions[index].email_subject = value;
      this.setVariable("ctaActions", this.actions);
      this.canSave = true;
    },
    setEmailTemplate(index, value) {
      this.actions[index].email_template = value;
      this.setVariable("ctaActions", this.actions);
      this.canSave = true;
    },
    setEmailAttachment(index, fileObj) {
      this.actions[index].file_attachment = fileObj;
      this.setVariable("ctaActions", this.actions);
      this.canSave = true;
    },
    setEmailTo(index, value) {
      console.log('value 123', value);
      this.actions[index].email_to = value;
      this.setVariable("ctaActions", this.actions);
      this.canSave = true;
    },
    openPDFEditor(index, pdfSource) {
      this.pdfEditorIndex = index;
      this.pdfEditorSource = pdfSource;
      this.pdfEditorModal = true;
    },
    updatePDFFile(form) {
      this.actions[this.pdfEditorIndex].file_attachment.fieldPositions = form;
      this.setVariable("ctaActions", this.actions);
    },
  },
};

</script>

<style lang="scss">
.collapsable {
  margin-bottom: 32px;
}
</style>

<style lang="scss" scoped>
@import './EntityEditor.scss';
</style>
