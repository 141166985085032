import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user.vuex';
import goodiebags from './modules/goodiebags.vuex';
import companies from './modules/companies.vuex';
import parentCompanies from './modules/parentCompanies.vuex';
import users from './modules/users.vuex';
import hyperlinks from './modules/hyperlink.vuex';
import contents from './modules/contents.vuex';
import search from './modules/search.vuex';
import games from './modules/games.vuex';
import game from './modules/game.vuex';
import links from './modules/links.vuex';
// import device from './modules/device.vuex';

// PV
import pvCompanies from './modules/pvCompanies.vuex';
import pvCampaigns from './modules/pvCampaigns.vuex';
import pvActivations from './modules/pvActivations.vuex';
import pvReceipts from './modules/pvReceipts.vuex';
import pvChains from './modules/pvChains.vuex';
import pvRetailers from './modules/pvRetailers.vuex';
import pvStores from './modules/pvStores.vuex';
import pvPartnerAPIs from './modules/pvPartnerAPIs.vuex';
import pvFlagged from './modules/pvFlagged.vuex';
import pvBlocked from './modules/pvBlocked.vuex';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    user,
    goodiebags,
    companies,
    parentCompanies,
    users,
    hyperlinks,
    contents,
    search,
    games,
    game,

    // Links
    links,

    // PV
    pvCompanies,
    pvCampaigns,
    pvActivations,
    pvReceipts,
    pvChains,
    pvRetailers,
    pvStores,
    pvPartnerAPIs,
    pvFlagged,
    pvBlocked,
  },
  strict: debug,
});
