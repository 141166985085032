<template>
  <div v-if="notFound">
    <div class="user-content--container">
      <div class="activation-notfound--box">
        <iconComponent
          symbol="closed"
          class="activation-notfound--closed"
          stroke="#FFFFFF"/>
        <h2 class="activation-notfound--header">{{ transl.UserPV.activationnomore }}</h2>
        <p class="activation-notfound--expired">{{ transl.UserPV.sorryonnomore }}</p>
        <p class="btn btn-activate" @click="goBack()">{{ transl.UserPV.backto }}</p>
      </div>
    </div>
  </div>
  <div
    v-else-if="loadedPage"
    class="pv-wrapper"
    :style="cssProps">

    <div v-show="loading" class="pv-loading-overlay">
      <img
           class="header-logo"
           src="../assets/images/icons8-spinning-circle.gif" />
    </div>

    <div class="pv-content">
      <div class="pv-breadcrumb">
        <div class="pv-breadcrumb__container">
          <span v-if="activation.receipt_step" :class="{'visited' : stepNumber >= 0 }"></span>
          <span v-if="activation.registration_step" :class="{'visited' : stepNumber >= 1 }"></span>
          <span v-if="activation.survey_step" :class="{'visited' : stepNumber >= 2 }"></span>
          <span v-if="activation.address_step" :class="{'visited' : stepNumber >= 3 }"></span>
          <span v-if="activation.review_step" :class="{'visited' : stepNumber >= 4 }"></span>
          <span v-if="activation.cashback_step" :class="{'visited' : stepNumber >= 5 }"></span>
          <span :class="{'visited' : stepNumber >= 6 }"></span>
        </div>

        <div class="right-icons">
          <iconComponent
            v-if="activation.terms || activation.terms_text"
            symbol="info"
            @click="tocModal = true"/>
          <div
            v-if="receipts && (receipts.length + newReceiptOffset) > 1"
            @click="openAttemptsModal()"
            class="receipts-counter"
            :style="`${activation.terms || activation.terms_text ? 'margin-top: 10px' : ''}`">
            {{ bubbleReceiptLength }}
          </div>
        </div>

        <iconComponent
          v-if="(step === 'receipt-edit' && !edit) || step === 'receipt-input'"
          symbol="question"
          :fill="receiptIssueBubble ? 'white' : 'black'"
          :stroke="receiptIssueBubble ? 'white' : 'black'"
          @click="openFaqModal()"
          :style="`${receiptIssueBubble ? `background: ${activation.accent_color}` : ''}`"/>

        <div
          v-if="receiptIssueBubble && !this.receiptData.receiptItems?.length &&
          ((step === 'receipt-edit' && !edit)
            || step === 'receipt-input')"
          class="pv-edit-step__something-wrong bubble speech">
          <div class="pv-edit-step__something-wrong-header">
            <h4>{{ transl.UserPV.pleasetryscanagain }}</h4>
            <iconComponent symbol="cross" @click="receiptIssueBubble = false;"/>
          </div>
          <div
               v-if="step === 'receipt-edit'"
               class="pv-edit-step__something-wrong-body">
            <iconComponent symbol="errorCircle" fill="#DB0061" stroke="#DB0061" @click="receiptIssueBubble = false;"/>
            <div>{{ transl.UserPV.issuewithreceipt }}</div>
          </div>
          <div class="pv-edit-step__something-wrong-text-image">
            <div>
              <div>{{ transl.UserPV.includethis }}</div>
              <ul>
                <li>
                  {{ transl.UserPV.storename }}
                </li>
                <li>
                  {{ transl.UserPV.products }}
                </li>
                <li>
                  {{ transl.UserPV.date }}
                </li>
              </ul>
            </div>
            <img :src="`${windowOrigin}/api/nar/file/Enginio_Reciept_Small.jpg`" alt="">
          </div>
          <div class="pv-edit-step__something-wrong-note">{{ transl.UserPV.pleasenote }}</div>
          <div class="pv-edit-step__something-wrong-onlyaccept">{{ transl.UserPV.weonlyaccept }}</div>
          <div @click="openFaqModal()" class="enginio-link">{{ transl.UserPV.learnmore }}</div>
        </div>
      </div>

      <div v-if="step !== 'done' && (offer.thumbnail && offer.thumbnail.url) || (offer.media && offer.media[0] && offer.media[0].url)" class="pv-desktop-activation">
        <img :src="offer.thumbnail && offer.thumbnail.url ? offer.thumbnail.url : offer.media[0].url" alt="">
        <div class="pv-desktop-activation__header">{{ offer.first_header }}</div>
        <div class="pv-desktop-activation__sub-header">{{ offer.quick_text }}</div>
        <div class="btn btn-activate"
          @click="$router.push({ name: 'HomeOffer', params: { offerId: getId(offer) } });">
          {{ transl.UserPV.back }}
        </div>
      </div>

      <div
           v-if="step === 'receipt-edit'"
           class="pv-edit-step__receipt">
        <div v-if="receipt.fe_media && receipt.fe_media.file">
          <vuePdf
            v-if="receipt.fe_media.fileType.includes('pdf')"
            class="pdf-component"
            :src="`${windowOrigin}/api/pv/nar/file/${receipt.media.file}`"></vuePdf>
          <img v-else :src="receipt.fe_media.file" alt="">
        </div>
        <div v-if="authenticated" class="btn btn-whitebg-border">
          <label :style="`color: ${activation.accent_color}`"
            for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
          <input
            id="myReceiptFileInput"
            type="file"
            style="display:none"
            accept="image/*;capture=camera"
            @change="analyzeReceipt($event.target.files[0])">
        </div>
      </div>

      <div
        v-if="step === 'receipt-input'"
        class="pv-receipt-step">
        <!--<img
          v-if="activation.fe_icon"
          class="header-logo"
          :src="`${activation.fe_icon}`"/>-->

        <iconComponent class="show-desktop" symbol="uploadReceipt" :width="35" />

        <h1>
          {{ transl.UserPV.registerreceipt }}
        </h1>

        <div v-if="authenticated" class="pv-receipt-step__scan"
          :style="`border: 2px solid ${activation.accent_color}`">
          <label :style="`color: ${activation.accent_color};`"
            for="myReceiptFileInput">
            <div class="upload-label">
              <div>
                  <svg :style="`fill: ${activation.accent_color}`" fill="#000000" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50" width="50px" height="50px">
                    <path
                      d="M 19.398438 6 C 17.019531 6 14.96875 7.679688 14.5 10.011719 L 14.09375 12 L 9 12 C 6.238281 12 4 14.238281 4 17 L 4 38 C 4 40.761719 6.238281 43 9 43 L 41 43 C 43.761719 43 46 40.761719 46 38 L 46 17 C 46 14.238281 43.761719 12 41 12 L 35.90625 12 L 35.5 10.011719 C 35.03125 7.675781 32.980469 6 30.601563 6 Z M 19.398438 8 L 30.601563 8 C 32.023438 8 33.261719 9.011719 33.542969 10.40625 L 33.941406 12.394531 C 34.132813 13.328125 34.953125 14 35.90625 14 L 41 14 C 42.652344 14 44 15.347656 44 17 L 44 38 C 44 39.652344 42.652344 41 41 41 L 9 41 C 7.347656 41 6 39.652344 6 38 L 6 17 C 6 15.347656 7.347656 14 9 14 L 14.09375 14 C 15.046875 14 15.867188 13.328125 16.058594 12.394531 L 16.457031 10.40625 C 16.738281 9.011719 17.976563 8 19.398438 8 Z M 25 17 C 19.476563 17 15 21.476563 15 27 C 15 32.523438 19.476563 37 25 37 C 30.523438 37 35 32.523438 35 27 C 35 21.476563 30.523438 17 25 17 Z M 25 19 C 29.410156 19 33 22.589844 33 27 C 33 31.410156 29.410156 35 25 35 C 20.589844 35 17 31.410156 17 27 C 17 22.589844 20.589844 19 25 19 Z" />
                  </svg>
              </div>
              <div class="upload--bottom">
                {{ transl.UserPV.scanreceipt }}
              </div>
            </div>
          </label>
          <input
            id="myReceiptFileInput"
            type="file"
            style="visibility:hidden;"
            accept="image/*;capture=camera"
            @change="analyzeReceipt($event.target.files[0])">
        </div>

        <div v-if="authenticated" class="pv-receipt-step__or">
          <h4>{{ transl.UserPV.or }}</h4>
        </div>

        <div v-if="authenticated" class="pv-receipt-step__upload show-mobile-and-tablet"
          :style="`border: 2px solid ${activation.accent_color} !important`">
          <label :style="`color: ${activation.accent_color}`"
            for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
          <input
            id="myReceiptFileInput"
            type="file"
            style="visibility:hidden;"
            accept="image/*;capture=camera"
            @change="analyzeReceipt($event.target.files[0])">

          <div class="desktop" :style="`background: ${activation.accent_color}`">
            <label
              for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
            <input
              id="myReceiptFileInput"
              type="file"
              style="visibility:hidden;"
              accept="image/*;capture=camera"
              @change="analyzeReceipt($event.target.files[0])">
          </div>
        </div>
        <div v-if="authenticated" class="pv-receipt-step__upload show-desktop">
          <label :style="`color: ${activation.accent_color}`"
            for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
          <input
            id="myReceiptFileInput"
            type="file"
            style="visibility:hidden;"
            accept="image/*;capture=camera"
            @change="analyzeReceipt($event.target.files[0])">

          <div class="desktop" :style="`background: ${activation.accent_color}`">
            <label
              for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
            <input
              id="myReceiptFileInput"
              type="file"
              style="visibility:hidden;"
              accept="image/*;capture=camera"
              @change="analyzeReceipt($event.target.files[0])">
          </div>
        </div>

        <div v-if="!authenticated" class="pv-receipt-step__not-auth">
          <div class="pv-receipt-step__scan"
            :style="`border: 2px solid ${activation.accent_color}`">
            <label :style="`color: ${activation.accent_color};`"
              for="myReceiptFileInput">
              <div class="upload-label">
                <div>
                    <svg :style="`fill: ${activation.accent_color}`" fill="#000000" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50 50" width="50px" height="50px">
                      <path
                        d="M 19.398438 6 C 17.019531 6 14.96875 7.679688 14.5 10.011719 L 14.09375 12 L 9 12 C 6.238281 12 4 14.238281 4 17 L 4 38 C 4 40.761719 6.238281 43 9 43 L 41 43 C 43.761719 43 46 40.761719 46 38 L 46 17 C 46 14.238281 43.761719 12 41 12 L 35.90625 12 L 35.5 10.011719 C 35.03125 7.675781 32.980469 6 30.601563 6 Z M 19.398438 8 L 30.601563 8 C 32.023438 8 33.261719 9.011719 33.542969 10.40625 L 33.941406 12.394531 C 34.132813 13.328125 34.953125 14 35.90625 14 L 41 14 C 42.652344 14 44 15.347656 44 17 L 44 38 C 44 39.652344 42.652344 41 41 41 L 9 41 C 7.347656 41 6 39.652344 6 38 L 6 17 C 6 15.347656 7.347656 14 9 14 L 14.09375 14 C 15.046875 14 15.867188 13.328125 16.058594 12.394531 L 16.457031 10.40625 C 16.738281 9.011719 17.976563 8 19.398438 8 Z M 25 17 C 19.476563 17 15 21.476563 15 27 C 15 32.523438 19.476563 37 25 37 C 30.523438 37 35 32.523438 35 27 C 35 21.476563 30.523438 17 25 17 Z M 25 19 C 29.410156 19 33 22.589844 33 27 C 33 31.410156 29.410156 35 25 35 C 20.589844 35 17 31.410156 17 27 C 17 22.589844 20.589844 19 25 19 Z" />
                    </svg>
                </div>
                <div class="upload--bottom">
                  {{ transl.UserPV.scanreceipt }}
                </div>
              </div>
            </label>
          </div>

          <div class="pv-receipt-step__or">
            <h4>{{ transl.UserPV.or }}</h4>
          </div>

          <div class="pv-receipt-step__upload show-mobile-and-tablet"
            :style="`border: 2px solid ${activation.accent_color}`">
            <label :style="`color: ${activation.accent_color}`" @click="showLogin()"
              for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>

            <!--<div class="desktop" :style="`background: ${activation.accent_color}`">
              <label :style="`color: ${activation.text_color} !important`" @click="showLogin()" for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
            </div>-->
          </div>
          <div class="pv-receipt-step__upload show-desktop">
            <label @click="showLogin()"
              for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>

            <div class="desktop" :style="`background: ${activation.accent_color}`">
              <label @click="showLogin()"
                for="myReceiptFileInput">{{ transl.UserPV.upload }}</label>
            </div>
          </div>
        </div>

        <div class="pv-tip">
          {{ transl.UserPV.tip }}
        </div>
      </div>

      <div
        class="pv-edit-step"
        v-else-if="step === 'receipt-edit' && !edit">
        <h2>
          {{ transl.UserPV.scanned }}
        </h2>

        <div class="pv-edit-step__scan-again">
          <label for="myReceiptFileInput" style="display: grid; grid-template-columns: 50px 1fr;">
            <div :style="`background: ${activation.accent_color}`">
              <svg
                :style="`fill: white`" fill="#000000" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50" width="50px" height="50px">
                <path
                  d="M 19.398438 6 C 17.019531 6 14.96875 7.679688 14.5 10.011719 L 14.09375 12 L 9 12 C 6.238281 12 4 14.238281 4 17 L 4 38 C 4 40.761719 6.238281 43 9 43 L 41 43 C 43.761719 43 46 40.761719 46 38 L 46 17 C 46 14.238281 43.761719 12 41 12 L 35.90625 12 L 35.5 10.011719 C 35.03125 7.675781 32.980469 6 30.601563 6 Z M 19.398438 8 L 30.601563 8 C 32.023438 8 33.261719 9.011719 33.542969 10.40625 L 33.941406 12.394531 C 34.132813 13.328125 34.953125 14 35.90625 14 L 41 14 C 42.652344 14 44 15.347656 44 17 L 44 38 C 44 39.652344 42.652344 41 41 41 L 9 41 C 7.347656 41 6 39.652344 6 38 L 6 17 C 6 15.347656 7.347656 14 9 14 L 14.09375 14 C 15.046875 14 15.867188 13.328125 16.058594 12.394531 L 16.457031 10.40625 C 16.738281 9.011719 17.976563 8 19.398438 8 Z M 25 17 C 19.476563 17 15 21.476563 15 27 C 15 32.523438 19.476563 37 25 37 C 30.523438 37 35 32.523438 35 27 C 35 21.476563 30.523438 17 25 17 Z M 25 19 C 29.410156 19 33 22.589844 33 27 C 33 31.410156 29.410156 35 25 35 C 20.589844 35 17 31.410156 17 27 C 17 22.589844 20.589844 19 25 19 Z" />
              </svg>
            </div>
            <span style="align-self: center;">{{ transl.UserPV.scanagain }}</span>
          </label>
          <input
            id="myReceiptFileInput"
            type="file"
            accept="image/*;capture=camera"
            @change="analyzeReceipt($event.target.files[0])">
        </div>
        <div>
        <div class="pv-edit-step__receipt-info">
          <div class="pv-edit-step__receipt-info-header">{{ transl.UserPV.purchasedate }}</div>
          <div class="pv-edit-step__receipt-info-subheader" :class="{ 'incorrect-field': !receiptData.receiptDate }">{{ receiptData.receiptDate || transl.UserPV.notfound }}</div>
          <div class="pv-edit-step__receipt-info-header">{{ transl.UserPV.store }}</div>
          <div class="pv-edit-step__receipt-info-subheader" :class="{ 'incorrect-field': !receiptData.receiptStore }">{{ receiptData.receiptStore || transl.UserPV.notfound }}</div>
          <div class="pv-edit-step__receipt-info-header">{{ mergedPVItems.length > 1 ? transl.UserPV.products : transl.UserPV.product }}</div>

          <div class="product-box several-boxes">
            <!--:class="{ 'several-boxes': mergedPVItems.length > 1 }"-->
            <div class="product-item" v-for="item in mergedPVItems" :key="item._id">
              <div :class="{ 'incorrect-field': !item.item && item?.receipt_term.mandatory }">
                {{ item?.item || transl.UserPV.notfound }}
              </div>
              <div>{{ item?.item_cost }}</div>
            </div>
          </div>
        </div>
      </div>

        <!-- <div class="pv-edit-step__buttons-container">
          <p>{{ transl.UserPV.note }}</p>
          <div>
            <div
              v-if="canEditReceipt"
              class="btn btn-whitebg-border"
              @click="edit = !edit">{{ transl.UserPV.edit }}</div>

          </div>
        </div> -->

        <div class="fixed_bottom_container">
          <div
            class="btn btn-activate pv-form_btn"
            @click="changeReceipt({ setReceipt: true })">
            {{ transl.UserPV.complete }}
          </div>
        </div>
      </div>

      <div
        v-else-if="step === 'receipt-edit' && edit"
        class="pv-edit-step">
        <!--<img
          v-if="activation.fe_icon"
          class="header-logo"
          :src="`${activation.fe_icon}`"/>-->
        <h2>
          {{ transl.UserPV.editreceipt }}
        </h2>

        <!--<div class="pv-edit-step__scan-again">
          <div>
            <img src="../assets/images/camera.svg" alt="">
          </div>
          <label for="myReceiptFileInput">{{ transl.UserPV.scanagain }}</label>
          <input
            id="myReceiptFileInput"
            type="file"
            accept="image/*;capture=camera"
            @change="analyzeReceipt($event.target.files[0])">
        </div>-->

        <div class="pv-receipt-step__input-field">
          <InputField :disabled="!activation.receipt_can_change_date" :label="transl.UserPV.purchasedate" v-model="receiptEditData.receiptDate"/>
          <InputField :disabled="!activation.receipt_can_change_item" :label="transl.UserPV.product" v-model="receiptEditData.receiptItem"/>
          <InputField :disabled="!activation.receipt_can_change_item_cost" :label="transl.UserPV.amount" v-model="receiptEditData.receiptItemCost"/>
          <InputField :disabled="!activation.receipt_can_change_store" :label="transl.UserPV.store" v-model="receiptEditData.receiptStore"/>
        </div>

        <!--<div class="pv-edit-step__buttons-container">
          <p>{{ transl.UserPV.note2 }}</p>
          <div>
            <div
              class="btn btn-whitebg-border"
              @click="edit = !edit; receiptEditData = JSON.parse(JSON.stringify(receiptData))">
              {{ transl.UserPV.undo }}</div>
            <div class="btn btn-activate"
              @click="step = 'receipt-edit';edit = !edit; receiptData = JSON.parse(JSON.stringify(receiptEditData));">
              {{ transl.UserPV.save }}</div>
          </div>
        </div>-->

        <div class="fixed_bottom_container">
          <div
            class="btn btn-whitebg-border"
            @click="edit = !edit; receiptEditData = JSON.parse(JSON.stringify(receiptData))">
            {{ transl.UserPV.undo }}</div>
          <div class="btn btn-activate"
            @click="step = 'receipt-edit';edit = !edit; receiptData = JSON.parse(JSON.stringify(receiptEditData));">
            {{ transl.UserPV.save }}</div>
        </div>
      </div>

      <div
        v-else-if="step === 'registration'"
        class="pv-registration-step"
        :class="{ 'pv-no-auth-step': !authenticated }">

        <div v-if="!authenticated" class="pv-before-we-start">
          <h3>{{ transl.UserPV.beforewestart }}</h3>
          <div class="pv-before-we-start-body">
            {{ transl.UserPV.beforeregister }}
          </div>
          <div class="btn btn-activate" @click="showLogin('signupModalView')">
            {{ transl.App.register }}
          </div>
          <div>{{ transl.Login.or }}</div>
          <div class="btn btn-transparent" @click="showLogin('loginModalView')">
            {{ transl.Login.login }}
          </div>
        </div>
        <div v-else>
          <div class="product_step_title" style="margin-bottom: 20px;">{{ transl.UserPV.registerproduct }}</div>
          <div class="registration_box_container">
            <div
              v-for="item, index in mergedPRItems"
              :key="item._id"
              class="product-box"
              :class="{
                'all-but-last-box': index !== mergedPRItems.length - 1,
                'several-boxes': mergedPRItems.length > 1
              }">
              <div class="product_title">{{ item.registration_term.label }}</div>
                <div class="product_field_label">
                  {{ item.item }}
                </div>

                <div id="imei_container" v-if="item.registration_term.imei">
                  <InputField
                    :label="transl.UserPV.imei"
                    :outlined="true" :filled="false"
                    :color="['white', `${activation.accent_color}` ]"
                    v-model="item.registrationImeinr"/>

                  <div class="product_field_description">
                    <p>{{item.registration_term.imei_description || transl.UserPV.copyimei}}</p>
                  </div>
                </div>
                <div id="serial_container" v-if="item.registration_term.serial">
                  <InputField
                    :label="transl.UserPV.serialnumber"
                    :outlined="true" :filled="false"
                    :color="['white', `${activation.accent_color}` ]"
                    v-model="item.registrationSerialnr"/>

                  <div class="product_field_description" >
                    <p>{{item.registration_term.serial_description || transl.UserPV.copyserialnr}}</p>
                  </div>
                </div>

                <div class="product_field_label" style="text-align: left; margin-top:20px">

                </div>

                <AutoComplete v-if="item.registration_term.modelnr"
                  :title="transl.UserPV.modelnr"
                  :value="item.registrationModelnr"
                  :options="mappedModelNumbers(item.registration_term.modelnrs)"
                  :limit="4"
                  :accentColor="activation.accent_color"
                  :placeholder="transl.UserPV.findbyname"
                  :noResult="transl.UserPV.nomodelnr"
                  @selected="item.registrationModelnr = $event.text"/>
              </div>
            </div>

            <div class="fixed_bottom_container">
            <button class="btn btn-activate pv-form_btn" style="flex: 1;" @click="changeReceipt({ setRegistration: true })">
              {{ transl.UserPV.register }}
            </button>
          </div>
        </div>
      </div>

      <div
        v-else-if="step === 'survey'"
        class="pv-form-step"
        :class="{ 'pv-no-auth-step': !authenticated }">

        <div v-if="!authenticated" class="pv-before-we-start">
          <h3>{{ transl.UserPV.beforewestart }}</h3>
          <div class="pv-before-we-start-body">
            {{ transl.UserPV.beforesurvey }}
          </div>
          <div class="btn btn-activate" @click="showLogin('signupModalView')">
            {{ transl.App.register }}
          </div>
          <div>{{ transl.Login.or }}</div>
          <div class="btn btn-transparent" @click="showLogin('loginModalView')">
            {{ transl.Login.login }}
          </div>
        </div>
        <div v-else>
          <div class="pv-form__header">
            <h2>{{ transl.UserPV.surveyheader }}</h2>
            <p>{{ transl.UserPV.surveysubheader }}</p>
          </div>

          <Form
            class="pv-form show-mobile"
            :questions="activation.survey"
            :answers="surveyAnswers"
            :accentColor="activation.accent_color"
            :textColor="activation.text_color"
            :submitButton="transl.UserPV.submit"
            @answeredAll="surveyAnswers = $event"
            @missingRequired="surveyAnswers = {}"
            @submit="changeReceipt({ setSurvey: true })"/>

          <Form
            class="pv-form show-tablet-and-desktop"
            :questions="activation.survey"
            :answers="surveyAnswers"
            :accentColor="activation.accent_color"
            textColor="black"
            :submitButton="transl.UserPV.submit"
            @answeredAll="surveyAnswers = $event"
            @missingRequired="surveyAnswers = {}"
            @submit="changeReceipt({ setSurvey: true })"/>
        </div>
      </div>

      <div
        v-else-if="step === 'address'"
        class="pv-form-step"
        :class="{ 'pv-no-auth-step': !authenticated }">

        <div v-if="!authenticated" class="pv-before-we-start">
          <h3>{{ transl.UserPV.beforewestart }}</h3>
          <!--<div class="pv-before-we-start-body">
            {{ transl.UserPV.beforesurvey }}
          </div>-->
          <div class="btn btn-activate" @click="showLogin('signupModalView')">
            {{ transl.App.register }}
          </div>
          <div>{{ transl.Login.or }}</div>
          <div class="btn btn-transparent" @click="showLogin('loginModalView')">
            {{ transl.Login.login }}
          </div>
        </div>
        <div v-else>
          <div class="pv-form__header">
            <h2>{{ transl.UserPV.surveyheader }}</h2>
            <p>{{ transl.UserPV.surveysubheader }}</p>
          </div>

          <Form
            class="pv-form show-mobile"
            :questions="addressForm"
            :answers="addressData"
            :accentColor="activation.accent_color"
            :textColor="activation.text_color"
            :submitButton="transl.UserPV.submit"
            @answeredAll="addressData = $event"
            @missingRequired="addressData = {}"
            @submit="changeReceipt({ setAddress: true })"/>

          <Form
            class="pv-form show-tablet-and-desktop"
            :questions="addressForm"
            :answers="addressData"
            :accentColor="activation.accent_color"
            textColor="black"
            :submitButton="transl.UserPV.submit"
            @answeredAll="addressData = $event"
            @missingRequired="addressData = {}"
            @submit="changeReceipt({ setAddress: true })"/>
        </div>
      </div>

      <div
        v-else-if="step === 'review'"
        class="pv-form-step"
        :class="{ 'pv-no-auth-step': !authenticated }">

        <div v-if="!authenticated" class="pv-before-we-start">
          <h3>{{ transl.UserPV.beforewestart }}</h3>
          <!--<div class="pv-before-we-start-body">
            {{ transl.UserPV.beforereview }}
          </div>-->
          <div class="btn btn-activate" @click="showLogin('signupModalView')">
            {{ transl.App.register }}
          </div>
          <div>{{ transl.Login.or }}</div>
          <div class="btn btn-transparent" @click="showLogin('loginModalView')">
            {{ transl.Login.login }}
          </div>
        </div>
        <div v-else>
          <div class="product_step_title" style="margin-bottom: 20px;">{{ transl.UserPV.reviewproduct }}</div>
          <div
            v-if="receipt.receipt_items[0] && receipt.receipt_items[0].item"
            class="registration_box_container">
            <div class="form_label" style="text-align: left;">{{ transl.UserPV.product }}</div>
            <div class="product-box" style="background-color: #F2F2F2;">
              <div class="product-box_inner">
                <p class="title">{{ receipt.receipt_items[0].item }}</p>
                <p>{{ receipt.receipt_items[0].item_cost }}</p>
                <p>{{ receipt.receipt_store }}</p>
              </div>
            </div>

            <form
              v-if="reviewPartner == 'bazaarvoice'"
              @submit.prevent="handleSubmit()">
              <div class="form_label_margin" style="text-align: left;">{{ transl.UserPV.overallrating }}
              </div>

              <inputField
                v-model="reviewData.rating"
                :required="true"
                inputType="rating"></inputField>

              <div class="form_label_margin" style="text-align: left;">{{ transl.UserPV.title }}*
              </div>
              <inputField
                v-model="reviewData.title"
                :required="true"></inputField>

              <div class="form_label_margin" style="text-align: left;">{{ transl.UserPV.nickname }}*
              </div>
              <inputField
                v-model="reviewData.nickname"
                :required="true"></inputField>

              <div class="form_label_margin" style="text-align: left;">{{ transl.UserPV.description }}*
              </div>
              <inputField
                v-model="reviewData.description"
                :required="true"
                inputType="textarea"></inputField>

              <div class="form_footer">
                <p
                  style="text-align: left; margin-right:20px; margin-top:1px"
                  v-html="transl.UserPV.operatingreview.interpolate({ reviewpartner: getTitleCase(reviewPartner), companyname: offer.company.name })"></p>
                <img width="49px" height="49px" src="../assets/images/bazaarvoice_logo.png">
              </div>

              <input type="hidden" name="blackBox" id="ioBlackBox"/>
              <div class="fixed_bottom_container">
                <button class="btn btn-activate" style="flex: 1;" type="submit">{{ transl.UserPV.submit }}</button>
              </div>
            </form>

            <!--NOT YET TESTED-->
            <form
              v-else-if="reviewPartner == 'testfreaks'"
              @submit.prevent="handleSubmit()">
              <div class="form_label_margin" style="text-align: left;">{{ transl.UserPV.overallrating }}
              </div>

              <inputField
                v-model="reviewData.rating"
                :required="true"
                inputType="rating"></inputField>

              <div class="form_label_margin" style="text-align: left;">{{ transl.UserPV.title }}*
              </div>
              <inputField
                v-model="reviewData.title"
                :required="true"></inputField>

              <div class="form_label_margin" style="text-align: left;">{{ transl.UserPV.nickname }}*
              </div>
              <inputField
                v-model="reviewData.nickname"
                :required="true"></inputField>

              <div class="form_label_margin" style="text-align: left;">{{ transl.UserPV.description }}*
              </div>
              <inputField
                v-model="reviewData.description"
                :required="true"
                inputType="textarea"></inputField>

              <div class="form_footer">
                <p
                  style="text-align: left; margin-right:20px; margin-top:1px"
                  v-html="transl.UserPV.operatingreview.interpolate({ reviewpartner: getTitleCase(reviewPartner), companyname: offer.company.name })"></p>
                <img width="49" src="../assets/images/testfreaks_logo.png">
              </div>

              <input type="hidden" name="blackBox" id="ioBlackBox"/>
              <div class="fixed_bottom_container">
                <button class="btn btn-activate" style="flex: 1;" type="submit">{{ transl.UserPV.submit }}</button>
              </div>
            </form>
            <div v-else style="color: red; margin-top: 20px; font-weight: 700">
              The retailer has not provided a review partner. Contact support.
            </div>
          </div>
          <div v-else>
            <p>There are no receipt items, contact support in order to go forward</p>
          </div>
        </div>
      </div>

      <div
        v-else-if="step === 'cashback'"
        class="pv-cashback-step"
        :class="{ 'pv-no-auth-step': !authenticated }">
        <!-- allow="autoplay; fullscreen" allowfullscreen-->
        <div v-if="!authenticated" class="pv-before-we-start">
          <h3>{{ transl.UserPV.beforewestart }}</h3>
          <div class="pv-before-we-start-body">
            {{ transl.UserPV.beforecashback }}
          </div>
          <div class="btn btn-activate" @click="showLogin('signupModalView')">
            {{ transl.App.register }}
          </div>
          <div>{{ transl.Login.or }}</div>
          <div class="btn btn-transparent" @click="showLogin('loginModalView')">
            {{ transl.Login.login }}
          </div>
        </div>
        <iframe
          v-else-if="cashbackToken !== ''"
          id="cashback-iframe"
          :src="`https://paylink.svepos.se/cashback?token=${cashbackToken}`"
          frameborder="0"></iframe>
          <!--<iframe
          id="cashback-iframe"
          frameborder="0"></iframe>-->
      </div>

      <div v-else-if="step.includes('issue')" class="pv-issue">
        <h2>{{ transl.UserPV.ohoh }}</h2>
        <div class="pv-completed__mail">
          <iconComponent symbol="heartOnHand" />
          <!--the prizes are out of stock-->
          <p v-if="step.includes('out of stock')">
            {{ transl.UserPV.noprize }}
          </p>
          <p v-if="step.includes('tomorrow')">
            {{ transl.UserPV.tryagaintomorrow }}
          </p>
        </div>

        <div class="pv-completed__back-btn">
          <p style="padding-bottom: 10px">{{ transl.UserPV.dontforget }}</p>
          <div
            class="btn btn-activate"
            @click="$router.push({ name: 'HomeGoodiebag', params: { goodiebagId: getId(offer.goodiebag) } });">
            {{ transl.UserPV.backto }}
          </div>
        </div>
      </div>

      <div v-else class="pv-completed">
        <h2>{{ activation.cta_done_header || "It's Enginio" }}</h2>

        <div class="pv-completed__mail" v-if="activation.cta_done_custom_message">
          <div v-if="activation.cta_code_visible">{{ transl.UserPV.yourcode }}</div>
          <div v-if="activation.cta_code_visible" class="cta-code">{{ receipt.cta_code }}</div>
          <div v-if="activation.cta_code_visible" class="a-line"></div>

          <iconComponent :symbol="`${activation.cta_done_custom_icon || 'confetti'}`"/>
          <p>{{ activation.cta_done_custom_message }}</p>
        </div>

        <div class="pv-completed__mail" v-else-if="activation.cta_email || activation.cta_code_visible">
          <div v-if="activation.cta_code_visible">{{ transl.UserPV.yourcode }}</div>
          <div v-if="activation.cta_code_visible" class="cta-code">{{ receipt.cta_code }}</div>

          <div v-if="activation.cta_email && activation.cta_code_visible" class="a-line"></div>

          <iconComponent v-if="activation.cta_email" symbol="email" />
          <p v-if="activation.cta_email">{{ transl.UserPV.emailsoon }}</p>
          <!--TODO: add the_prize as a variable!-->
        </div>

        <div class="prize-container">
          <p style="padding-bottom: 10px">
            {{ activation.cta_done_button_description || transl.UserPV.dontforget }}
          </p>
          <div
            class="pv-completed__back-btn"
            :class="{
              'pv-completed__back-btn-2': canAddReceipt,
            }">
            <div
              v-if="activation.cta_done_url"
              class="btn btn-activate"
              @click="openUrl(activation.cta_done_url, true)">
              {{ activation.cta_done_button }}
            </div>
            <div
              v-else
              class="btn btn-activate"
              @click="$router.push({ name: 'HomeGoodiebag', params: { goodiebagId: getId(offer.goodiebag) } });">
              {{ activation.cta_done_button || transl.UserPV.backto }}
            </div>
            <div
              v-if="canAddReceipt"
              class="btn btn-transparent"
              @click="addNewReceipt()">
              {{ activation.cta_done_add_new_button || transl.UserPV.addnew }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      v-if="tocModal"
      @close="tocModal = false"
      size="medium" padding="0" :terms="true">
      <slot>
        <div class="terms-wrapper">
          <div v-if="activation.terms_text" v-html="activation.terms_text" class="html-text"></div>
          <vuePdf v-else :page="pageNr" :src="`${windowOrigin}/api/pv/nar/file/${activation.terms}`" @num-pages="pageCount = $event">
          </vuePdf>
        </div>
        <div class="terms-buttons">
          <div
            v-if="authenticated && activation.terms_merchandise && !receipt.accepted_merchandise && !receipt.accepted_terms"
            class="terms-merchandise-grid">
            <inputField
              class="terms-merchandise-checkbox"
              v-model="acceptMerchandise"
              inputType="checkbox"/>
            <span
              class="terms-merchandise-text"
              v-html="activation.terms_merchandise"></span>
          </div>

          <div v-if="authenticated && !receipt.accepted_terms" class="btn btn-activate" @click="acceptTerms()">
            {{ transl.UserPV.accept }}
          </div>

          <div class="btn btn-secondary" @click="tocModal = false">
            {{ transl.UserPV.close }}
          </div>
        </div>
      </slot>
    </modal>

    <modal v-if="notVerifiedEmailModal"
      @close="notVerifiedEmailModal = false;" size="medium">
      <slot>
        <div class="competition-info">
          <iconComponent
            symbol="emailWithPlus"
            :width="150"
            :stroke-width="0"
            fill="#EA7801" />

          <div class="internal-link-title-3">
            {{ transl.Offer.youmustverify }}
          </div>
          <div class="internal-link-title-4 internal-link-center">
            {{ transl.Offer.mustverifyemail }}
          </div>
          <div
            class="btn btn-activate"
            @click="verifyEmail()">
            {{ transl.Offer.gotoprofile }}
          </div>
          <Hero
            class="ok-stamp"
            type="Integrity_badge_Light" />
          <!--<div class="modal-terms">
            {{ transl.Offer.shareyou2 }}
            <span @click="openurl(faqUrl)">FAQ.</span>
          </div>-->
        </div>
      </slot>
    </modal>

    <modal v-if="atteptsModal"
      @close="atteptsModal = false;" size="medium">
      <slot>
        <div class="competition-info">

          <div class="internal-link-title-3">
            {{ transl.UserPV.previousreceipts1 }}
          </div>
          <div class="internal-link-title-4 internal-link-center">
            {{ transl.UserPV.previousreceipts2.interpolate({
              count: (receipts.length + newReceiptOffset)-1
            }) }}
          </div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import vuePdf from 'vue-pdf';
import ExpiredMixin from '../mixins/ExpiredMixin.vue';
import EntityMixin from '../mixins/EntityMixin.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import Hero from '../stories/components/Hero/Hero.vue';
import InputField from '../stories/components/InputField/InputField.vue';
import Form from '../stories/components/Form/Form.vue';
import AutoComplete from '../stories/components/AutoComplete/AutoComplete.vue';

export default {
  name: 'UserPV',
  components: {
    vuePdf,
    Hero,
    InputField,
    Form,
    AutoComplete,
  },
  mixins: [ExpiredMixin, BeforeEnterMixin, EntityMixin],
  props: ['pvId', 'previousOfferId'],
  mounted() {
    this.reloadPage();
  },
  computed: {
    ...mapGetters([
      'user',
      'userStatus',
      'authenticated',
      'receipts',
    ]),
    ...mapGetters('parentCompanies', [
      "parentCompany",
    ]),
    cssProps() {
      return {
        'background-image': `url(${this.activation.fe_media.file})`,
        '--activation-text-color': this.activation.text_color,
        '--activation-accent-color': this.activation.accent_color,
      }
    },
    canEditReceipt () {
      if (this.activation && this.activation._id) {
        return this.activation.receipt_can_change_item
          || this.activation.receipt_can_change_item_cost
          || this.activation.receipt_can_change_store
          || this.activation.receipt_can_change_date;
      } else {
        return false;
      }
    },
    stepNumber() {
      let stepNumber = 0;
      if (this.step === 'receipt-input') {
        stepNumber = 0;
      } else if (this.step === 'registration') {
        stepNumber = 1;
      } else if (this.step === 'survey') {
        stepNumber = 2;
      } else if (this.step === 'address') {
        stepNumber = 3;
      } else if (this.step === 'review') {
        stepNumber = 4;
      } else if (this.step === 'cashback') {
        stepNumber = 5;
      } else if (this.step === 'done') {
        stepNumber = 6;
      }

      return stepNumber;
    },
    modelNumberOptions() {
      return this.activation?.registration_modelnrs.map(modelnr => {
        return { text: modelnr, _id: modelnr };
      })
    },
    bubbleReceiptLength() {
      const totalLength = (this.receipts.length + this.newReceiptOffset)-1;
      let visibleLength = totalLength;
      if (totalLength > 99) {
        visibleLength = '99+';
      }
      return visibleLength;
    }
  },
  watch: {
    authenticated(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.reloadPage();
      }
    },
    surveyAnswers: {
      handler() {
        this.setAnsweredForm('survey');
      },
      deep: true,
    },
    reviewData: {
      handler() {
        this.setAnsweredForm('review');
      },
      deep: true,
    },
  },
  updated() {
    this.resizeCanvas();
  },
  destroyed() {
    this.$store.dispatch('resetActivation');
  },
  data() {
    return {
      loadedPage: false,
      loading: false,
      step: 'receipt-input',
      edit: false,
      disableAddReceipt: false,
      receipt: {},
      newReceiptOffset: 0,
      canAddReceipt: false,
      fileSize: 10 * 1000000, // 10 MB
      receiptFile: undefined,
      cashbackToken: '',
      mergedPVItems: [],
      mergedPRItems: [],
      receiptData: {},
      receiptEditData: {},
      registrationSerialnr: '',
      registrationImeinr: '',
      registrationModelnr: '',
      registrationSerialDescription: '',
      registrationImeiDescription: '',
      surveyAnswers: {},
      surveyOther: {},
      answeredSurvey: false,
      tocModal: false,
      mustAcceptTerms: false,
      acceptMerchandise: true,
      pageCount: 0,
      pageNr: 1,
      notVerifiedEmailModal: false,
      receiptIssueBubble: false,
      atteptsModal: false,
      receiptValidText: '',
      notFound: false,
      addressForm: [],
      addressData: {
        nameAtAddress: '',
        address: '',
        postalCode: '',
        city: '',
        state: '',
        country: '',
      },
      reviewData: {},
      reviewPartner: 'bazaarvoice',
    };
  },
  methods: {
    getTitleCase(str = '') {
      return str.replace(/\w\S*/g, function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
    },
    mappedModelNumbers(modelnrs) {
      return modelnrs.map(modelnr => {
       return { text: modelnr }
      });
    },
    isReceiptDateWithinLiveDateRange(receipt_date){
      if (receipt_date) {
        let unixReceiptDate = new Date(receipt_date).getTime() / 1000; // convert to Unix time format
        if (unixReceiptDate >= this.activation.live_date_range_from && unixReceiptDate <= this.activation.live_date_range_to) {
            //Receipt is valid
          this.receiptValidText = `date is inside`;
          this.receiptValid = true;
        } else {
          //Receipt is not valid
          this.receiptValidText = `date is outside`;
          this.receiptValid = false;
        }
      }
    },
    reloadPage() {
      if (!this.authenticated) {
        this.$store
          .dispatch('getNarOffer', { offerId: this.previousOfferId })
          .then((success) => {
            if (!success) {
              this.notFound = true;
              return;
            }
            this.$store
              .dispatch('parentCompanies/getNarParentCompany', {
                parentCompanyId: this.offer.company.parentCompany._id,
              })
              .then(() => {
                this.$store
                  .dispatch('getNarPV', { pvId: this.pvId })
                  .then((success) => {
                    /*this.$dpAnalytics({ view: 'UserPV', subject: this.activation._id, action: 'enter pv' });
              this.$dpAnalytics({ view: 'UserPV', subject: this.offer._id, action: 'enter pv inside offer' });
              this.$dpAnalytics({ view: 'UserPV', subject: this.offer.goodiebag._id, action: 'enter pv inside collection' });*/
                    this.pushDataLayer({
                      parentCompany: this.offer.company
                        ? this.offer.company.parentCompany
                        : 'Unknown',
                      company: this.offer.company,
                      goodiebag: this.offer.goodiebag,
                      offer: this.offer,
                      action: `Enter PV: "${this.activation.name}"`,
                    });
                    if (!success) {
                      this.notFound = true;
                      // the button will dredirect you to activation page
                      return;
                    }
                    this.setPageTitle(this.offer.first_header, this.offer.company.name);
                    this.setStep();
                    this.acceptMerchandise = this.activation.terms_merchandise_default;
                    this.loadedPage = true;
                    this.setLoadedPageData(true);
                    this.showLogin();
                  });
              });
          });
      } else {
        this.$store
          .dispatch('getNarOffer', { offerId: this.previousOfferId })
          .then((success) => {
            if (!success) {
              this.notFound = true;
              return;
            }
            this.$store
              .dispatch('parentCompanies/getNarParentCompany', {
                parentCompanyId: this.offer.company.parentCompany._id,
              })
              .then(() => {
                this.$store
                  .dispatch('getUserPV', { pvId: this.pvId, phoneNr: this.user.phone_nr })
                  .then(({ canAddReceipt, success }) => {
                    /*this.$dpAnalytics({ view: 'UserPV', subject: this.activation._id, action: 'login pv' });
                    this.$dpAnalytics({ view: 'UserPV', subject: this.offer._id, action: 'login pv inside offer' });
                    this.$dpAnalytics({ view: 'UserPV', subject: this.offer.goodiebag._id, action: 'login pv inside collection' });*/
                    this.canAddReceipt = canAddReceipt;

                    this.pushDataLayer({
                      parentCompany: this.offer.company
                        ? this.offer.company.parentCompany
                        : 'Unknown',
                      company: this.offer.company,
                      goodiebag: this.offer.goodiebag,
                      offer: this.offer,
                      action: `Logged in PV: "${this.activation.name}"`,
                    });
                    if (!success) {
                      this.notFound = true;
                      // the button will dredirect you to activation page
                      return;
                    }

                    if (!this.user.verified_email) {
                      this.notVerifiedEmailModal = true;
                    } else {
                      if (
                        this.receipts &&
                        this.receipts.length
                      ) {
                        this.setStep(this.receipts[this.receipts.length-1]);
                      } else {
                        this.setStep();
                      }
                    }
                    this.setPageTitle(this.offer.first_header, this.offer.company.name);
                    this.acceptMerchandise = this.activation.terms_merchandise_default;
                    this.loadedPage = true;
                    this.setLoadedPageData(true);
                  });
              });
          });
      }
    },
    setCashbackIframe() {
      axios
        .get(`/api/pv/receipts/${this.receipt._id}/cashback`)
        .then((response) => {
          const { token } = response.data;
          this.cashbackToken = token;

          this.pushDataLayer({
            parentCompany: this.offer.company
              ? this.offer.company.parentCompany
              : 'Unknown',
            company: this.offer.company,
            goodiebag: this.offer.goodiebag,
            offer: this.offer,
            action: `Cashback Open: "${this.activation.name}"`,
          });

          /*let xhr = new XMLHttpRequest();

          // const params = '';
          xhr.open('GET', 'https://paylink.svepos.se/cashback');
          xhr.onreadystatechange = handler;
          xhr.responseType = 'blob';
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          // xhr.setRequestHeader('Referrer-Policy', 'no-referrer');
          // xhr.setRequestHeader('Content-Type', 'text/html');
          // document.domain = "enginio.io";
          xhr.send();
          // this.spinner = true;

          function handler() {
            if (this.readyState === this.DONE) {
              if (this.status === 200) {
                // this.response is a Blob, because we set responseType above
                let data_url = URL.createObjectURL(this.response);
                document.querySelector('#cashback-iframe').src = data_url;
                // this.spinner = false;
                console.error('Cashback Ready');
              } else {
                console.error('Cashback didnt work');
              }
            }
          }*/

          if (this.wsReady) {
            this.$socket.client.on(`RELOAD_${this.user._id}_CASHBACK`, ({ success, error }) => {
              console.log('Cashback cburl', success, error);
              if (success) {
                this.pushDataLayer({
                  parentCompany: this.offer.company
                    ? this.offer.company.parentCompany
                    : 'Unknown',
                  company: this.offer.company,
                  goodiebag: this.offer.goodiebag,
                  offer: this.offer,
                  action: `Cashback Done: "${this.activation.name}"`,
                });
                location.reload();
              } else {
                this.setAlert(error, 5);
              }
            });
          }
        })
        .catch((error) => {
          const { message } = error;
          this.setAlert(message, 5);
        });
    },
    showLogin(view) {
      this.$emit('setLoginView', { view });
    },
    verifyEmail() {
      localStorage.setItem(
        'UserEmailRedirect',
        JSON.stringify({
          name: 'UserPV',
          params: { pvId: this.pvId, previousOfferId: this.previousOfferId },
        })
      );
      this.$router.push({ name: 'UserEmail' });
    },
    acceptTerms() {
      if (this.disableAddReceipt) {
        return;
      }
      const method = this.receipt && this.receipt._id ? 'put' : 'post';
      const data = {
        activationId: this.pvId, phoneNr: this.user.phone_nr,
        accept: true, parentCompanyId: this.parentCompany._id,
        acceptMerchandise: this.acceptMerchandise,
        thirdPartyCrmName: this.thirdPartyCrmName,
      };
      if (this.user.name) {
        data.name = this.user.name;
      }
      if (this.user.last_name) {
        data.lastName = this.user.last_name;
      }
      if (this.user.email) {
        data.email = this.user.email;
      }
      axios[method](
        `/api/pv/receipts/${this.receipt._id}/terms`,
        data)
        .then((response) => {
          this.pushDataLayer({
            parentCompany: this.offer.company
              ? this.offer.company.parentCompany
              : 'Unknown',
            company: this.offer.company,
            goodiebag: this.offer.goodiebag,
            offer: this.offer,
            action: `Accepted terms: "${this.activation.name}"`,
          });
          this.mustAcceptTerms = false;
          this.tocModal = false;
          this.setStep(response.data.receipt);
        });
    },
    addNewReceipt() {
      this.newReceiptOffset = 1;
      this.receipt = {};
      this.setStep();
    },
    setStep(receipt) {
      const activation = this.activation;
      let theReceipt = this.receipt;
      try {
        if (receipt && receipt._id) {
          theReceipt = receipt;
        }
  
        if (theReceipt && theReceipt._id) {
          this.receipt = theReceipt;
  
          const acceptedTerms = theReceipt.accepted_terms;
  
          //use this for client side validation. uncomment since only server side is used.
          //this.isReceiptDateWithinLiveDateRange(theReceipt.receipt_date);
  
          if (theReceipt.cta_issue) {
            if (theReceipt.cta_issue.includes('The codes are out of stock')) {
              this.step = 'issue - out of stock';
            }
          } else if (activation.receipt_step &&
            !(theReceipt.media && theReceipt.media.file) && !theReceipt.confirmed_receipt) {
            this.step = 'receipt-input';
          } else if (activation.receipt_step && !theReceipt.confirmed_receipt) {
            this.receiptData.receiptItems = theReceipt.receipt_items;
            this.mergedPVItems = [];
            for (let i = 0; i < this.activation.receipt_terms_multiple.length; i++) {
              const receiptTerm = this.activation.receipt_terms_multiple[i];
              const receiptItems = this.receiptData.receiptItems.filter(item => item.key === receiptTerm.key);

              for (let i = 0; i < receiptItems.length; i++) {
                const receiptItem = receiptItems[i];
                this.mergedPVItems.push({
                  ...receiptItem,
                  receipt_term: receiptTerm
                });
              }
              if (receiptTerm.mandatory && !receiptItems.length) {
                this.mergedPVItems.push({
                  receipt_term: receiptTerm
                });
              }
            }

            this.receiptData.receiptStore = theReceipt.receipt_store;
            this.receiptData.receiptDate = theReceipt.receipt_date;
            this.receiptEditData = JSON.parse(JSON.stringify(this.receiptData));
            this.step = 'receipt-edit';
            if (!this.receiptData.receiptItems?.length || !this.receiptData.receiptStore) {
              this.receiptIssueBubble = true;
            }
          } else if (activation.registration_step && !theReceipt.confirmed_registration) {
            this.registrationSerialnr = '';
            this.registrationImeinr = '';
            this.registrationModelnr = '';

            this.receiptData.receiptItems = theReceipt.receipt_items;

            this.mergedPRItems = [];
            for (let i = 0; i < this.activation.registration_terms_multiple.length; i++) {
              const registrationTerm = this.activation.registration_terms_multiple[i];
              const receiptItems = this.receiptData.receiptItems.filter(item => item.key === registrationTerm.key);

              for (let i = 0; i < receiptItems.length; i++) {
                const receiptItem = receiptItems[i];
                this.mergedPRItems.push({
                  ...receiptItem,
                  registration_term: registrationTerm
                });
              }
            }
            if (!this.mergedPRItems.length) {
              this.mergedPRItems.push({
                item: 'Incorrect settings, contact support if the issue persists',
                registration_term: { label: 'Issue' }
              });
            }

            this.step = 'registration';
          } else if (activation.survey_step && !theReceipt.confirmed_survey) {
            this.step = 'survey';
          } else if (activation.address_step && !theReceipt.confirmed_address) {
            this.addressForm = [
              {
                label: `${this.transl.Offer.firstname} ${this.transl.Offer.lastname}`,
                required: true,
                model: 'nameAtAddress',
                id: 'name',
              },
              {
                label: this.transl.Offer.street,
                required: true,
                model: 'address',
                id: 'address',
              },
              {
                label: this.transl.Offer.postalcode,
                required: true,
                model: 'postalCode',
                id: 'postal-code',
              },
              {
                label: this.transl.Offer.city,
                required: true,
                model: 'city',
                id: 'address-level2',
              },
            ];
            if (activation.address_country) {
              this.addressForm.push({
                label: 'Ange ditt land',
                required: true,
                model: 'country',
                id: 'country',
              });
            }
            if (activation.address_state) {
              this.addressForm.push({
                label: 'Ange ditt landskap',
                required: true,
                model: 'state',
                id: 'state',
              });
            }
  
            let addressData = {
              nameAtAddress: `${this.user.name} ${this.user.last_name}`,
              address: this.user.adress,
              postalCode: this.user.postal_code,
              city: this.user.city,
              state: this.user.state,
              country: this.user.country,
            };
            const receiptLength = this.receipts && this.receipts.length ? this.receipts.length : 0;
            if (receiptLength && receiptLength > 1 && this.receipts[receiptLength-2].address) {
              const lastReceipt = this.receipts[receiptLength-2];
              addressData = {
                nameAtAddress: `${this.user.name} ${this.user.last_name}`,
                address: lastReceipt.address,
                postalCode: lastReceipt.postal_code,
                city: lastReceipt.city,
                state: lastReceipt.state,
                country: lastReceipt.country,
              };
            }
  
            this.addressData = addressData;
            this.step = 'address'
          } else if (activation.review_step && !theReceipt.confirmed_review) {
            this.reviewData = {};
            this.reviewPartner = theReceipt.receipt_retailer?.review_partner || activation.company?.review_partner || activation.review_partner;
            this.step = 'review';
          } else if (activation.cashback_step && !theReceipt.confirmed_cashback) {
            this.step = 'cashback';
            this.setCashbackIframe();
          } else {
            this.step = 'done';
            this.canAddReceipt = (activation.recurring_limit ? this.receipts.length < activation.recurring_limit : true)
              && (activation.recurring || activation.recurring_daily);
          }
  
          if (!acceptedTerms && activation.terms_must_accept) {
            this.mustAcceptTerms = true;
            this.tocModal = true;
          }
  
          if (theReceipt.survey && theReceipt.survey.length) {
            this.surveyAnswers = theReceipt.survey;
          }
  
        } else if (this.authenticated) {
          this.addEmptyReceipt();
        } else {
          if (this.authenticated && activation.terms_must_accept) {
            this.mustAcceptTerms = true;
            this.tocModal = true;
          } else if (activation.receipt_step) {
            this.step = 'receipt-input';
          } else if (activation.registration_step) {
            this.step = 'registration';
          } else if (activation.survey_step) {
            this.step = 'survey';
          } else if (activation.cashback_step) {
            this.step = 'cashback';
            // this.setCashbackIframe();
          }
        }
      } catch (e) {
        console.error('Error in setStep', e);
      }
    },
    analyzeReceipt(file) {
      if (this.disableAddReceipt) {
        return;
      } else if (!this.user.verified_email) {
        this.notVerifiedEmailModal = true;
        return;
      } else if (this.mustAcceptTerms) {
        this.tocModal = true;
        return;
      } else if (file.size >= this.fileSize) {
        const mb = 1 / 1000000;
        this.setAlert(
          `Too large file (${(file.size * mb).toFixed(1)}Mb)` +
            `, maximum is (${this.fileSize * mb}Mb), try lower resolution`,
          5
        );
      } else {
        if (file && /\.(gif|jpg|jpeg|png|bmp|pdf)$/i.test(file.name)) {
          this.receiptFile = file;
        } else {
          this.setAlert('Incorrect file type, accepting: jpg, jpeg, png, bmp and pdf', 6);
          return;
        }
        this.loading = true;
        const data = new FormData();
        data.append('file', this.receiptFile);
        data.append('activationId', this.pvId);
        if (this.user.name) {
          data.append('name', this.user.name);
        }
        if (this.user.last_name) {
          data.append('lastName', this.user.last_name);
        }
        if (this.user.email) {
          data.append('email', this.user.email);
        }
        data.append('phoneNr', this.user.phone_nr);
        /*if (this.user.phone_nr) {
          data.append('phoneNr', this.user.phone_nr);
        }*/

        const config = {
          headers: {
            size: this.receiptFile.size,
          },
        };

        /*const payload = { data, config };
        payload.pvId = this.pvId;*/

        const method = this.receipt && this.receipt._id ? 'put' : 'post';
        axios[method](
          `/api/pv/receipts${
            method === 'put' ? `/${this.receipt._id}` : ''
          }/analyze`,
          data,
          config
        )
          .then((response) => {
            this.pushDataLayer({
              parentCompany: this.offer.company
                ? this.offer.company.parentCompany
                : 'Unknown',
              company: this.offer.company,
              goodiebag: this.offer.goodiebag,
              offer: this.offer,
              action: `Success receipt scan: "${this.activation.name}"`,
            });
            this.setStep(response.data.receipt);
            this.loading = false;
          })
          .catch((error) => {
            this.receiptFile = undefined;
            this.loading = false;

            this.setAlert('', 0);
            let issue = 'Issue with third party, try another file or format';
            let seconds = 6;
            const {
              errorMessage, // this is too advanced for the consumer
              errorType,
            } = this.getError(error);

            if (errorType === 'DUPLICATE') {
              issue = this.transl.UserPV.receiptdouble;
              seconds = 10;
            } else if (errorType == 'NO_RECOGNIZED_RECEIPT') {
              issue = this.transl.UserPV.issuewithreceipt;
              seconds = 10;
            } else if (errorType == 'RATELIMIT') {
              issue = 'You are too quick, please try again in a couple of seconds';
              seconds = 10;
            }
            document.getElementById('myReceiptFileInput').value = '';
            this.pushDataLayer({
              parentCompany: this.offer.company
                ? this.offer.company.parentCompany
                : 'Unknown',
              company: this.offer.company,
              goodiebag: this.offer.goodiebag,
              offer: this.offer,
              action: `Failed receipt scan: "${this.activation.name}"`,
            });
            this.setAlert(issue, seconds);
          });
        /*this.$store.dispatch(`saveUserReceipt${this.step === 'receipt-input' ? 'Metadata' : 'Picture'}`, payload).then(() => {
          this.setStep(this.receipts[0]);
        }).catch(() => {
          this.setAlert(this.transl.Offer.tryagain);
        });*/
      }
    },
    addEmptyReceipt() {
      if (this.disableAddReceipt) {
        return;
      } else if (!this.receipt || !this.receipt._id) {
        this.disableAddReceipt = true;
        const data = { activationId: this.pvId, phoneNr: this.user.phone_nr, accept: true };
        if (this.user.name) {
          data.name = this.user.name;
        }
        if (this.user.last_name) {
          data.lastName = this.user.last_name;
        }
        if (this.user.email) {
          data.email = this.user.email;
        }
        axios.post(`/api/pv/receipts`, data)
        .then((response) => {
          this.disableAddReceipt = false;
          this.pushDataLayer({
            parentCompany: this.offer.company
              ? this.offer.company.parentCompany
              : 'Unknown',
            company: this.offer.company,
            goodiebag: this.offer.goodiebag,
            offer: this.offer,
            action: `Success empty receipt creation: "${this.activation.name}"`,
          });
          this.receiptIssueBubble = true;
          this.canAddReceipt = false;
          this.setStep(response.data.receipt);
        }).catch(() => {
          this.disableAddReceipt = false;
          this.pushDataLayer({
            parentCompany: this.offer.company
              ? this.offer.company.parentCompany
              : 'Unknown',
            company: this.offer.company,
            goodiebag: this.offer.goodiebag,
            offer: this.offer,
            action: `Failed empty receipt creation: "${this.activation.name}"`,
          });
          this.setAlert('Are you sure you have access to this Activation?', 6);
        });
      }
    },
    changeReceipt({
      setReceipt = false,
      setRegistration = false,
      setSurvey = false,
      setAddress = false,
      setReview = false,
    }) {
      let changeObj = {
        activationId: this.pvId,
        relayState: this.user._id,
      };
      let savingType = '';

      if (setReceipt) {
        changeObj.setReceipt = true;
        changeObj.receiptData = this.receiptData;
        savingType = 'saveReceipt';
      }

      if (setRegistration) {
        changeObj.setRegistration = true;

        changeObj.registrationData = this.mergedPRItems.map(item => ({
          _id: item._id,
          modelnr: item.registrationModelnr,
          serialnr: item.registrationSerialnr,
          imeinr: item.registrationImeinr
        }));

        savingType = 'saveRegistration';
      }

      if (setSurvey) {
        if (!this.answeredSurvey) {
          console.log('it seems like you have not answered the survey');
          return;
        }
        changeObj.setSurvey = true;
        changeObj.surveyAnswers = this.surveyAnswers;
        savingType = 'saveSurvey';
      }

      if (setAddress) {
        changeObj.setAddress = true;
        changeObj.addressData = this.addressData;
        savingType = 'saveAddress';

        if (!this.user.adress || !this.user.postal_code || !this.user.city
          || !this.user.country || !this.user.state) {
          this.$store.dispatch('changeUser', {
            name: this.user.name,
            lastName: this.user.last_name,
            adress: this.user.adress || this.addressData.address,
            postalCode: this.user.postal_code || this.addressData.postalCode,
            city: this.user.city || this.addressData.city,
            country: this.user.country || this.addressData.country,
            state: this.user.state || this.addressData.state,
          })
        }
      }

      if (setReview) {
        changeObj.setReview = true;
        changeObj.reviewData = this.reviewData;

        let invalidReviewForm = false;
        let message = '';
        if (!changeObj.reviewData.rating) {
          // TODO: check if this works as intended
          message += `${this.transl.UserPV.overallrating}, `;
          invalidReviewForm = true;
        }
        if (!changeObj.reviewData.title) {
          message += `${this.transl.UserPV.title}, `;
          invalidReviewForm = true;
        }
        if (!changeObj.reviewData.description) {
          message += `${this.transl.UserPV.description}, `;
          invalidReviewForm = true;
        }
        if (!changeObj.reviewData.nickname) {
          message += `${this.transl.UserPV.nickname}, `;
          invalidReviewForm = true;
        }

        if (invalidReviewForm) {
          message.slice(message.length - 2);
          this.setAlert(this.transl.Offer.requiredquestion.interpolate({ message }), 6);
          return;
        }
        this.loading = true;
      }

      axios
        .put(`/api/pv/receipts/${this.receipt._id}`, changeObj)
        .then((response) => {
          this.loading = false;
          this.pushDataLayer({
            parentCompany: this.offer.company
              ? this.offer.company.parentCompany
              : 'Unknown',
            company: this.offer.company,
            goodiebag: this.offer.goodiebag,
            offer: this.offer,
            action: `Save receipt: "${this.activation.name}", ${savingType}`,
          });
          this.setStep(response.data.receipt);
        })
        .catch((error) => {
          this.loading = false;
          this.setAlert('', 0);
          let issue = `${this.transl.UserPV.requirement} `;
          let seconds = 0;
          const {
            errorMessage,
            errorType,
          } = this.getError(error);

          if (errorType === 'SURVEY') {
            // TODO: PV add all different languages!
            issue = errorMessage;
            seconds = 6;
          } else if (errorType === 'DUPLICATE') {
            issue = this.transl.UserPV.receiptdouble;
            seconds = 10;
          } else if (errorType === 'RECEIPT') {
            if (errorMessage.includes('minimum cost')) {
              const amount = errorMessage.split('(')[1].split(')')[0];
              issue = `${issue}${this.transl.UserPV.noamount.interpolate({
                amount,
              })} ${this.transl.UserPV.and} `;
              seconds += 7;
            }
            if (errorMessage.includes('store')) {
              issue = `${issue}${this.transl.UserPV.nostore} ${this.transl.UserPV.and} `;
              seconds += 7;
            }
            if (errorMessage.includes('item')) {
              issue = `${issue}${this.transl.UserPV.nolineitem} ${this.transl.UserPV.and} `;
              seconds += 7;
            }
            if (errorMessage.includes('date') || errorMessage.includes('range')) {
              issue = `${issue}${this.transl.UserPV.thepurchasedate} ${this.transl.UserPV.and} `;
              seconds += 7;
            }

            issue = `${issue.slice(0, -(this.transl.UserPV.and.length + 2))} ${
              this.transl.UserPV.missingscanagain
            }`;

          } else {
            issue = errorMessage;
            seconds = 10;
          }
          this.pushDataLayer({
            parentCompany: this.offer.company
              ? this.offer.company.parentCompany
              : 'Unknown',
            company: this.offer.company,
            goodiebag: this.offer.goodiebag,
            offer: this.offer,
            action: `Save receipt, incorrectly: "${this.activation.name}", ${errorMessage}`,
          });
          this.setAlert(issue, seconds);
        });
    },
    getError(error) {
      const errorMessage =
        error && error.response && error.response.data
          ? error.response.data.message
          : error;

      const errorType =
        error && error.response && error.response.data
          ? error.response.data.error
          : error;
      return { errorMessage, errorType };
    },
    setAnsweredForm(type = 'survey') {
      let answers = this.surveyAnswers;
      if (type == 'review') {
        answers = this.reviewData;
      }
      for (const answerKey in answers) {
        if (
          Array.isArray(answers[answerKey]) &&
          answers[answerKey].length
        ) {
          this.answeredSurvey = true;
          return;
        } else if (
          !Array.isArray(answers[answerKey]) &&
          answers[answerKey] !== ''
        ) {
          this.answeredSurvey = true;
          return;
        }
      }
      this.answeredSurvey = false;
    },
    changePage(newPageNr) {
      if (newPageNr <= this.pageCount && newPageNr > 0) {
        this.pageNr = newPageNr;
      }
    },
    openFaqModal() {
      this.receiptIssueBubble = false;
      let faqSubjects = [];
      let faqQuestions = [];
      if (this.step === 'receipt-edit' || this.step === 'receipt-input') {
        faqSubjects = ['receiptscan'];
        faqQuestions = [];
      }
      this.$emit('openFaqModal', { faqSubjects, faqQuestions });
    },
    openAttemptsModal() {
      this.atteptsModal = true;
    },
    resizeCanvas() {
      if (this.tocModal) {
        const canvas = document.getElementsByTagName('CANVAS');
        if (canvas && canvas.length) {
          canvas[0].style.width = 'auto';
          canvas[0].style.height = '80vh';
          canvas[0].style.display = 'flex';
          canvas[0].style.margin = 'auto';
        }
      }
    },
    handleSubmit() {
      const blackBox = document.getElementById('ioBlackBox').value;
      this.reviewData.blackBox = blackBox;

      this.changeReceipt({ setReview: true });
    },
    goBack() {
      if (this.offer && this.offer._id) {
        if (this.authenticated) {
          this.$router.push({ name: 'UserOffer', params: { offerId: this.offer._id } });
        } else {
          this.$router.push({ name: 'HomeOffer', params: { offerId: this.offer._id } });
        }
      } else {
        if (this.authenticated) {
          this.$router.push({ name: 'UserStart' });
        } else {
          this.$router.push({ name: 'Home' });
        }
      }
    }
  },
};
</script>

<style lang="scss">
.pv-receipt-step,
.pv-edit-step,
.pv-form-step,
.pv-registration-step,
.pv-review-step,
.pv-completed,
.pv-cashback-step,
.pv-issue {
  @media (max-width: $desktop-medium-minwidth) {
    color: var(--activation-text-color);
    .autocomplete {
      color: var(--activation-text-color);
    }
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/UserPV";
</style>
