import axios from 'axios';

export default {
  findAdminOffers: (payload) => {
    return axios.put('/api/admin/offers', payload)
      .then(response => ({
        offers: response.data.offers != null
          ? response.data.offers : {},
      }));
  },

  getAdminOffer: (payload) => {
    return axios.get(`/api/admin/offers/${payload.entityId}`)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  addAdminOffer: (payload) => {
    return axios.post('/api/admin/offers', payload)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  addAdminOfferMedia: (payload) => {
    return axios.put(`/api/admin/offers/${payload.entityId}/media/${payload.mediaType}`, payload.data, payload.config)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  finishAdminOfferLinks: (payload) => {
    return axios.put(`/api/admin/offers/${payload.entityId}/links-done`)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  publiciseAdminOffer: (payload) => {
    return axios.put(`/api/admin/offers/${payload.entityId}/publicise`, payload)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  softPubliciseAdminOffer: (payload) => {
    return axios.put(`/api/admin/offers/${payload.entityId}/soft-publicise`, payload)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  deleteAdminOfferPictures: (payload) => {
    return axios.delete(`/api/admin/offers/${payload.entityId}/picture`)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  addAdminOfferLink: (payload) => {
    return axios.put(`/api/admin/offers/${payload.entityId}/internal_link`, payload.data, payload.config)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  saveAdminOffer: (payload) => {
    return axios.put(`/api/admin/offers/${payload.entityId}`, payload)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  closeAdminOffer: (payload) => {
    return axios.put(`/api/admin/offers/${payload.entityId}/close`, payload)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  archiveAdminOffer: (payload) => {
    return axios.put(`/api/admin/offers/${payload.entityId}/archive`, payload)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  getAdminWinners: (offerId) => {
    return axios.get(`/api/admin/offers/${offerId}/winners`)
      .then(response => response);
  },

  deleteAdminWinners: (offerId) => {
    return axios.delete(`/api/admin/offers/${offerId}/winners`)
      .then(response => response);
  },

  deleteAdminOffer: (offerId) => {
    return axios.delete(`/api/admin/offers/${offerId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },


  // Company

  getCompanyOffer: (payload) => {
    return axios.get(`/api/c/offers/${payload.entityId}`)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  addCompanyOffer: (payload) => {
    return axios.post('/api/c/offers', payload)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  addCompanyOfferMedia: (payload) => {
    return axios.put(`/api/c/offers/${payload.entityId}/media/${payload.mediaType}`, payload.data, payload.config)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  deleteCompanyOfferPictures: (payload) => {
    return axios.delete(`/api/c/offers/${payload.entityId}/picture`)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  addCompanyOfferLink: (payload) => {
    return axios.put(`/api/c/offers/${payload.entityId}/internal_link`, payload.data, payload.config)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  saveCompanyOffer: (payload) => {
    return axios.put(`/api/c/offers/${payload.entityId}`, payload)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  publiciseCompanyOffer: (payload) => {
    return axios.put(`/api/c/offers/${payload.entityId}/publicise`, payload)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  closeCompanyOffer: (payload) => {
    return axios.put(`/api/c/offers/${payload.entityId}/close`, payload)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  archiveCompanyOffer: (payload) => {
    return axios.put(`/api/c/offers/${payload.entityId}/archive`, payload)
      .then(response => ({
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  getCompanyWinners: (offerId) => {
    return axios.get(`/api/c/offers/${offerId}/winners`)
      .then(response => response);
  },

  deleteCompanyWinners: (offerId) => {
    return axios.delete(`/api/c/offers/${offerId}/winners`)
      .then(response => response);
  },

  deleteCompanyOffer: (offerId) => {
    return axios.delete(`/api/c/offers/${offerId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  findCompanyOffers: (payload) => {
    return axios.put('/api/c/offers', payload)
      .then(response => ({
        offers: response.data.offers != null
          ? response.data.offers : {},
      }));
  },

  // Consumer
  getOffer: (payload) => {
    return axios.get(`/api/users/offers/${payload.offerId}`)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
        offer: response.data.offer != null
          ? response.data.offer : {},
      }));
  },

  getActiveOffers: () => {
    return axios.get(`/api/users/active-offers`)
      .then(response => ({
        offers: response.data.offers != null
          ? response.data.offers : [],
      }));
  },
};
